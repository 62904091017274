import React, { useState, useEffect, useContext } from "react";
import { Button, TextField, Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../context/OnboardingContext";

const DetalleDireccionForm = () => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const [formData, setFormData] = useState(onboardingData);
  const [isFormValid, setIsFormValid] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const { address } = formData;
    setIsFormValid(!!address.line1 && !!address.postalCode && !!address.city);
  }, [formData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnboardingData(formData);
    history("/terminosCondiciones");
  };

  const handleFieldChange =
    (fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [fieldName]: event.target.value,
        },
      }));
    };

  return (
    <Container>
      <Header>
        <BackButton variant="contained" onClick={() => history("/formularioRegistro")}>
          Atrás
        </BackButton>
        <Image src={Logo} alt="Logo Tipwise" />
      </Header>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <Title variant="h4">Agregá tu dirección</Title>
          <Subtitle variant="body1">Usa la dirección donde reside</Subtitle>
          <StyledTextField
            label="Dirección"
            variant="outlined"
            fullWidth
            value={formData.address.line1}
            onChange={handleFieldChange("line1")}
          />
          <StyledTextField
            label="Adicional"
            variant="outlined"
            fullWidth
            value={formData.address.line2}
            onChange={handleFieldChange("line2")}
          />
          <StyledTextField
            label="Código Postal"
            variant="outlined"
            fullWidth
            value={formData.address.postalCode}
            onChange={handleFieldChange("postalCode")}
          />
          <StyledTextField
            label="Ciudad"
            variant="outlined"
            fullWidth
            value={formData.address.city}
            onChange={handleFieldChange("city")}
          />
          <ContinueButton
            type="submit"
            variant="contained"
            disabled={!isFormValid}
          >
            Continuar
          </ContinueButton>
        </FormContainer>
      </form>
    </Container>
  );
};

export default DetalleDireccionForm;

// Estilos del componente
const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "25px",
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  alignItems: "center",
  marginBottom: 2,
});

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const Image = styled("img")({
  width: 80,
  height: 50,
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: 300,
  height: 400,
  marginTop: 4,
});

const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.4rem",
  alignSelf: "flex-start",
  marginBottom: 1,
});

const Subtitle = styled(Typography)({
  color: "#272541",
  marginBottom: 5,
  alignSelf: "flex-start",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    width: 300,
    borderRadius: "15px",
  },
  marginTop: 1,
});

const ContinueButton = styled(Button)({
  position: "fixed",
  bottom: 20,
  width: "90%",
  borderRadius: "15px",
  height: "50px",
  marginTop: 2,
  backgroundColor: "#272541",
  fontWeight: "bold",
});
