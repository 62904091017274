import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Logo from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import { useNavigate } from "react-router-dom";
import axios from "../../AxiosConfig";

const Contacto = () => {
  const [phone, setPhone] = useState("");
  const history = useNavigate();

  // Cargar número de teléfono desde el localStorage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user && user.phoneNumber) {
      setPhone(user.phoneNumber);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const response = await axios.patch(
        `/users/${user._id}`,
        { phoneNumber: phone },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // Actualizar el localStorage con el nuevo número de teléfono
      localStorage.setItem("user", JSON.stringify(response.data.user));
      history("/informacionPersonal");
    } catch (error) {
      console.error("Error al actualizar el número de teléfono:", error);
      alert("Error al actualizar el número de teléfono.");
    }
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <Button
          sx={{ backgroundColor: "#272541" }}
          onClick={() => history("/informacionPersonal")}
        >
          <ArrowBackOutlinedIcon sx={{ color: "#FFFFFF" }} />
        </Button>
        <StyledImg src={Logo || ""} />
      </HeaderContainer>
      <ParagraphContainer>
        <Typography
          variant="h5"
          component="h2"
          fontWeight={"bold"}
          sx={{ marginBottom: "10px" }}
        >
          Ingrese su número telefónico
        </Typography>
      </ParagraphContainer>
      <ContentContainer>
        <PhoneInput
          country={"es"}
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputProps={{
            placeholder: "XXXXXXXXX",
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          containerStyle={{ width: "100%" }}
          inputStyle={{
            width: "100%",
            height: "50px",
            borderRadius: "12px",
            paddingLeft: "50px",
            borderColor: "#4640b3",
          }}
          buttonStyle={{
            borderRadius: "12px 0 0 12px",
          }}
          dropdownStyle={{
            borderRadius: "0 0 12px 12px",
          }}
        />
      </ContentContainer>
      <StyledButton onClick={handleSubmit}>Continuar</StyledButton>
    </MainContainer>
  );
};

export default Contacto;

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const StyledImg = styled("img")(({ theme }) => ({
  width: 80,
  height: 50,
}));

const ParagraphContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "30px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  marginTop: "300px",
  backgroundColor: "#272541",
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#272741",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    marginTop: "300px",
  },
  [theme.breakpoints.up("md")]: {
    width: "58%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
    marginTop: "250px",
  },
}));
