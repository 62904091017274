import React from "react";
import { Box, Typography, IconButton, Link, Tooltip, Button } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../assets/logos/LOGOTIPOBlanco.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#272541",
        color: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "16px",
        width: "100%",
        boxSizing: "border-box",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom: { xs: "8px", sm: "0" },
        }}
      >
         <Box>
          <Tooltip title="Ayúdanos a mejorar!">
            <IconButton
              component={Link}
              href="mailto:hello@tipwise.ai"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: "#F7E000",
                "&:hover": {
                  backgroundColor: "#D9C100",
                },
                color: "#272541",
              }}
              aria-label="Soporte"
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <IconButton
          component={Link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/tipwise-global/"
          sx={{
            backgroundColor: "#F7E000",
            "&:hover": {
              backgroundColor: "#D9C100",
            },
            color: "#272541",
          }}
          aria-label="LinkedIn"
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          component={Link}
          href="https://www.instagram.com/tip_wise/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            backgroundColor: "#F7E000",
            "&:hover": {
              backgroundColor: "#D9C100",
            },
            color: "#272541",
          }}
          aria-label="Instagram"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          component={Link}
          href="https://www.facebook.com/profile.php?id=61553410565930"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            backgroundColor: "#F7E000",
            "&:hover": {
              backgroundColor: "#D9C100",
            },
            color: "#272541",
          }}
          aria-label="Facebook"
        >
          <FacebookIcon />
        </IconButton>
        {/* <IconButton
          component={Link}
          href="mailto:hello@tipwise.ai"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            marginRight: 15,
            backgroundColor: "#F7E000",
            "&:hover": {
              backgroundColor: "#D9C100",
            },
            color: "#272541",
          }}
          aria-label="Soporte"
        >
          <MailOutlineIcon />
        </IconButton> */}
      </Box>

      <Typography variant="body2" sx={{ display: { xs: "none", sm: "block" } }}>
        © 2024 Tipwise. All rights reserved.
      </Typography>

      <Box>
        <img
          src={Logo}
          alt="Tipwise Logo"
          style={{ height: "50px", paddingRight: 15 }}
          sx={{
            height: { xs: "50px", sm: "80px" },
          }}
        />
      </Box>
    </Box>
  );
};

export default Footer;