import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../AxiosConfig";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          setUser(localStorage.getItem("user"));
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Error validating token:", error);
          setIsAuthenticated(false);
        }
      }
    };
    fetchUser();
  }, []);

  const login = async (email, accessCode) => {
    try {
      const response = await axios.post("/auth/validate-access-code", {
        email,
        accessCode,
      });
      setIsAuthenticated(false);
      setUser(null);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.user.role);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      setUser(response.data.user);
      setIsAuthenticated(true);
      if (response.data.user.completedOnboarding) {
        console.log(response.data.user.completedOnboarding)
        navigate("/home");
      } else {
        navigate("/tipoEmpresa");
      }

    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUser(null);
    navigate("/signin");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
