import React, { useState, useEffect } from "react";
import { Button, Box, Typography, IconButton } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const InformacionPersonal = () => {
  const [userData, setUserData] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
    }
  }, []);

  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  const { address, segment } = userData;

  return (
    <>
      <HeaderBox>
        <Button onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon sx={{ color: "#FFFFFF" }} />
        </Button>
        <CenteredBox>
          <HeaderTypography>Información personal</HeaderTypography>
        </CenteredBox>
      </HeaderBox>
      <ContentBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Detalles personales</Typography>
          </SectionHeader>
          <Typography>Nombre: {userData.firstName}</Typography>
          <Typography>Apellido: {userData.lastName}</Typography>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Dirección</Typography>
            <IconButton
              size="small"
              onClick={() => history("/formularioDireccionEdicion")}
            >
              <EditIcon sx={{ color: "#272541" }} />
            </IconButton>
          </SectionHeader>
          <Typography>Línea 1: {address.line1}</Typography>
          <Typography>Línea 2: {address.line2}</Typography>
          <Typography>Código Postal: {address.postalCode}</Typography>
          <Typography>Ciudad: {address.city}</Typography>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Email</Typography>
          </SectionHeader>
          <Typography>{userData.email}</Typography>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Teléfono</Typography>
            <IconButton size="small" onClick={() => history("/contacto")}>
              <EditIcon sx={{ color: "#272541" }} />
            </IconButton>
          </SectionHeader>
          <Typography>{userData.phoneNumber || "No disponible"}</Typography>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Ocupaciones</Typography>
            <IconButton size="small" onClick={() => history("/seleccionarRubroUsuarioEdit")}>
              <EditIcon sx={{ color: "#272541" }} />
            </IconButton>
          </SectionHeader>
          <Typography>Especialidades: {segment?.join(", ") || "No disponible"}</Typography>
        </SectionBox>
      </ContentBox>
    </>
  );
};

export default InformacionPersonal;

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 16px",
  backgroundColor: "#272541",
});

const CenteredBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
  color: "#FFFFFF",
});

const ContentBox = styled(Box)({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const SectionBox = styled(Box)({
  width: "95%",
  marginBottom: "20px",
  padding: "16px",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  "@media (max-width: 768px)": {
    width: "100%",
  },
});

const SectionHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
  color: "#272541",
});
