import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  ButtonBase,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "../../AxiosConfig"; // Asegúrate de tener configurado Axios

const RubroUsuarioEdit = () => {
  const navigate = useNavigate();
  
  // Obtener el usuario del localStorage
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  // Estado para manejar los segmentos seleccionados y otro segmento personalizado
  const [selectedSegments, setSelectedSegments] = useState<string[]>(user?.segment || []);
  const [otherSegment, setOtherSegment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Si el usuario tiene un segmento personalizado (no predeterminado)
    if (!user?.segment?.includes("Otro")) {
      setOtherSegment(user?.segment?.find(seg => seg === "Otro") || "");
    }
  }, [user]);

  // Volver a la pantalla de Información Personal
  const handleBack = () => {
    navigate("/informacionPersonal");
  };

  // Manejar la selección de segmentos (rubro)
  const handleSelection = (value) => {
    if (value === "Otro") {
      if (!selectedSegments.includes(value)) {
        setSelectedSegments([...selectedSegments, value]);
      }
    } else {
      if (selectedSegments.includes(value)) {
        setSelectedSegments(
          selectedSegments.filter((segment) => segment !== value)
        );
      } else if (selectedSegments.length < 3) {
        setSelectedSegments([...selectedSegments, value]);
      }
    }
  };

  // Manejar el envío del formulario y guardar los segmentos seleccionados en el backend y en localStorage
  const handleContinue = async () => {
    const segmentsToSave = selectedSegments.includes("Otro")
      ? [...selectedSegments.filter((seg) => seg !== "Otro"), otherSegment]
      : selectedSegments;

    // Actualizar en backend
    setIsSaving(true);
    try {
      const response = await axios.patch(
        `/users/${user._id}`, // Asegúrate de que la URL sea correcta
        {
          segment: segmentsToSave, // Enviar los segmentos seleccionados
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agregar el token de autenticación
          },
        }
      );

      // Si la solicitud tiene éxito, actualizamos el localStorage
      const updatedUser = { ...user, segment: segmentsToSave };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      // Redirigir de vuelta a la pantalla de información personal
      navigate("/informacionPersonal");
    } catch (error) {
      console.error("Error al actualizar los segmentos:", error);
      alert("Hubo un error al guardar tus cambios. Inténtalo nuevamente.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#272541",
            borderRadius: "15px",
            fontWeight: "bold",
          }}
          onClick={handleBack}
        >
          Atrás
        </Button>
        <StyledImg src={localStorage.getItem("avatar") || ""} />
      </HeaderContainer>
      <ParagraphContainer>
        <Typography
          variant="h5"
          component="h2"
          fontWeight={"bold"}
          sx={{ marginBottom: "10px" }}
        >
          ¿En qué te especializas?
        </Typography>
        <StyledParagraph>Elija hasta 3 opciones.</StyledParagraph>
      </ParagraphContainer>
      <StyledBoxContainer>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {[
            "Barbero",
            "Camarero/a",
            "Chef",
            "Chofer",
            "Cocinero/a",
            "Cuidador/a profesional",
            "Guía turístico",
            "Housekeeping",
            "Terapeuta manual",
            "Músico/a",
            "Peluquera/o",
            "Profesional de estética",
            "Profesional de enfermería",
            "Profesional independiente",
            "Repartidor/a",
            "Taxista",
            "Valet",
            "Otro",
          ].map((segment) => (
            <StyledButtonBase
              key={segment}
              onClick={() => handleSelection(segment)}
            >
              <StyledCard selected={selectedSegments.includes(segment)}>
                <StyledTypogrphy>{segment}</StyledTypogrphy>
              </StyledCard>
            </StyledButtonBase>
          ))}
        </Box>
        {selectedSegments.includes("Otro") && (
          <TextField
            fullWidth
            label="Ingrese la especialidad"
            value={otherSegment}
            onChange={(e) => setOtherSegment(e.target.value)}
            sx={{ marginTop: "15px" }}
          />
        )}
      </StyledBoxContainer>
      <StyledButton
        onClick={handleContinue}
        variant="contained"
        disabled={
          isSaving ||
          selectedSegments.length === 0 ||
          (selectedSegments.includes("Otro") && !otherSegment)
        }
      >
        {isSaving ? "Guardando..." : "Continuar"}
      </StyledButton>
    </MainContainer>
  );
};

export default RubroUsuarioEdit;

// Estilos con MUI Styled

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const StyledImg = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
}));

const ParagraphContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "30px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginBottom: "10px",
  color: "#626067",
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
    marginBottom: "25px",
  },
}));

const StyledTypogrphy = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
}));

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({}));

const StyledCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ selected }) => ({
  padding: "5px 10px",
  border: selected ? "2px solid #7c7495" : "1px solid #ccc",
  color: selected ? "#7c7495" : "black",
  backgroundColor: selected ? "#f1f0ff" : "#ffffff",
  borderRadius: "15px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  fontWeight: "bold",
  marginTop: "15px",
  backgroundColor: "#272541",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#272541",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    marginTop: "100px",
  },
  [theme.breakpoints.up("md")]: {
    width: "58%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
    marginTop: "100px",
  },
}));
