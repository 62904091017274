import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Link,
  Card,
  CardContent,
  ButtonBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import España from "../../assets/España.jpg";
import persona from "../../assets/OnBoarding1.jpg";
import personas from "../../assets/OnBoarding4.jpg";
import { useNavigate } from "react-router-dom";

const TipoUsuario = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const handleSelectedCard = (value) => {
    setSelectedOption(value);
  };

  const handleContinue = () => {
    if (selectedOption === "individuo") {
      navigate("/seleccionarRubroUsuario");
      localStorage.setItem("tipoUser", "individuo");
    } else if (selectedOption === "empresa") {
      navigate("/seleccionarRubro");
      localStorage.setItem("tipoUser", "empresa");
    }
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <BackButton variant="contained" onClick={() => navigate("/SignIn")}>
          Atrás
        </BackButton>
        <StyledImg src={localStorage.getItem("avatar") || ""} />
      </HeaderContainer>
      <ParagraphContainer>
        <Typography
          variant="h6"
          component="h2"
          fontWeight={"bold"}
          sx={{ marginBottom: "10px" }}
        >
          ¿Cómo planeas usar Tipwise?
        </Typography>
        <StyledParagraph>
          Elige entre la opción individual o equipo y comienza a disfrutar de
          cobrar las propinas sin barreras.
        </StyledParagraph>
      </ParagraphContainer>
      <CardsContainer>
        <StyledButtonBase
          onClick={() => handleSelectedCard("individuo")}
          disableRipple
        >
          <StyledCard selected={selectedOption === "individuo"}>
            <StyledCardContent>
              <CardImg src={persona} />
              <Box>
                <CardTitle>Para mí</CardTitle>
                <CardParagraph>
                  Recibe propinas individuales al presentar tu código QR único.
                </CardParagraph>
              </Box>
            </StyledCardContent>
          </StyledCard>
        </StyledButtonBase>
        <StyledButtonBase
          onClick={() => handleSelectedCard("empresa")}
          disableRipple
        >
          <StyledCard selected={selectedOption === "empresa"}>
            <StyledCardContent>
              <CardImg src={personas} />
              <Box>
                <CardTitle>Para las empresas</CardTitle>
                <CardParagraph>
                  Crea botes para distribuir la propina automáticamente entre
                  los miembros del equipo.
                </CardParagraph>
              </Box>
            </StyledCardContent>
          </StyledCard>
        </StyledButtonBase>
      </CardsContainer>
      <StyledButton
        variant="contained"
        disabled={selectedOption ? false : true}
        onClick={handleContinue}
      >
        Continuar
      </StyledButton>
    </MainContainer>
  );
};

export default TipoUsuario;

const MainContainer = styled(Box)(() => ({
  width: "100vw",
  height: "90vh",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const StyledImg = styled("img")(() => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
}));

const ParagraphContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "30px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  // marginBottom: "10px",
  color: "#626067",
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
    marginBottom: "20px",
  },
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    justifyContent: "center",
  },

  [theme.breakpoints.up("lg")]: {
    width: "60%",
    //marginTop: "20px",
  },
}));

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ selected }) => ({
  flex: "1 1 auto",
  display: "flex",
  border: selected ? "2px solid #4640b3" : "1px solid #ccc",
  borderRadius: "15px",
  boxSizing: "border-box",
  maxWidth: "550px",
  margin: "0 auto",
  cursor: "pointer",
  "&:hover": {
    borderColor: selected ? "#4640b3" : "#ccc",
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const CardTitle = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: 700,
  color: "black",
}));

const CardParagraph = styled(Typography)(() => ({
  fontSize: "14px",
  color: "#626067",
  marginTop: "10px",
}));

const CardImg = styled("img")(() => ({
  width: "130",
  height: "110px",
}));

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  display: "flex",
  textAlign: "inherit",
  borderRadius: "15px",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  marginTop: "15px",
  backgroundColor: "#272541",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#272541",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    marginTop: "100px",
  },
  [theme.breakpoints.up("md")]: {
    width: "58%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
    marginTop: "100px",
  },
}));

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});
