import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Typography, Box, Button, Avatar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";

const ProfileComponent = () => {
  const history = useNavigate();
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  
  const [profile] = useState({
    name: `${user.firstName} ${user.lastName}`,
    username: `@${user.username}`,
    rating: 0.0,
    reviews: "-",
    monthsActive: 1,
    specialties: user.segment,
    location: user.country,
    description: user.description,
  });

  return (
    <ContentContainer>
      <Header>
        <IconButton
          onClick={() => {
            history("/perfil");
          }}
          style={{ color: "#FFFFFF" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ flexGrow: 1, textAlign: "center", color: "#FFFFFF" }}
        >
          Perfil
        </Typography>
        <Button
          onClick={() => {
            history("/infoUsuario");
          }}
          style={{ color: "#FFFFFF" }}
        >
          <EditIcon />
          <EditText>Editar</EditText>
        </Button>
      </Header>
      <ProfileCard>
        <ProfileInfo>
          <Avatar
            src={localStorage.getItem("avatar") || ""}
            sx={{ width: 86, height: 86 }}
          ></Avatar>
          <Typography variant="h6">{profile.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {profile.username}
          </Typography>
        </ProfileInfo>
        <StatsBox>
          <StatsItem>
            <Typography variant="h6">{profile.reviews}</Typography>
            <Typography variant="body2" color="textSecondary">
              Revisiones
            </Typography>
          </StatsItem>
          <StatsItem>
            <StarIcon color="primary" />
            <Typography variant="h6">{profile.rating.toFixed(2)}</Typography>
            <Typography variant="body2" color="textSecondary">
              Calificación
            </Typography>
          </StatsItem>
          <StatsItem>
            <Typography variant="h6">{profile.monthsActive}</Typography>
            <Typography variant="body2" color="textSecondary">
              Meses activo
            </Typography>
          </StatsItem>
        </StatsBox>
        <Specialties>
          <Typography variant="body2" color="textSecondary">
            Nos especializamos en
          </Typography>
          <SpecialtiesList>
            {profile.specialties.map((specialty) => (
              <SpecialtyItem key={specialty}>{specialty}</SpecialtyItem>
            ))}
          </SpecialtiesList>
        </Specialties>
        <Location>
          <Typography variant="body2" color="textSecondary">
            Vivo en {profile.location}
          </Typography>
        </Location>
        <Description>
          <Typography variant="body2">{profile.description}</Typography>
        </Description>
        <Reviews>
          <Typography variant="h6">Revisiones</Typography>
          <Typography variant="body2" color="textSecondary">
            No Reviews
          </Typography>
        </Reviews>
      </ProfileCard>
    </ContentContainer>
  );
};

export default ProfileComponent;

const ContentContainer = styled(Box)({
  padding: "0 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f9f9f9",
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "60px",
  padding: "0 16px",
  backgroundColor: "#272541",
});

const EditText = styled(Typography)({
  marginLeft: "5px",
});

const ProfileCard = styled(Box)({
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  padding: "20px",
  marginTop: "20px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width: 768px)": {
    margin: "30px",
  },
});

const ProfileInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "20px",
});

const StatsBox = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  marginBottom: "20px",
});

const StatsItem = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Specialties = styled(Box)({
  width: "100%",
  marginBottom: "20px",
});

const SpecialtiesList = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
});

const SpecialtyItem = styled(Box)({
  backgroundColor: "#e0e0e0",
  borderRadius: "15px",
  padding: "5px 10px",
  margin: "5px",
});

const Location = styled(Box)({
  width: "100%",
  textAlign: "center",
  marginBottom: "20px",
});

const Description = styled(Box)({
  width: "100%",
  textAlign: "center",
  marginBottom: "20px",
});

const Reviews = styled(Box)({
  width: "100%",
  textAlign: "center",
});
