import React, { useEffect, useState } from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import axios from "../../../AxiosConfig";

const DocumentVerification = () => {
  const history = useNavigate();
  const [account, setAccount] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await axios.get(`/usersAccount/${userData._id}`);
        setAccount(response.data);
        checkAccountStatus(response.data);
      } catch (error) {
        console.error("Error fetching account:", error);
      }
    };
    fetchAccount();
  }, []);

  const checkAccountStatus = (account) => {
    if (account.charges_enabled && account.payouts_enabled) {
      // La cuenta está completamente activada
      setButtonDisabled(true); // Deshabilitar botón
    } else {
      if (!account.charges_enabled) {
        console.log('La cuenta no puede aceptar cargos.');
      }
      if (!account.payouts_enabled) {
        console.log('La cuenta no puede recibir pagos.');
      }
      if (account.disabled_reason) {
        console.log(`La cuenta está desactivada debido a: ${account.disabled_reason}`);
      }
    }
  };

  const handleGoToDocuments = async () => {
    if (!buttonDisabled) {
      try {
        const stripeResponse = await axios.post(
          `/users/${userData._id}/stripe-account-onboarding`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        window.location.href = stripeResponse.data.onboardingUrl;
      } catch (error) {
        console.error("Error redirecting to documents:", error);
      }
    }
  };

  return (
    <Container>
      <HeaderBox>
        <StyledButton onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon />
        </StyledButton>
        <CenteredBox>
          <HeaderTypography>Documentos</HeaderTypography>
        </CenteredBox>
      </HeaderBox>
      <Content>
        <VerificationBox>
          <Typography variant="h6" className="title">
            {buttonDisabled
              ? "Cuenta Verificada"
              : "Verificación pendiente"}
          </Typography>
          <Typography variant="body1" className="description">
            {buttonDisabled
              ? "Tu cuenta ha sido verificada y está completamente activada."
              : "La verificación que realizaste en Stripe se encuentra en estado pendiente. Si deseas obtener más información, haz click en 'Ir a Documentos' para más detalles."}
          </Typography>
        </VerificationBox>
      </Content>
      <Footer>
        <GoToDocumentsButton
          variant="contained"
          onClick={handleGoToDocuments}
          disabled={buttonDisabled} // Deshabilitar el botón si la cuenta está activada
        >
          Ir a documentos
        </GoToDocumentsButton>
        {!buttonDisabled && (
          <FooterNote>
            Serás re-dirigido a la página de nuestro asociado para subir los
            documentos de identidad de forma segura.
          </FooterNote>
        )}
      </Footer>
    </Container>
  );
};

export default DocumentVerification;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 10px",
  minHeight: "90vh",
  backgroundColor: "#f9f9f9",
  overflow: "hidden",
  "@media (max-width: 768px)": {
    padding: "0 10px",
  },
});

const Content = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBlock: "15px",
  flex: 1,
});

const VerificationBox = styled(Box)({
  backgroundColor: "#e6ffed",
  padding: "20px",
  borderRadius: "10px",
  textAlign: "center",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  width: "85%",
  maxHeight: "200px",
  "@media (max-width: 768px)": {
    padding: "15px",
  },
  ".title": {
    marginTop: "10px",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  ".description": {
    marginTop: "10px",
    fontSize: "1rem",
    color: "#555",
  },
});

const Footer = styled(Box)({
  textAlign: "center",
  width: "100%",
  padding: "20px",
  "@media (max-width: 768px)": {
    padding: "10px",
  },
});

const FooterNote = styled(Typography)({
  fontSize: "0.875rem",
  color: "#666",
  marginTop: "10px",
});

const GoToDocumentsButton = styled(Button)({
  width: "85%",
  padding: "15px",
  backgroundColor: "#272541",
  color: "#fff",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#1f1a33",
  },
});

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 15px",
  backgroundColor: "#272541",
  color: "#FFFFFF",
});

const StyledButton = styled(Button)({
  color: "#FFFFFF",
  zIndex: 99,
});

const CenteredBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
  color: "#FFFFFF",
});
