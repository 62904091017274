import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "../../../AxiosConfig";

const CrearBote: React.FC = () => {
  const history = useNavigate();
  const [boteNombre, setBoteNombre] = useState("");
  const [tipoBote, setTipoBote] = useState("");
  const [porcentaje, setPorcentaje] = useState<number | null>(null);
  const [parentId, setParentId] = useState<string | null>(null);
  const [botesExistentes, setBotesExistentes] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [manualStartTime, setManualStartTime] = useState("");
  const [manualEndTime, setManualEndTime] = useState("");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    validateForm();
    fetchBotesExistentes();
    // eslint-disable-next-line
  }, [boteNombre, tipoBote, porcentaje, manualStartTime, manualEndTime]);

  const handleNombreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBoteNombre(event.target.value);
  };

  const handleTipoBoteChange = (event) => {
    setTipoBote(event.target.value as string);
  };

  const handlePorcentajeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPorcentaje(parseFloat(event.target.value));
  };

  const handleParentIdChange = (event) => {
    setParentId(event.target.value as string);
  };

  const handleManualStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualStartTime(event.target.value);
  };

  const handleManualEndTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualEndTime(event.target.value);
  };

  const validateForm = () => {
    const isHorarioValid =
      (manualStartTime !== "" && manualEndTime !== "") ||
      (manualStartTime === "" && manualEndTime === "");
    setIsFormValid(
      boteNombre.trim() !== "" &&
        tipoBote !== "" &&
        (tipoBote !== "Manual" ||
          (porcentaje !== null &&
            !isNaN(porcentaje) &&
            porcentaje > 0 &&
            porcentaje <= 100)) &&
        isHorarioValid
    );
  };

  const fetchBotesExistentes = async () => {
    if (user && user.companies && user.companies.length > 0) {
      console.log(user.companies[0]);
      try {
        const response = await axios.get(
          `/botes/company/${user.companies[0]._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setBotesExistentes(response.data.botes);
      } catch (error) {
        console.error("Error fetching botes existentes:", error);
      }
    }
  };

  const handleCrearClick = async () => {
    if (user && user.companies && user.companies.length > 0) {
      const nuevoBote = {
        nombre: boteNombre,
        tipo: tipoBote,
        percentage: porcentaje,
        companyId: user.companies[0],
        parentBote: parentId,
        schedule: {
          from: manualStartTime
            ? new Date(`1970-01-01T${manualStartTime}:00Z`)
            : null,
          to: manualEndTime
            ? new Date(`1970-01-01T${manualEndTime}:00Z`)
            : null,
        },
      };

      try {
        const response = await axios.post("/botes", nuevoBote, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log("Nuevo bote creado:", response.data);
        history("/agregarMiembros");
      } catch (error) {
        console.error("Error creando el bote:", error);
      }
    }
  };

  return (
    <>
      <Header>
        <IconButton
          onClick={() => history("/botes")}
          style={{ color: "#FFFFFF" }}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ flexGrow: 1, textAlign: "center", color: "#FFFFFF" }}
        >
          Crear un bote
        </Typography>
        <Box sx={{ width: 48 }} /> {/* Empty box to center the title */}
      </Header>
      <ContentContainer>
        <ProfileCard>
          <InnerBox>
            <StyledTextField
              label="Nombre"
              value={boteNombre}
              onChange={handleNombreChange}
            />
            <StyledFormControl>
              <InputLabel>Reparto del bote</InputLabel>
              <Select
                label="Reparto de bote"
                value={tipoBote}
                onChange={handleTipoBoteChange}
              >
               {/*  <MenuItem value="Manual">Manual</MenuItem>
                <MenuItem value="Igualitario">Igualitario</MenuItem> */}
                <MenuItem value="Individual">Individual</MenuItem>
              </Select>
            </StyledFormControl>
           {/*  {tipoBote === "Manual" && (
              <StyledFormControl>
                <TextField
                  label="Porcentaje"
                  type="number"
                  value={porcentaje ?? ""}
                  onChange={handlePorcentajeChange}
                />
              </StyledFormControl>
            )} */}
           {/*  <StyledFormControl>
              <InputLabel>Bote Padre</InputLabel>
              <Select
                label="Bote Padre"
                value={parentId ?? ""}
                onChange={handleParentIdChange}
              >
                <MenuItem value="">Ninguno</MenuItem>
                {botesExistentes.map((bote) => (
                  <MenuItem key={bote._id} value={bote._id}>
                    {bote.nombre}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl> */}

          {/*   <Box sx={{ display: "flex", gap: "15px", marginTop: "15px" }}>
              <StyledTextFieldHorario
                label="Desde"
                type="time"
                value={manualStartTime}
                onChange={handleManualStartTimeChange}
                InputLabelProps={{ shrink: true }}
              />
              <StyledTextFieldHorario
                label="Hasta"
                type="time"
                value={manualEndTime}
                onChange={handleManualEndTimeChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box> */}

            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ textAlign: "center", marginTop: "10px" }}
            >
              Cada miembro del equipo recibe las propinas generadas por él/ella
            </Typography>
          </InnerBox>
          <StyledButton
            variant="contained"
            onClick={handleCrearClick}
            disabled={!isFormValid}
          >
            Crear
          </StyledButton>
        </ProfileCard>
      </ContentContainer>
    </>
  );
};

export default CrearBote;

const ContentContainer = styled(Box)({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f9f9f9",
  overflow: "hidden",
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "60px",
  padding: "0 16px",
  backgroundColor: "#272541",
  boxSizing: "border-box",
});

const ProfileCard = styled(Box)({
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  padding: "20px",
  marginTop: "20px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
});

const InnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  minHeight: "400px",
});

const StyledTextField = styled(TextField)({
  marginBlock: "20px",
  width: "100%",
  maxWidth: "300px",
});

const StyledTextFieldHorario = styled(TextField)({
  marginBottom: "20px",
  width: "150px",
});

const StyledFormControl = styled(FormControl)({
  marginBottom: "20px",
  width: "100%",
  maxWidth: "300px",
});

const StyledButton = styled(Button)({
  width: "100%",
  maxWidth: "300px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#F7E000",
    color: "#272541",
  },
});
