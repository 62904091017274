import React, { useState, useEffect } from "react";
import { Button, Box, Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/system";

const LenguajeSelector = () => {
  const history = useNavigate();
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data.map(
          (country: any) => country.translations.spa.common
        );
        countryNames.sort((a: string, b: string) => a.localeCompare(b));
        setCountries([" ", ...countryNames]);
      })
      .catch((error) => {
        console.error("Error al cargar los países:", error);
      });
  }, []);

  return (
    <>
      <HeaderBox>
        <StyledButton onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon />
        </StyledButton>
        <TitleContainer>
          <HeaderTypography>Lenguaje</HeaderTypography>
        </TitleContainer>
      </HeaderBox>
      <MainContainer>
        <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
          Cambie el lenguaje usado en la interfaz de usuario:
        </Typography>
        <Autocomplete
          options={countries}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Español"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </MainContainer>
    </>
  );
};

export default LenguajeSelector;

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 1px",
  backgroundColor: "#272541",
  color: "#FFFFFF",
});

const StyledButton = styled(Button)({
  color: "#FFFFFF",
});

const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#FFFFFF",
  "@media (max-width: 768px)": {
    fontSize: "18px",
  },
});

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  width: "100%",
  maxWidth: "600px",
  maxHeight: "50vh",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    padding: "10px",
  },
});

const StyledTextField = styled(TextField)({
  width: "300px",
  marginTop: "20px",
  textAlign: "center",
  "@media (max-width: 768px)": {
    marginTop: "15px",
  },
});
