import React, { useEffect, useState } from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useNavigate } from "react-router-dom";
import AppBarMenu from "../../navBar/NavBarMenu.tsx";
import GananciasDetail from "./DetalleGanancias.tsx";

dayjs.extend(isBetween);

const transactions = [
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Fri 3 May 2024",
    time: "4:35 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Fri 3 May 2024",
    time: "4:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Sat 4 May 2024",
    time: "12:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Sat 4 May 2024",
    time: "2:30 PM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Sun 5 May 2024",
    time: "11:00 AM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Sun 5 May 2024",
    time: "5:45 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Mon 6 May 2024",
    time: "1:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Mon 6 May 2024",
    time: "3:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Tue 7 May 2024",
    time: "10:30 AM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Tue 7 May 2024",
    time: "4:00 PM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Wed 8 May 2024",
    time: "11:35 AM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Wed 8 May 2024",
    time: "6:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Thu 9 May 2024",
    time: "2:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Thu 9 May 2024",
    time: "7:30 PM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Fri 10 May 2024",
    time: "9:00 AM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Fri 10 May 2024",
    time: "4:45 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Sat 11 May 2024",
    time: "1:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Sat 11 May 2024",
    time: "5:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Sun 12 May 2024",
    time: "10:30 AM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Sun 12 May 2024",
    time: "3:00 PM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Mon 13 May 2024",
    time: "11:35 AM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Mon 13 May 2024",
    time: "6:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Tue 14 May 2024",
    time: "12:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Tue 14 May 2024",
    time: "2:30 PM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Wed 15 May 2024",
    time: "11:00 AM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Wed 15 May 2024",
    time: "5:45 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Thu 16 May 2024",
    time: "1:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Thu 16 May 2024",
    time: "3:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Fri 17 May 2024",
    time: "10:30 AM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Fri 17 May 2024",
    time: "4:00 PM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Sat 18 May 2024",
    time: "11:35 AM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Sat 18 May 2024",
    time: "6:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Sun 19 May 2024",
    time: "2:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Sun 19 May 2024",
    time: "7:30 PM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Mon 20 May 2024",
    time: "9:00 AM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Mon 20 May 2024",
    time: "4:45 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Tue 21 May 2024",
    time: "1:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Tue 21 May 2024",
    time: "5:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Wed 22 May 2024",
    time: "10:30 AM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Wed 22 May 2024",
    time: "3:00 PM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Thu 23 May 2024",
    time: "11:35 AM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Thu 23 May 2024",
    time: "6:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Fri 24 May 2024",
    time: "12:15 PM",
  },
  {
    email: "joa**@gmail.com",
    amount: 2.75,
    date: "Fri 24 May 2024",
    time: "2:30 PM",
  },
  {
    email: "lau**@gmail.com",
    amount: 5.0,
    date: "Sat 25 May 2024",
    time: "11:00 AM",
  },
  {
    email: "pab**@gmail.com",
    amount: 4.58,
    date: "Sat 25 May 2024",
    time: "5:45 PM",
  },
  {
    email: "ped**@gmail.com",
    amount: 4.58,
    date: "Sun 26 May 2024",
    time: "1:05 PM",
  },
  {
    email: "mar**@gmail.com",
    amount: 3.2,
    date: "Sun 26 May 2024",
    time: "3:15 PM",
  },
];

//const formatDate = (date) => dayjs(date).format("ddd DD MMM YYYY");

const handleFilter = (selectedFilter) => {
  const today = dayjs();
  switch (selectedFilter) {
    case "Hoy":
      return transactions.filter((item) =>
        dayjs(item.date, "ddd DD MMM YYYY").isSame(today, "day")
      );
    case "Semana":
      const startOfWeek = today.startOf("week");
      const endOfWeek = today.endOf("week");
      return transactions.filter((item) =>
        dayjs(item.date, "ddd DD MMM YYYY").isBetween(
          startOfWeek,
          endOfWeek,
          null,
          "[]"
        )
      );
    case "Mes":
      const startOfMonth = today.startOf("month");
      const endOfMonth = today.endOf("month");
      return transactions.filter((item) =>
        dayjs(item.date, "ddd DD MMM YYYY").isBetween(
          startOfMonth,
          endOfMonth,
          null,
          "[]"
        )
      );
    default:
      return transactions;
  }
};

interface ITransactions {
  email: string;
  amount: number;
  date: string;
  time: string;
}

const Ganancias = () => {
  const [totalTipsAmount, setTotalTipsAmount] = useState(0);
  const [tipCount, setTipCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredTransactions, setFilteredTransaction] = useState<
    ITransactions[]
  >([]);
  const history = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  useEffect(() => {
    const filtered = handleFilter(selectedFilter);
    setFilteredTransaction(filtered);

    const totalAmount = filtered
      .reduce((sum, transaction) => sum + transaction.amount, 0)
      .toFixed(2);
    setTotalTipsAmount(parseInt(totalAmount));
    setTipCount(filtered.length);
  }, [selectedFilter]);

  return (
    <MainContainer>
      <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <MainBox>
        <GananciasDetail
          totalTipsAmount={totalTipsAmount}
          tipCount={tipCount}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </MainBox>
      <TransactionContainer>
        <TransactionHeader>
          <Typography variant="h6" fontWeight="bold" color="#272541">
            Historial de transacciones
          </Typography>
          <Link variant="h6" sx={{ color: "#4443b4", textDecoration: "none" }}>
            Ver todas
          </Link>
        </TransactionHeader>
        {filteredTransactions.length > 0 ? (
          <TransactionBox>
            {filteredTransactions.map((item, index) => (
              <TransactionItem key={index}>
                <IconWrapper>
                  <IconButton onClick={() => history("/detalleTransction")}>
                    <NorthEastOutlinedIcon
                      sx={{
                        strokeWidth: "1",
                        fontSize: "30px",
                        color: "#7c7c7c",
                      }}
                    />
                  </IconButton>
                </IconWrapper>
                <CenterContent>
                  <Typography variant="h6" color="#272541">
                    Desde {item.email}
                  </Typography>
                  <Typography color="#7c7c7c">
                    {item.date} {item.time}
                  </Typography>
                </CenterContent>
                <AmountText>+ € {item.amount.toFixed(2)}</AmountText>
              </TransactionItem>
            ))}
          </TransactionBox>
        ) : (
          <Typography variant="body1" color="#7c7c7c">
            No hay transacciones para mostrar.
          </Typography>
        )}
      </TransactionContainer>
    </MainContainer>
  );
};

export default Ganancias;

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  backgroundColor: "#f4f4f9",
});

const MainBox = styled(Box)({
  backgroundColor: "#272541",
  width: "100%",
  padding: "20px",
  display: "flex",
  justifyContent: "center",
});

const TransactionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  padding: "20px",
  backgroundColor: "#f4f4f9",
});

const TransactionHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "800px",
  marginBottom: "20px",
});

const TransactionBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "800px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  padding: "10px",
});

const TransactionItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 0",
  borderBottom: "1px solid #e0e0e0",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
  height: "48px",
  backgroundColor: "#f2f2f8",
  border: "1px solid #dcd4dc",
  borderRadius: "50%",
});

const CenterContent = styled(Box)({
  flex: 1,
  marginLeft: "10px",
});

const AmountText = styled(Typography)({
  color: "#4dab7b",
  fontWeight: "bold",
  marginLeft: "40px",
});

// import React, { useEffect, useState } from "react";
// import { Box, Typography, Badge, Link, IconButton } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
// import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import { styled } from "@mui/material/styles";
// import DonRamon from "../../../assets/TipWise.png";
// import GananciasDetail from "./DetalleGanancias.tsx";
// import dayjs from "dayjs";
// import isBetween from "dayjs/plugin/isBetween";
// import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
// import { useNavigate } from "react-router-dom";
// import AppBarMenu from "../../navBar/NavBarMenu.tsx";

// dayjs.extend(isBetween);

// const transactions = [
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Fri 3 May 2024",
//     time: "4:35 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Fri 3 May 2024",
//     time: "4:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Sat 4 May 2024",
//     time: "12:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Sat 4 May 2024",
//     time: "2:30 PM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Sun 5 May 2024",
//     time: "11:00 AM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Sun 5 May 2024",
//     time: "5:45 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Mon 6 May 2024",
//     time: "1:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Mon 6 May 2024",
//     time: "3:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Tue 7 May 2024",
//     time: "10:30 AM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Tue 7 May 2024",
//     time: "4:00 PM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Wed 8 May 2024",
//     time: "11:35 AM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Wed 8 May 2024",
//     time: "6:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Thu 9 May 2024",
//     time: "2:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Thu 9 May 2024",
//     time: "7:30 PM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Fri 10 May 2024",
//     time: "9:00 AM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Fri 10 May 2024",
//     time: "4:45 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Sat 11 May 2024",
//     time: "1:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Sat 11 May 2024",
//     time: "5:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Sun 12 May 2024",
//     time: "10:30 AM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Sun 12 May 2024",
//     time: "3:00 PM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Mon 13 May 2024",
//     time: "11:35 AM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Mon 13 May 2024",
//     time: "6:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Tue 14 May 2024",
//     time: "12:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Tue 14 May 2024",
//     time: "2:30 PM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Wed 15 May 2024",
//     time: "11:00 AM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Wed 15 May 2024",
//     time: "5:45 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Thu 16 May 2024",
//     time: "1:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Thu 16 May 2024",
//     time: "3:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Fri 17 May 2024",
//     time: "10:30 AM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Fri 17 May 2024",
//     time: "4:00 PM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Sat 18 May 2024",
//     time: "11:35 AM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Sat 18 May 2024",
//     time: "6:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Sun 19 May 2024",
//     time: "2:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Sun 19 May 2024",
//     time: "7:30 PM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Mon 20 May 2024",
//     time: "9:00 AM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Mon 20 May 2024",
//     time: "4:45 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Tue 21 May 2024",
//     time: "1:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Tue 21 May 2024",
//     time: "5:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Wed 22 May 2024",
//     time: "10:30 AM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Wed 22 May 2024",
//     time: "3:00 PM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Thu 23 May 2024",
//     time: "11:35 AM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Thu 23 May 2024",
//     time: "6:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Fri 24 May 2024",
//     time: "12:15 PM",
//   },
//   {
//     email: "joa**@gmail.com",
//     amount: 2.75,
//     date: "Fri 24 May 2024",
//     time: "2:30 PM",
//   },
//   {
//     email: "lau**@gmail.com",
//     amount: 5.0,
//     date: "Sat 25 May 2024",
//     time: "11:00 AM",
//   },
//   {
//     email: "pab**@gmail.com",
//     amount: 4.58,
//     date: "Sat 25 May 2024",
//     time: "5:45 PM",
//   },
//   {
//     email: "ped**@gmail.com",
//     amount: 4.58,
//     date: "Sun 26 May 2024",
//     time: "1:05 PM",
//   },
//   {
//     email: "mar**@gmail.com",
//     amount: 3.2,
//     date: "Sun 26 May 2024",
//     time: "3:15 PM",
//   },
// ];

// //const formatDate = (date) => dayjs(date).format("ddd DD MMM YYYY");

// const handleFilter = (selectedFilter) => {
//   const today = dayjs();
//   switch (selectedFilter) {
//     case "Hoy":
//       return transactions.filter((item) =>
//         dayjs(item.date, "ddd DD MMM YYYY").isSame(today, "day")
//       );
//     case "Semana":
//       const startOfWeek = today.startOf("week");
//       const endOfWeek = today.endOf("week");
//       return transactions.filter((item) =>
//         dayjs(item.date, "ddd DD MMM YYYY").isBetween(
//           startOfWeek,
//           endOfWeek,
//           null,
//           "[]"
//         )
//       );
//     case "Mes":
//       const startOfMonth = today.startOf("month");
//       const endOfMonth = today.endOf("month");
//       return transactions.filter((item) =>
//         dayjs(item.date, "ddd DD MMM YYYY").isBetween(
//           startOfMonth,
//           endOfMonth,
//           null,
//           "[]"
//         )
//       );
//     default:
//       return transactions;
//   }
// };

// interface ITransactions {
//   email: string;
//   amount: number;
//   date: string;
//   time: string;
// }

// const Ganancias = () => {
//   const [totalTipsAmount, setTotalTipsAmount] = useState(0);
//   const [tipCount, setTipCount] = useState(0);
//   const [selectedFilter, setSelectedFilter] = useState("");
//   const [filteredTransactions, setFilteredTransaction] = useState<
//     ITransactions[]
//   >([]);
//   const history = useNavigate();
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

//   useEffect(() => {
//     const filtered = handleFilter(selectedFilter);
//     setFilteredTransaction(filtered);

//     const totalAmount = filtered
//       .reduce((sum, transaction) => sum + transaction.amount, 0)
//       .toFixed(2);
//     setTotalTipsAmount(parseInt(totalAmount));
//     setTipCount(filtered.length);
//   }, [selectedFilter]);

//   return (
//     <IncomesContainer>
//       {/* <HeaderContainer>
//         <HeaderContentContainer>
//           <LogoContainer src={DonRamon} />
//           <HeaderLeftContainer>
//             <Typography variant="h6" color="#F5F5F5">
//               Taquería Madrid
//             </Typography>
//             <StarNotifMenu>
//               <RatingContainer>
//                 <StarIcon
//                   sx={{
//                     color: "#EED42F",
//                   }}
//                 />
//                 <Typography variant="body1" color="#F5F5F5">
//                   0.0
//                 </Typography>
//               </RatingContainer>
//               <Badge badgeContent={0} color="error">
//                 <NotificationsOutlinedIcon
//                   fontSize="large"
//                   sx={{ color: "#FFFFFF" }}
//                 />
//               </Badge>

//               <MenuIconContainer>
//                 <MenuIcon fontSize="large" sx={{ color: "#FFFFFF" }} />
//               </MenuIconContainer>
//             </StarNotifMenu>
//           </HeaderLeftContainer>
//         </HeaderContentContainer>
//       </HeaderContainer> */}
//       <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
//       <GananciasDetail
//         totalTipsAmount={totalTipsAmount}
//         tipCount={tipCount}
//         selectedFilter={selectedFilter}
//         setSelectedFilter={setSelectedFilter}
//       />
//       <TransactionHistoryContainer>
//         <HistoryHeader>
//           <Typography variant="h6" fontWeight={"bold"}>
//             Historial de transacciones
//           </Typography>
//           <Link variant="h6" sx={{ color: "#4443b4", textDecoration: "none" }}>
//             Ver todas
//           </Link>
//         </HistoryHeader>
//         {filteredTransactions && filteredTransactions.length > 0 ? (
//           <DetailPreviewContainer>
//             {filteredTransactions.map((item, index) => (
//               <div
//                 key={index}
//                 style={{
//                   height: "60px",
//                   display: "flex",
//                   alignItems: "center",
//                   marginBottom: "10px",
//                   gap: "10px",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: "48px",
//                     height: "48px",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#f2f2f8",
//                     border: "1px solid #dcd4dc",
//                     borderRadius: "50%",
//                   }}
//                 >
//                   <IconButton
//                     onClick={() => {
//                       history("/detalleTransction");
//                     }}
//                   >
//                     <NorthEastOutlinedIcon
//                       sx={{
//                         strokeWidth: "1",
//                         fontSize: "30px",
//                         color: "#7c7c7c",
//                       }}
//                     />
//                   </IconButton>
//                 </Box>
//                 <CenterPreviewContent>
//                   <Typography variant="h6">Desde {item.email}</Typography>
//                   <Typography>
//                     {item.date} {item.time}
//                   </Typography>
//                 </CenterPreviewContent>
//                 <div
//                   style={{
//                     height: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     marginTop: "10px",
//                     marginLeft: "40px",
//                   }}
//                 >
//                   <Typography sx={{ color: "#4dab7b", fontWeight: "bold" }}>
//                     + € {item.amount.toFixed(2)}
//                   </Typography>
//                 </div>
//               </div>
//             ))}
//           </DetailPreviewContainer>
//         ) : (
//           <Typography variant="body1">
//             No hay transacciones para mostrar.
//           </Typography>
//         )}
//       </TransactionHistoryContainer>
//     </IncomesContainer>
//   );
// };

// export default Ganancias;
// const IncomesContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   flexDirection: "column",

//   [theme.breakpoints.up("md")]: {
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
// }));

// const HeaderContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   width: "100%",
//   height: "250px",
//   backgroundColor: "#4443b4",
//   padding: "15px",
//   [theme.breakpoints.up("sm")]: {
//     height: "200px",
//     padding: "20px",
//   },
//   [theme.breakpoints.up("md")]: {
//     height: "250px",
//     padding: "25px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     height: "250px",
//     padding: "30px",
//   },
// }));

// const HeaderContentContainer = styled(Box)({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   width: "100%",
//   height: "80px",
//   padding: "15px",
// });

// const RatingContainer = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   gap: "2px",
// });

// const MenuIconContainer = styled(Box)({});

// const LogoContainer = styled("img")({
//   width: "100px",
//   height: "50px",
//   //borderRadius: "50%",
//   marginRight: "15px",
// });

// const StarNotifMenu = styled(Box)({
//   display: "flex",
//   gap: "10px",
// });

// const HeaderLeftContainer = styled(Box)({
//   width: "100%",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// });

// const TransactionHistoryContainer = styled(Box)(({ theme }) => ({
//   width: "90%",
//   //marginTop: "170px",
//   // marginLeft: "20px",
//   [theme.breakpoints.up("sm")]: {
//     width: "80%",
//     marginTop: "240px",
//   },
//   [theme.breakpoints.up("md")]: {
//     width: "70%",
//     marginTop: "200px",
//     marginLeft: "50px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     width: "60%",
//     marginTop: "230px",
//     marginLeft: "100px",
//   },
// }));

// const HistoryHeader = styled(Box)({
//   width: "100%",
//   height: "auto",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// });

// const DetailPreviewContainer = styled(Box)(({ theme }) => ({
//   width: "100%",
//   height: "300px",
//   display: "flex",
//   flexDirection: "column",
//   marginTop: "30px",
//   gap: "15px",
//   overflowY: "scroll",

//   [theme.breakpoints.up("sm")]: {
//     width: "100%",
//     height: "500px",
//     marginTop: "50px",
//     marginLeft: "70px",
//     overflowX: "scroll",
//   },

//   [theme.breakpoints.up("md")]: {
//     width: "100%",
//     height: "500px",
//     marginTop: "50px",
//     marginLeft: "100px",
//     overflowX: "scroll",
//   },
// }));

// const CenterPreviewContent = styled(Box)({
//   marginLeft: "10px",
//   height: "100%",
// });
