import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Badge,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  SyncAlt as SyncAltIcon,
  AssignmentReturned as AssignmentReturnedIcon,
  Analytics as AnalyticsIcon,
  Notifications as NotificationsIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { styled } from "@mui/material/styles";
import logo from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import { AuthContext } from "../../context/authContext";

const drawerWidth = 240;

const AppBarMenu = ({ isDrawerOpen, toggleDrawer }) => {
  const history = useNavigate();
  const location = useLocation();
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const { logout } = useContext(AuthContext);
  const userType = localStorage.getItem("tipoUser");

  return (
    <>
      <AppBarStyled
        position="fixed"
        sx={{
          backgroundColor: "#272541",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <ToolbarStyled>
          {location.pathname === "/botes" ? (
            <UserInfoContainer>
              <UserAvatar src={logo} alt="User Avatar" />
              <Typography variant="h6">Botes</Typography>
            </UserInfoContainer>
          ) : (
            <UserInfoContainer>
              <UserAvatar src={logo} alt="User Avatar" />
              <Typography variant="h6">Hola,</Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  letterSpacing: 1,
                  paddingLeft: 1,
                  fontSize: "22px",
                }}
              >
                {user.firstName ? user.firstName : user.companies[0].name}
              </Typography>
            </UserInfoContainer>
          )}
          <BoxContainer>
            {/* 
            {location.pathname !== "/botes" && (
              <RatingContainer>
                <StarIconStyled />
                <Typography variant="body1">0.0</Typography>
              </RatingContainer>
            )}
           <NotificationsContainer>
              <IconButton
                color="inherit"
                aria-label="notifications"
                onClick={() => history("/notificaciones")}
              >
                <Badge badgeContent={3} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </NotificationsContainer> */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </BoxContainer>
        </ToolbarStyled>
      </AppBarStyled>
      <DrawerStyled
        variant="temporary"
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <DrawerPaperStyled>
          <Toolbar />
          <DrawerContainerStyled>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "90vh" }}
            >
              <List>
                <ListItem onClick={() => history("/home")}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inicio" />
                </ListItem>
                <Divider />
                <ListItem onClick={() => history("/perfil")}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Perfil" />
                </ListItem>
                <Divider />
                {/*  <ListItem onClick={() => history("/transacciones")}>
                  <ListItemIcon>
                    <SyncAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transacciones" />
                </ListItem> */}
                <Divider />
                {/*  <ListItem onClick={() => history("/notificaciones")}>
                  <ListItemIcon>
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Notificaciones" />
                </ListItem> */}
                <Divider />
                {user.userType && user.userType === "empresa" && (
                  <ListItem onClick={() => history("/botes")}>
                    <ListItemIcon>
                      <AssignmentReturnedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Botes" />
                  </ListItem>
                )}
                <Divider />
                <ListItem
                  onClick={() =>
                    userType === "individuo"
                      ? history("/home")
                      : history("/home")
                  }
                >
                  <ListItemIcon>
                    <AnalyticsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Analiticas" />
                </ListItem>
                <Divider />
              </List>
              <Box sx={{ flexGrow: 1 }} />
              <List>
                <ListItem onClick={() => logout()}>
                  <ListItemIcon>
                    <MeetingRoomIcon sx={{ color: "lightcoral" }} />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesion" />
                </ListItem>
              </List>
            </Box>
          </DrawerContainerStyled>
        </DrawerPaperStyled>
      </DrawerStyled>
    </>
  );
};

export default AppBarMenu;

const AppBarStyled = styled(AppBar)({});

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
});

const DrawerPaperStyled = styled("div")({
  width: drawerWidth,
});

const DrawerContainerStyled = styled("div")({
  overflow: "auto",
  // display: "flex",
  // justifyContent: "space-around",
});

const ToolbarStyled = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const UserInfoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const UserAvatar = styled("img")({
  width: "65px",
  height: "40px",
  marginRight: "10px",
});

const BoxContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
});

const NotificationsContainer = styled("div")({
  display: "flex",
  marginRight: "15px",
});

const RatingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "10px",
});

const StarIconStyled = styled(StarIcon)({
  color: "#EED42F",
  marginRight: "10px",
});
