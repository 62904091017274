import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "../../../AxiosConfig";

const AgregarMiembros: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const { boteId: initialBoteId } = location.state || {};
  const [boteId, setBoteId] = useState<string | null>(initialBoteId || null);
  const [botes, setBotes] = useState<any[]>([]);
  const [boteTipo, setBoteTipo] = useState<string | null>(null);
  const [botesSeteados, setBotesSeteados] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<any[]>([]);
  const [addedMembers, setAddedMembers] = useState<any[]>([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserRole, setNewUserRole] = useState("user");
  const [newUserPercentage, setNewUserPercentage] = useState<number | null>(
    null
  );
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    const fetchBotes = async () => {
      if (
        user &&
        user.companies &&
        user.companies.length > 0 &&
        !botesSeteados
      ) {
        console.log(user.companies[0]);
        try {
          const response = await axios.get(
            `/botes/company/${user.companies[0]._id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setBotes(response.data.botes);
          setBotesSeteados(true);
        } catch (error) {
          console.error("Error fetching boats:", error);
        }
      }
    };
    fetchBotes();
  }, [user]);

  const fetchBoteDetails = async (boteId) => {
    try {
      const response = await axios.get(`/botes/${boteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setBoteTipo(response.data.bote.tipo);
      setAddedMembers(response.data.bote.members);
    } catch (error) {
      console.error("Error fetching bote details:", error);
    }
  };

  useEffect(() => {
    if (boteId) {
      fetchBoteDetails(boteId);
    }
  }, [boteId]);

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    try {
      const response = await axios.get(`/users/search?term=${term}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setSearchResults(response.data.users);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  const handleSelectPerson = (persona: any) => {
    setSelectedPeople([...selectedPeople, { ...persona, percentage: 0 }]);
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleRemovePerson = (persona: any) => {
    console.log("persona", persona);
    const updatedPeople = selectedPeople.filter(
      (p) => p.email !== persona.email
    );
    setSelectedPeople(updatedPeople);
  };

  const handleAddNewUser = () => {
    if (
      newUserEmail &&
      // newUserFirstName &&
      // newUserLastName &&
      (!boteTipo || boteTipo !== "Manual" || newUserPercentage !== null)
    ) {
      setSelectedPeople([
        ...selectedPeople,
        {
          email: newUserEmail,
          // firstName: newUserFirstName,
          // lastName: newUserLastName,
          role: newUserRole,
          percentage: newUserPercentage,
        },
      ]);
      setNewUserEmail("");
      setNewUserFirstName("");
      setNewUserLastName("");
      setNewUserRole("user");
      setNewUserPercentage(null);
    }
  };

  const handlePercentageChange = (index: number, value: number) => {
    const updatedPeople = selectedPeople.map((person, i) =>
      i === index ? { ...person, percentage: value } : person
    );
    setSelectedPeople(updatedPeople);
  };

  const handleRoleChange = (index: number, value: string) => {
    const updatedPeople = selectedPeople.map((person, i) =>
      i === index ? { ...person, role: value } : person
    );
    setSelectedPeople(updatedPeople);
  };

  const validatePercentages = () => {
    if (boteTipo === "Manual") {
      const totalPercentage = selectedPeople.reduce(
        (sum, person) => sum + (person.percentage || 0),
        0
      );
      return totalPercentage === 100;
    }
    return true;
  };

  /* const validateIndividual = () => {
    if (boteTipo === "Individual") {
      return selectedPeople.length === 1;
    }
    return true;
  }; */

  const handleAgregarMiembros = async () => {
    if (!validatePercentages()) {
      alert("La suma de los porcentajes debe ser igual a 100%");
      return;
    }
    /* if (!validateIndividual()) {
      alert("En botes individuales la cantidad de miembros es 1");
      return;
    } */
    try {
      for (const persona of selectedPeople) {
        await axios.post(
          `/botes/${boteId}/members`,
          {
            email: persona.email,
            role: persona.role,
            percentage: persona.percentage,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      setAddedMembers([...addedMembers, ...selectedPeople]);
      setSelectedPeople([]);
      history("/botes");
    } catch (error) {
      console.error("Error adding members:", error);
    }
  };

  const handleUpdateMember = async (index: number, updatedMember: any) => {
    try {
      await axios.put(
        `/botes/${boteId}/members/${updatedMember._id}`,
        { role: updatedMember.role, percentage: updatedMember.percentage },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedMembers = addedMembers.map((member, i) =>
        i === index ? updatedMember : member
      );
      setAddedMembers(updatedMembers);
    } catch (error) {
      console.error("Error updating member:", error);
    }
  };

  return (
    <>
      <Header>
        <IconButton
          onClick={() => history("/crearBote")}
          style={{ color: "#FFFFFF" }}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ flexGrow: 1, textAlign: "center", color: "#FFFFFF" }}
        >
          Agregar miembros
        </Typography>
        <Box sx={{ width: 48 }} />
      </Header>
      <ContentContainer>
        <ProfileCard>
          <InnerBox>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Botes</InputLabel>
              <Select
                value={boteId}
                onChange={(e) => setBoteId(e.target.value as string)}
                label="Botes"
              >
                {botes.map((bote) => (
                  <MenuItem key={bote._id} value={bote._id}>
                    {bote.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <NewUserContainer>
              <Typography variant="h6">Agregar nuevo usuario</Typography>
              {/* <FormControl fullWidth variant="outlined" margin="dense">
                <StyledTextField
                  label="Nombre"
                  variant="filled"
                  value={newUserFirstName}
                  onChange={(e) => setNewUserFirstName(e.target.value)}
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="dense">
                <StyledTextField
                  label="Apellido"
                  variant="filled"
                  value={newUserLastName}
                  onChange={(e) => setNewUserLastName(e.target.value)}
                  fullWidth
                />
              </FormControl> */}
              <FormControl fullWidth margin="dense">
                <StyledTextField
                  label="Email"
                  variant="filled"
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Tipo de Usuario</InputLabel>
                <Select
                  value={newUserRole}
                  onChange={(e) => setNewUserRole(e.target.value as string)}
                  label="Tipo de Usuario"
                >
                  <MenuItem value="user">Usuario</MenuItem>
                  <MenuItem value="admin">Administrador</MenuItem>
                </Select>
              </FormControl>
              {boteTipo === "Manual" && (
                <FormControl fullWidth margin="dense">
                  <StyledTextField
                    label="Porcentaje"
                    type="number"
                    variant="filled"
                    value={newUserPercentage ?? ""}
                    onChange={(e) =>
                      setNewUserPercentage(parseFloat(e.target.value))
                    }
                    fullWidth
                  />
                </FormControl>
              )}
              <StyledButton
                variant="contained"
                onClick={handleAddNewUser}
                sx={{ marginLeft: "20px" }}
              >
                Agregar
              </StyledButton>
            </NewUserContainer>
            <ScrollableBox>
              {searchResults.map((persona, index) => (
                <SearchResultBox key={index}>
                  <SearchResultItem>
                    <Avatar>{persona.firstName?.charAt(0)}</Avatar>
                    <TextContainer>
                      <Typography>
                        {persona.firstName} {persona.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {persona.email}
                      </Typography>
                    </TextContainer>
                    <IconButton onClick={() => handleSelectPerson(persona)}>
                      <PersonAddIcon color="primary" />
                    </IconButton>
                  </SearchResultItem>
                </SearchResultBox>
              ))}
              {selectedPeople.length > 0 && (
                <SelectedPeopleContainer>
                  <Typography variant="h6">Personas seleccionadas:</Typography>
                  {selectedPeople.map((persona, index) => (
                    <SelectedPersonBox key={index}>
                      <SearchResultItem>
                        <Avatar>{persona.firstName?.charAt(0)}</Avatar>
                        <TextContainer>
                          <Typography>
                            {persona.firstName} {persona.lastName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {persona.email}
                          </Typography>
                        </TextContainer>
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          sx={{ minWidth: 100, marginRight: 2 }}
                        >
                          <InputLabel>Tipo Usuario</InputLabel>
                          <Select
                            value={persona.role}
                            onChange={(e) =>
                              handleRoleChange(index, e.target.value as string)
                            }
                            label="Tipo de Usuario"
                            size="small"
                          >
                            <MenuItem value="user">Usuario</MenuItem>
                            <MenuItem value="admin">Administrador</MenuItem>
                          </Select>
                        </FormControl>
                        {boteTipo === "Manual" && (
                          <StyledTextField
                            label="Porcentaje"
                            type="number"
                            variant="outlined"
                            value={persona.percentage ?? ""}
                            onChange={(e) =>
                              handlePercentageChange(
                                index,
                                parseFloat(e.target.value)
                              )
                            }
                            size="small"
                            sx={{ maxWidth: 80 }}
                          />
                        )}
                        <IconButton onClick={() => handleRemovePerson(persona)}>
                          <CloseIcon />
                        </IconButton>
                      </SearchResultItem>
                    </SelectedPersonBox>
                  ))}
                </SelectedPeopleContainer>
              )}
            </ScrollableBox>
            <StyledButton
              variant="contained"
              onClick={handleAgregarMiembros}
              sx={{
                position: "fixed",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                width: "90%",
              }}
            >
              Confirmar
            </StyledButton>
            {addedMembers.length > 0 && (
              <AddedMembersContainer>
                <Typography variant="h6">Miembros añadidos:</Typography>
                {addedMembers.map((persona, index) => (
                  <AddedMemberBox key={index}>
                    <SearchResultItem>
                      <Avatar>{persona.userId?.firstName?.charAt(0)}</Avatar>
                      <TextContainer>
                        <Typography>
                          {persona.userId?.firstName} {persona.userId?.lastName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {persona.userId?.email}
                        </Typography>
                      </TextContainer>
                      <FormControl
                        variant="outlined"
                        margin="normal"
                        sx={{ minWidth: 100, marginRight: 2 }}
                      >
                        <InputLabel>Tipo Usuario</InputLabel>
                        <Select
                          value={persona.userId?.role}
                          onChange={(e) => {
                            const updatedMember = {
                              ...persona,
                              role: e.target.value as string,
                            };
                            handleUpdateMember(index, updatedMember);
                          }}
                          label="Tipo de Usuario"
                          size="small"
                        >
                          <MenuItem value="user">Usuario</MenuItem>
                          <MenuItem value="admin">Administrador</MenuItem>
                        </Select>
                      </FormControl>
                      {boteTipo === "Manual" && (
                        <StyledTextField
                          label="Porcentaje"
                          type="number"
                          variant="outlined"
                          value={persona.percentage ?? ""}
                          onChange={(e) => {
                            const updatedMember = {
                              ...persona,
                              percentage: parseFloat(e.target.value),
                            };
                            handleUpdateMember(index, updatedMember);
                          }}
                          size="small"
                          sx={{ maxWidth: 80 }}
                        />
                      )}
                    </SearchResultItem>
                  </AddedMemberBox>
                ))}
              </AddedMembersContainer>
            )}
          </InnerBox>
        </ProfileCard>
      </ContentContainer>
    </>
  );
};

export default AgregarMiembros;

const ContentContainer = styled(Box)({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f9f9f9",
  overflow: "hidden",
  width: "100%", // Prevent horizontal scroll
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "60px",
  padding: "0 20px",
  backgroundColor: "#272541",
});

const ProfileCard = styled(Box)({
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  padding: "20px",
  margin: "20px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "calc(100vh - 120px)",
  overflowY: "auto",
});

const InnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
});

const ScrollableBox = styled(Box)({
  width: "100%",
  overflowY: "auto",
  maxHeight: "300px",
  marginBottom: "20px",
});

const StyledTextField = styled(TextField)({
  backgroundColor: "white",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
});

const SearchResultBox = styled(Box)({
  marginBottom: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  padding: "10px",
  backgroundColor: "white",
});

const SearchResultItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Avatar = styled(Box)({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: "#e0e0e0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  fontWeight: "bold",
  color: "#757575",
});

const TextContainer = styled(Box)({
  flex: 1,
  marginLeft: "10px",
});

const SelectedPeopleContainer = styled(Box)({
  marginTop: "20px",
  width: "100%",
});

const SelectedPersonBox = styled(Box)({
  marginBottom: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  padding: "10px",
  backgroundColor: "white",
});

const StyledButton = styled(Button)({
  marginTop: "20px",
  width: "90%",
  height: "50px",
  alignItems: "center",
  borderRadius: "15px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#F7E000",
    color: "#272541",
  },
});

const AddedMembersContainer = styled(Box)({
  marginTop: "20px",
  width: "100%",
});

const AddedMemberBox = styled(Box)({
  marginBottom: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  padding: "10px",
  backgroundColor: "white",
});

const NewUserContainer = styled(Box)({
  marginTop: "20px",
  width: "100%",
});
