import React from "react";
import { Card, Typography, IconButton, Box, Link } from "@mui/material";
import { styled } from "@mui/system";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Close";

const CustomCard: React.FC<{
  title: string;
  content: string;
  onArchive: () => void;
}> = ({ title, content, onArchive }) => {
  return (
    <StyledCard>
      <CardContentWrapper>
        <LeftBox>
          <DetailsIconButton>
            <ArchiveIconStyled />
          </DetailsIconButton>
        </LeftBox>
        <CenterBox>
          <InviteText>
            Te han invitado a unirte al bote <TitleText>{title}</TitleText>
          </InviteText>
          <Typography variant="body2" color="textSecondary">
            {content}
          </Typography>
          <StyledLink href="#">Ver</StyledLink>
        </CenterBox>
        <RightBox>
          <DeleteIconButton onClick={onArchive}>
            <DeleteIcon />
          </DeleteIconButton>
        </RightBox>
      </CardContentWrapper>
    </StyledCard>
  );
};

export default CustomCard;

const StyledCard = styled(Card)({
  margin: "10px 0",
  width: "90%",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  padding: "15px",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(39, 37, 65, 0.1)",
  },
  "@media (max-width: 768px)": {
    width: "100%",
  },
});

const CardContentWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
});

const LeftBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20%",
});

const CenterBox = styled(Box)({
  textAlign: "left",
  width: "80%",
});

const RightBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "10%",
});

const InviteText = styled(Typography)({
  fontSize: "14px",
  color: "#272541",
  marginTop: "10px",
});

const TitleText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
  color: "#272541",
});

const DetailsIconButton = styled(IconButton)({
  color: "#272541",
});

const ArchiveIconStyled = styled(ArchiveIcon)({
  width: "50px",
  height: "50px",
});

const DeleteIconButton = styled(IconButton)({
  color: "#272541",
});

const StyledLink = styled(Link)({
  color: "#272541",
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: "bold",
  marginTop: "5px",
  display: "inline-block",
});

// import React from "react";
// import { Card, Typography, IconButton, Box, Link } from "@mui/material";
// import { styled } from "@mui/system";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import DeleteIcon from "@mui/icons-material/Close";

// const CustomCard: React.FC<{
//   title: string;
//   content: string;
//   onArchive: () => void;
// }> = ({ title, content, onArchive }) => {
//   return (
//     <StyledCard>
//       <CardContentWrapper>
//         <LeftBox>
//           <DetailsIconButton>
//             <ArchiveIconStyled />
//           </DetailsIconButton>
//         </LeftBox>
//         <CenterBox>
//           <InviteText>
//             Te han invitado a unirte al bote <TitleText>{title}</TitleText>
//           </InviteText>
//           <Typography variant="body2" color="textSecondary">
//             {content}
//           </Typography>
//           <Link>Ver</Link>
//         </CenterBox>
//         <RightBox>
//           <DeleteIconButton onClick={onArchive}>
//             <DeleteIcon />
//           </DeleteIconButton>
//         </RightBox>
//       </CardContentWrapper>
//     </StyledCard>
//   );
// };

// export default CustomCard;

// const StyledCard = styled(Card)({
//   margin: "10px",
//   width: "90%",
//   height: "130px",
//   "@media (max-width: 768px)": {
//     width: "350px",
//   },
//   "&:hover": {
//     backgroundColor: "#eee",
//   },
// });

// const CardContentWrapper = styled(Box)({
//   display: "flex",
//   justifyContent: "space-around",
// });

// const LeftBox = styled(Box)({});

// const CenterBox = styled(Box)({
//   textAlign: "justify",
// });

// const RightBox = styled(Box)({});

// const InviteText = styled(Typography)({
//   variant: "body2",
//   color: "textSecondary",
//   marginTop: "10px",
// });

// const TitleText = styled(Typography)({
//   variant: "h6",
//   component: "h2",
//   fontWeight: "bold",
//   color: "black",
// });

// const DetailsIconButton = styled(IconButton)({
//   color: "#333",
//   minHeight: "50px",
// });

// const ArchiveIconStyled = styled(ArchiveIcon)({
//   width: "50px",
//   height: "50px",
// });

// const DeleteIconButton = styled(IconButton)({});
