import React, { useState } from "react";
import { Card, Typography, IconButton, Box, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import ArchiveIcon from "@mui/icons-material/Archive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";

const CustomCard: React.FC<{
  date: string;
  title: string;
  content: string;
  amount: number;
  fee: number;
  net: number;
  onArchive: () => void;
}> = ({ date, title, content, onArchive, amount, fee, net }) => {
  const [expanded, setExpanded] = useState(false);

  // Convertir fee de centavos a euros
  const feeInEuros = fee / 100;

  // Tarifa fija de Stripe en euros
  const stripeFixedFee = 0.25;

  // Calcular la comisión total menos la tarifa fija de Stripe
  const remainingFee = feeInEuros - stripeFixedFee;

  // Las tasas de comisión de Stripe y Tipwise
  const stripePercentage = 1.5 / 100;
  const tipwisePercentage = 5 / 100;

  // Calcular el factor total (stripe + tipwise)
  const totalPercentage = stripePercentage + tipwisePercentage;

  // Desglosar la comisión en base a las proporciones
  const stripeFeeVariable = (remainingFee * stripePercentage) / totalPercentage;
  const tipwiseFee = (remainingFee * tipwisePercentage) / totalPercentage;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderIcon = () => {
    if (content === "succeeded") {
      return (
        <CheckCircleIcon
          style={{ color: "gray", width: "30px", height: "30px" }}
        />
      );
    } else if (content === "pending") {
      return (
        <PendingActionsIcon
          style={{ color: "orange", width: "30px", height: "30px" }}
        />
      );
    } else if (content === "payment_failed") {
      return (
        <SmsFailedIcon
          style={{ color: "red", width: "30px", height: "30px" }}
        />
      );
    } else if (content === "available") {
      return (
        <CheckCircleIcon
          style={{ color: "green", width: "30px", height: "30px" }}
        />
      );
    } else {
      return <Typography variant="h6">{content}</Typography>;
    }
  };

  return (
    <TransaccionesCard>
      <CardContentWrapper>
        <Box>
          <DetailsIconButton onClick={onArchive}>
            <ArchiveIcon
              style={{ color: "#272541", width: "30px", height: "30px" }}
            />
          </DetailsIconButton>
        </Box>
        <CenterBox onClick={handleExpandClick}>
          {/* <TitleTypography variant="body1">{date}</TitleTypography> */}
          <TitleTypography variant="body1">{title}</TitleTypography>
          <ContentTypography variant="body2">
            Neto recibido €{net / 100}
          </ContentTypography>
        </CenterBox>
        <Box>
          <TransactionAmount variant="h6">{renderIcon()}</TransactionAmount>
          <ExpandIconButton onClick={handleExpandClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandIconButton>
        </Box>
      </CardContentWrapper>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <DetailsBox>
          <Typography variant="body2" textAlign="end">
            Total €{amount / 100}
          </Typography>
          <Typography variant="body2" textAlign="end">
            Tasa de Servicio Tipwise: €{tipwiseFee.toFixed(2)}
          </Typography>
          <Typography variant="body2" textAlign="end">
            Stripe Fee: €{(stripeFeeVariable + stripeFixedFee).toFixed(2)}
          </Typography>
          <Typography variant="body2" textAlign="end">
            <hr />
            Has recibido €{net / 100}
          </Typography>
        </DetailsBox>
      </Collapse>
    </TransaccionesCard>
  );
};

export default CustomCard;

const TransaccionesCard = styled(Card)({
  margin: "10px",
  width: "800px",
  "@media (max-width: 768px)": {
    width: "700px",
  },
  "@media (max-width: 426px)": {
    width: "95%",
  },
});

const CardContentWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "10px",
});

const CenterBox = styled(Box)({
  flex: 1,
  textAlign: "center",
  cursor: "pointer",
});

const DetailsIconButton = styled(IconButton)({
  color: "#333",
});

const TitleTypography = styled(Typography)({
  fontWeight: "bold",
});

const ContentTypography = styled(Typography)({
  color: "textSecondary",
});

const TransactionAmount = styled(Typography)({
  color: "green",
});

const ExpandIconButton = styled(IconButton)({
  padding: 0,
});

const DetailsBox = styled(Box)({
  padding: "10px",
});
