import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Container,
  Divider,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import axios from 'axios';

const PaymentForm = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Tarjeta");
  const [email, setEmail] = useState("");
  const [saveDetails, setSaveDetails] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const { tipAmount, includeTransactionCost, clientSecret, timeSlot } = location.state || {};

  const tipAmountNumber = Number(tipAmount);
  const transactionCostNumber = includeTransactionCost ? 0.25 : 0;
  const totalAmount = (tipAmountNumber + transactionCostNumber).toFixed(2);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'eur',
        total: {
          label: 'Total',
          amount: Number(totalAmount), // Amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, totalAmount]);

  useEffect(() => {
    checkFormValidity();
  }, [selectedPaymentMethod, email, termsAccepted]);

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleToggle = (setter) => () => {
    setter(prev => !prev);
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      if (selectedPaymentMethod === "Tarjeta") {
        const cardElement = elements.getElement(CardElement);
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              email: email,
            },
          },
        });

        if (error) {
          console.error(error);
        } else {
          // Send payment details to the server
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/process`, {
            amount: Number(totalAmount) * 100,
            timeSlot,
            email,
            paymentMethodId: paymentIntent.payment_method,
          });

          if (response.status === 200) {
            navigate("/pagos/pagoExitoso", { state: { propina: tipAmountNumber, costosTransaccion: transactionCostNumber } });
          } else {
            console.error("Error processing payment:", response.data.message);
          }
        }
      }
    }
  };

  const checkFormValidity = () => {
    if (selectedPaymentMethod === "Tarjeta" && email && termsAccepted) {
      setIsFormValid(true);
    } else if (email && termsAccepted) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  return (
    <MainContainer>
      <FormContainer onSubmit={handleSubmit}>
        <PaymentOptions>
          <PaymentOption
            selected={selectedPaymentMethod === "Tarjeta"}
            onClick={() => handlePaymentMethodChange("Tarjeta")}
          >
            Tarjeta
          </PaymentOption>
          {paymentRequest && (
            <PaymentOption
              selected={selectedPaymentMethod === "Apple Pay"}
              onClick={() => handlePaymentMethodChange("Apple Pay")}
            >
              Apple Pay
            </PaymentOption>
          )}
        </PaymentOptions>
        {selectedPaymentMethod === "Tarjeta" && (
          <>
            <CardElementContainer>
              <CardElement options={{ style: { base: { fontSize: '18px' } }, disableLink: true }} />
            </CardElementContainer>
            <Label>Correo electrónico</Label>
            <StyledTextField
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleFieldChange(setEmail)}
              margin="normal"
            />
          </>
        )}
        {selectedPaymentMethod === "Apple Pay" && paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
        <FormControlLabel
          control={
            <PurpleSwitch
              checked={saveDetails}
              onChange={handleToggle(setSaveDetails)}
            />
          }
          label="Guardar tus datos para un proceso de compra seguro en un click con Link"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleToggle(setTermsAccepted)}
              sx={{
                color: "#5657FF",
                "&.Mui-checked": {
                  color: "#5657FF",
                },
              }}
            />
          }
          label="Acepto Términos y condiciones"
        />
      </FormContainer>
      <BottomContainer>
        <Divider />
        <TotalAmountContainer>
          <Typography variant="h6">Total a pagar</Typography>
          <Typography variant="h6">{`€ ${totalAmount}`}</Typography>
        </TotalAmountContainer>
        <PayButton
          type="submit"
          variant="contained"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Pagar
        </PayButton>
      </BottomContainer>
    </MainContainer>
  );
};

export default PaymentForm;

const MainContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
  paddingTop: "20px",
  paddingBottom: "80px",
  "@media (max-width: 768px)": {
    paddingBottom: "100px",
  },
});

const FormContainer = styled("form")({
  flex: 1,
  overflowY: "auto",
  paddingBottom: "80px",
});

const PaymentOptions = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  marginBottom: "20px",
});

const PaymentOption = styled(Button)(({ selected }) => ({
  borderBottom: selected ? "2px solid #5657FF" : "none",
  fontWeight: selected ? "bold" : "normal",
  flex: 1,
  paddingBottom: "10px",
  paddingTop: "10px",
  "&:first-of-type": {
    marginRight: "10px",
  },
}));

const CardElementContainer = styled(Box)({
  margin: "20px 0",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid #ccc",
  backgroundColor: "#fff",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
});

const Label = styled(Typography)({
  fontSize: "0.9rem",
  marginBottom: "5px",
  marginTop: "10px",
});

const PurpleSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#5657FF",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#5657FF",
  },
});

const BottomContainer = styled(Box)({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "90%",
  backgroundColor: "#fff",
  padding: "10px 20px",
  boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const TotalAmountContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
});

const PayButton = styled(Button)({
  width: "100%",
  height: "50px",
  maxWidth: "400px",
  borderRadius: "10px",
});
