import React, { useState } from "react";
import { Box, Typography, Avatar, Button, Rating, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const Calificar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { persona, propina, costosTransaccion, totalPagado } = location.state || { persona: {}, propina: 0, costosTransaccion: 0, totalPagado: 0 };
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState("");
  const [reasons, setReasons] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const reasonsForRating = {
    1: ["Mal servicio", "Demorado", "Desatento"],
    2: ["Servicio lento", "Poco cordial", "Falta de atención"],
    3: ["Servicio medio", "Nada destacable", "Aceptable"],
    4: ["Buen servicio", "Amable", "Rápido"],
    5: ["Excelente servicio", "Muy atento", "Eficiente"],
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    setReasons(reasonsForRating[newValue]);
    setReason("");
    validateForm(newValue, "");
  };

  const handleReasonSelect = (reason) => {
    setReason(reason);
    validateForm(rating, reason);
  };

  const validateForm = (rating, reason) => {
    setIsFormValid(rating > 0 && reason !== "");
  };

  const handleSubmit = () => {
    // Enviar la calificación
    navigate("/reseñaExitosa", { state: { persona, propina, costosTransaccion, totalPagado, rating, reason } });
  };

  return (
    <Container>
      <CloseButtonContainer>
        <CloseButton onClick={() => navigate("/")}>
          <CloseIcon />
        </CloseButton>
      </CloseButtonContainer>
      <Content>
        <Section>
          <Avatar src={persona.avatar} sx={{ width: 100, height: 100 }} />
          <Typography variant="h6" fontWeight="bold">
            {persona.nombre}
          </Typography>
          <Box display="flex" mt={1}>
            <Chip label="Bartender" variant="outlined" sx={{ mr: 1 }} />
            <Chip label="Camarero/a" variant="outlined" />
          </Box>
        </Section>
        <Section>
          <Typography mt={2} fontWeight="bold">
            Evaluá el servicio
          </Typography>
          <StyledRating
            name="simple-controlled"
            value={rating}
            onChange={handleRatingChange}
            size="large"
          />
        </Section>
        <Section>
          <ReasonBox>
            {reasons.map((reasonOption) => (
              <ReasonButton
                key={reasonOption}
                variant={reason === reasonOption ? "contained" : "outlined"}
                onClick={() => handleReasonSelect(reasonOption)}
              >
                {reasonOption}
              </ReasonButton>
            ))}
          </ReasonBox>
          <SubmitButton
            variant="contained"
            color="primary"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Enviar
          </SubmitButton>
        </Section>
      </Content>
    </Container>
  );
};

export default Calificar;

const Container = styled(Box)({
  paddingTop: "10px",
});

const CloseButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const CloseButton = styled(Button)({
  padding: 0,
});

const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "90vh",
  justifyContent: "space-evenly",
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledRating = styled(Rating)({
  fontSize: "3rem",
  "& .MuiRating-icon": {
    borderRadius: "50%",
  },
});

const ReasonBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  mt: 2,
});

const ReasonButton = styled(Button)({
  margin: "5px",
});

const SubmitButton = styled(Button)({
  mt: 3,
  width: "80%",
  marginTop: "30px",
});
