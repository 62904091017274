import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import ReactStars from "react-rating-stars-component";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const TransactionDetail = () => {
  const history = useNavigate();
  const rating = 4.5;

  return (
    <DetailMainContainer>
      <HeaderContainer>
        <IconButton
          onClick={() => {
            history("/ganancias");
          }}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          component="div"
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          Detalles
        </Typography>
        <Box sx={{ width: "48px" }} />{" "}
        {/* Box vacío para mantener el espacio a la derecha */}
      </HeaderContainer>

      <StyledCard>
        <StyledCardContent>
          <MonetizationOnOutlinedIcon fontSize="large" />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ padding: "0", marginBottom: "10px" }}
              >
                Pago
              </Typography>
              <Typography variant="h6">€ 4.58 EUR</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Typography variant="h6">Exitoso</Typography>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CheckCircleIcon sx={{ color: "#24a956" }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledCardContent>
      </StyledCard>

      <StyledCard>
        <StyledCardContent>
          <OutboundOutlinedIcon
            fontSize="large"
            style={{ transform: "rotate(180deg)" }}
          />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography variant="h6" sx={{ maringBottom: "10px" }}>
              Pagado por
            </Typography>
            <Typography variant="h6">pab*@gmail.com</Typography>
          </Box>
        </StyledCardContent>
      </StyledCard>

      <StyledCard>
        <StyledCardContent>
          <CompareArrowsIcon fontSize="large" />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              ID de transacción
            </Typography>
            <Typography variant="h6">
              f714bffa-2738-4ea2-9150-a21800532cac
            </Typography>
          </Box>
        </StyledCardContent>
      </StyledCard>

      <StyledCard>
        <StyledCardContent>
          <PaymentsOutlinedIcon fontSize="large" />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              Coste de Tipwise
            </Typography>
            <Box
              sx={{
                width: "35px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#c8c5fe",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography textAlign={"center"} variant="h6">
                SI
              </Typography>
            </Box>
          </Box>
        </StyledCardContent>
      </StyledCard>

      <StyledCard>
        <StyledCardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" fontWeight={"bold"} marginBottom={"20px"}>
            Detalles del pago
          </Typography>
          <PaymentDetailContainer>
            <PaymentRowContainer>
              <Typography variant="body1">Propina bruta</Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                € 5.00
              </Typography>
            </PaymentRowContainer>
            <PaymentRowContainer>
              <Typography variant="body1">Costo de transacción</Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                - € 0.42
              </Typography>
            </PaymentRowContainer>
            <PaymentRowContainer>
              <Typography variant="body1">Costo de Tipwise</Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                - € 0.25
              </Typography>
            </PaymentRowContainer>
            <Divider sx={{ marginBottom: "15px" }} />
            <PaymentRowContainer>
              <Typography variant="body1">Recibirás</Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                € 4.58
              </Typography>
            </PaymentRowContainer>
          </PaymentDetailContainer>
        </StyledCardContent>
      </StyledCard>
      <StyledCard>
        <StyledCardContent>
          <Typography variant="h5" fontWeight={"bold"}>
            Review
          </Typography>
        </StyledCardContent>
        <ReviewContainer>
          <ReactStars size={35} count={5} value={rating} edit={false} />
        </ReviewContainer>
        <Typography variant="h6" fontWeight={"bold"} sx={{ marginTop: "15px" }}>
          Motivo: Servicio atento
        </Typography>
      </StyledCard>
    </DetailMainContainer>
  );
};

export default TransactionDetail;

const DetailMainContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "20px",
  boxSizing: "border-box",
  overflow: "auto",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: 0,
  "&:last-child": {
    paddingBottom: 0,
  },
}));

const PaymentDetailContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: "15px",
  boxShadow: "none",
  border: "2px solid #dbdbdf",
}));

const PaymentRowContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "15px",
}));

const ReviewContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "40px",
}));
