import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import image from "../../../assets/OnBoarding4.jpg";
import { useNavigate } from "react-router-dom";

const OnBoarding4 = () => {
  const history = useNavigate();
  const handleContinuar = () => {
    history("/SignIn");
  };

  return (
    <MainContainer>
      <MiddleBox>
        <ImageContainer>
          <Image src={image} alt="" />
        </ImageContainer>
        <Box>
          <StyledTypography align="left" className={classes.title}>
            {"Analizar, celebrar, conectar"}
          </StyledTypography>
          <StyledTypography align="left" className={classes.subtitle}>
            {
              "Explora tus transacciones, analiza tus propinas y mantén tu perfil brillante."
            }
          </StyledTypography>
          <StyledTypography
            align="left"
            className={classes.subtitle}
            sx={{ color: "#272541" }}
          >
            {"¡Descubre lo fácil que es sentirte bien mientras ganas!"}
          </StyledTypography>
        </Box>
      </MiddleBox>
      <BottomBox>
        <StyledButton
          id="btn_siguiente"
          fullWidth
          onClick={handleContinuar}
          variant="contained"
          color="secondary"
        >
          Comenzar
        </StyledButton>
      </BottomBox>
    </MainContainer>
  );
};

export default OnBoarding4;

const PREFFIX = "OnboardingStep";

const classes = {
  title: `${PREFFIX}-title`,
  subtitle: `${PREFFIX}-subtitle`,
};

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90vh",
  padding: "20px",
  "@media (max-width: 768px)": {
    padding: "30px",
  },
});

const MiddleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const BottomBox = styled(Box)({
  width: "100%",
  paddingBottom: "20px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.title}`]: {
    fontSize: 20,
    fontWeight: 800,
    paddingBottom: 8,
    color: "#272541",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  [`&.${classes.subtitle}`]: {
    fontSize: 14,
    "@media (max-width: 768px)": {
      fontSize: 12,
    },
  },
}));

const ImageContainer = styled("div")({
  marginTop: "34px",
  marginBottom: "0px",
  "@media (max-width: 768px)": {
    marginTop: "20px",
  },
});

const StyledButton = styled(Button)({
  width: "100%",
  whiteSpace: "nowrap",
  borderRadius: "15px",
  fontWeight: 800,
  backgroundColor: "#272541",
  height: 50,
  "@media (max-width: 768px)": {
    height: 45,
  },
  "&:hover": {
    backgroundColor: "#272541",
  },
});

const Image = styled("img")({
  width: 300,
  height: 250,
  "@media (max-width: 768px)": {
    width: "100%",
    height: "auto",
  },
});

// import React from "react";
// import { styled } from "@mui/system";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import image from "../../../assets/OnBoarding4.jpg";
// import { useNavigate } from "react-router-dom";

// const OnBoarding4 = () => {
//   const history = useNavigate();
//   const handleContinuar = () => {
//     history("/SignIn");
//   };

//   return (
//     <MainContainer>
//       <OnBoardingContainer>
//         <ImageContainer>
//           <Image src={image} alt="" />
//         </ImageContainer>
//         <Box>
//           <StyledTypography align="left" className={classes.title}>
//             {"Analizar, celebrar, conectar"}
//           </StyledTypography>
//           <StyledTypography align="left" className={classes.subtitle}>
//             {
//               "Explore sus transacciones, analice sus propinas y mantenga su perfil brillante."
//             }
//           </StyledTypography>
//           <StyledTypography
//             align="left"
//             className={classes.subtitle}
//             sx={{ color: "#272541" }}
//           >
//             {"¡Descubra lo fácil que es sentirse bien mientras gana!"}
//           </StyledTypography>
//         </Box>
//         <StyledDiv>
//           <StyledButton
//             id="btn_siguiente"
//             fullWidth
//             onClick={handleContinuar}
//             variant="contained"
//             color="secondary"
//           >
//             Comenzar
//           </StyledButton>
//         </StyledDiv>
//       </OnBoardingContainer>
//     </MainContainer>
//   );
// };

// export default OnBoarding4;

// const PREFFIX = "OnboardingStep";

// const classes = {
//   title: `${PREFFIX}-title`,
//   subtitle: `${PREFFIX}-subtitle`,
// };

// const MainContainer = styled(Box)({
//   display: "flex",
//   justifyContent: "center",
//   padding: "10px",
// });

// const OnBoardingContainer = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   alignContent: "center",
//   width: 350,
//   height: 700,
//   "@media (max-width: 768px)": {
//     width: "100%",
//     height: "auto",
//     padding: "20px",
//   },
// });

// const StyledTypography = styled(Typography)(({ theme }) => ({
//   [`&.${classes.title}`]: {
//     fontSize: 20,
//     fontWeight: 800,
//     paddingBottom: 8,
//     "@media (max-width: 768px)": {
//       fontSize: 18,
//     },
//   },
//   [`&.${classes.subtitle}`]: {
//     fontSize: 14,
//     "@media (max-width: 768px)": {
//       fontSize: 12,
//     },
//   },
// }));

// const ImageContainer = styled("div")({
//   marginTop: "34px",
//   marginBottom: "0px",
//   "@media (max-width: 768px)": {
//     marginTop: "20px",
//   },
// });

// const StyledDiv = styled("div")({
//   width: 328,
//   position: "relative",
//   bottom: 30,
//   "@media (max-width: 768px)": {
//     width: "100%",
//     bottom: 20,
//   },
// });

// const StyledButton = styled(Button)({
//   marginTop: 75,
//   whiteSpace: "nowrap",
//   borderRadius: 8,
//   fontWeight: 800,
//   backgroundColor: "#272541",
//   height: 50,
//   "@media (max-width: 768px)": {
//     marginTop: 50,
//     height: 45,
//   },
//   "&:hover": {
//     backgroundColor: "#272541",
//   },
// });

// const Image = styled("img")({
//   width: 300,
//   height: 250,
//   "@media (max-width: 768px)": {
//     width: "100%",
//     height: "auto",
//   },
// });
