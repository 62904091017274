import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AppBarMenu from "../../navBar/NavBarMenu.tsx";
import { styled } from "@mui/material/styles";

const BotesEmpresa: React.FC<{
  newBotes: { title: string; content: string }[];
  invitationBotes: { title: string; content: string }[];
}> = ({ newBotes, invitationBotes }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useNavigate();

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <>
      <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <BoxButtonNotificaciones>
        <Button onClick={() => history("/home")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <BoxLabelNotificaciones>
          <LabelNotificaciones>Notificaciones</LabelNotificaciones>
        </BoxLabelNotificaciones>
      </BoxButtonNotificaciones>
      <ContentBox>
        <InnerContentBox>
          <Typography fontWeight="bold">No hay botes creados</Typography>
          <Typography textAlign="center">
            Crea botes para repartir las propinas entre sus empleados
          </Typography>
        </InnerContentBox>
        <CreateButton
          variant="contained"
          onClick={() => {
            history("/crearBote");
          }}
        >
          <Typography>Crear Bote</Typography>
        </CreateButton>
      </ContentBox>
    </>
  );
};

export default BotesEmpresa;

const BoxButtonNotificaciones = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
});

const BoxLabelNotificaciones = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const LabelNotificaciones = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const ContentBox = styled(Box)({
  //marginTop: "100px",
  width: "100%",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const InnerContentBox = styled(Box)({
  width: "100%",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const CreateButton = styled(Button)({
  width: "300px",
});
