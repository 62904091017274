import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import image from "../../../assets/OnBoarding3.jpg";
import { useNavigate } from "react-router-dom";

const OnBoarding3 = () => {
  const history = useNavigate();
  const handleContinuar = () => {
    history("/OnBoarding4");
  };
  const handleSaltar = () => {
    history("/SignIn");
  };

  return (
    <MainContainer>
      <TopBox>
        <BackButton variant="contained" onClick={handleSaltar}>
          {"Saltar"}
        </BackButton>
      </TopBox>
      <MiddleBox>
        <ImageContainer>
          <Image src={image} alt="" />
        </ImageContainer>
        <Box>
          <StyledTypography align="left" className={classes.title}>
            {"Empodera tu negocio, une a tus equipos."}
          </StyledTypography>
          <StyledTypography align="left" className={classes.subtitle}>
            {
              "Los dueños de negocios crean códigos QR únicos. Los clientes escanean y dan propina."
            }
          </StyledTypography>
          <StyledTypography
            align="left"
            className={classes.subtitle}
            sx={{ color: "#272541" }}
          >
            {"¡Fácil para los clientes, transparente para los equipos!"}
          </StyledTypography>
        </Box>
      </MiddleBox>
      <BottomBox>
        <StyledButton
          fullWidth
          onClick={handleContinuar}
          variant="contained"
          color="secondary"
        >
          Siguiente
        </StyledButton>
      </BottomBox>
    </MainContainer>
  );
};

export default OnBoarding3;

const PREFFIX = "OnboardingStep";

const classes = {
  title: `${PREFFIX}-title`,
  subtitle: `${PREFFIX}-subtitle`,
  saltearText: `${PREFFIX}-saltearText`,
};

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90vh",
  padding: "20px",
  "@media (max-width: 768px)": {
    padding: "30px",
  },
});

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const TopBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const MiddleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const BottomBox = styled(Box)({
  width: "100%",
  paddingBottom: "20px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.title}`]: {
    fontSize: 20,
    fontWeight: 800,
    paddingBottom: 8,
    color: "#272541",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  [`&.${classes.subtitle}`]: {
    fontSize: 14,
    "@media (max-width: 768px)": {
      fontSize: 12,
    },
  },
  [`&.${classes.saltearText}`]: {
    fontSize: 16,
    fontWeight: 600,
    color: "#272541",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      fontSize: 14,
    },
  },
}));

const ImageContainer = styled("div")({
  marginTop: "34px",
  marginBottom: "0px",
  "@media (max-width: 768px)": {
    marginTop: "20px",
  },
});

const StyledButton = styled(Button)({
  width: "100%",
  whiteSpace: "nowrap",
  borderRadius: "15px",
  fontWeight: 800,
  backgroundColor: "#272541",
  height: 50,
  "@media (max-width: 768px)": {
    height: 45,
  },
  "&:hover": {
    backgroundColor: "#272541",
  },
});

const Image = styled("img")({
  width: 300,
  height: 250,
  "@media (max-width: 768px)": {
    width: "100%",
    height: "auto",
  },
});
