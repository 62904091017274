// components/CardBotes.tsx
import React from "react";
import { Card, Typography, IconButton, Box } from "@mui/material";
import { styled } from "@mui/system";
import TableBarIcon from "@mui/icons-material/TableBar";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

const BotesCard: React.FC<{
  title: string;
  content: string;
  onArchive: () => void;
  onGenerateQRCode: () => void;
  onClick: () => void;
  usersNumber: string;
}> = ({
  title,
  content,
  onArchive,
  onGenerateQRCode,
  onClick,
  usersNumber,
}) => {
  return (
    <BoteCard onClick={onClick}>
      <CardContentWrapper>
        <Box>
          <DetailsIconButton>
            <TableBarIcon sx={{ width: "50px", height: "50px" }} />
          </DetailsIconButton>
        </Box>
        <Box textAlign="justify">
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            color="black"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {content}
          </Typography>
          <IconButton>
            <SupervisorAccountIcon />
            <Typography sx={{ marginLeft: "3px" }}>{usersNumber}</Typography>
          </IconButton>
        </Box>
      </CardContentWrapper>
    </BoteCard>
  );
};

export default BotesCard;

const BoteCard = styled(Card)({
  margin: "10px",
  width: "800px",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    width: "350px",
  },
  borderRadius: "10px",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const CardContentWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
});

const DetailsIconButton = styled(IconButton)({
  color: "#333",
});
