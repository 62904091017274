// components/CardInvitacionBote.tsx
import React from "react";
import { Card, Typography, IconButton, Box } from "@mui/material";
import { styled } from "@mui/system";
import TableBarIcon from "@mui/icons-material/TableBar";
import CloseIcon from "@mui/icons-material/Close";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckIcon from "@mui/icons-material/Check";
import { green, red } from "@mui/material/colors";

const InvitacionBotesCard: React.FC<{
  title: string;
  content: string;
  onArchive: () => void;
}> = ({ title, content, onArchive }) => {
  return (
    <InvitacionCard>
      <CardContentWrapper>
        <Box>
          <DetailsIconButton>
            <TableBarIcon sx={{ width: "50px", height: "50px" }} />
          </DetailsIconButton>
        </Box>
        <TextContainer>
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            color="black"
          >
            {content}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
          <IconButton>
            <SupervisorAccountIcon />
            <IconText>2</IconText>
          </IconButton>
        </TextContainer>
        <ButtonContainer>
          <RedRoundButton>
            <RedIcon />
          </RedRoundButton>
          <GreenRoundButton>
            <GreenIcon />
          </GreenRoundButton>
        </ButtonContainer>
      </CardContentWrapper>
    </InvitacionCard>
  );
};

export default InvitacionBotesCard;

const InvitacionCard = styled(Card)({
  margin: "10px",
  width: "800px",
  "@media (max-width: 768px)": {
    width: "350px",
  },
  borderRadius: "10px",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const CardContentWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
});

const DetailsIconButton = styled(IconButton)({
  color: "#333",
});

const TextContainer = styled(Box)({
  textAlign: "justify",
});

const IconText = styled(Typography)({
  marginLeft: "3px",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100px",
  justifyContent: "space-evenly",
});

const RedRoundButton = styled(IconButton)({
  borderRadius: "50%",
  backgroundColor: "rgba(255, 0, 0, 0.5)",
  "&:hover": {
    backgroundColor: "rgba(255, 0, 0, 0.6)",
  },
  width: "40px",
  height: "40px",
});

const GreenRoundButton = styled(IconButton)({
  borderRadius: "50%",
  backgroundColor: "rgba(0, 128, 0, 0.5)",
  "&:hover": {
    backgroundColor: "rgba(0, 128, 0, 0.6)",
  },
  width: "40px",
  height: "40px",
});

const GreenIcon = styled(CheckIcon)({
  color: green[500],
});

const RedIcon = styled(CloseIcon)({
  color: red[500],
});
