import React, { useState } from "react";
import { Tabs, Tab, AppBar, Button, Box, Typography } from "@mui/material";
import CustomCard from "./CardNotificacion.tsx";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const NotificationTabs: React.FC<{
  newNotifications: { title: string; content: string }[];
  archivedNotifications: { title: string; content: string }[];
}> = ({ newNotifications, archivedNotifications }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleArchive = () => {
    console.log("Notificación archivada");
  };

  return (
    <>
      <HeaderBox>
        <Button onClick={() => navigate("/home")}>
          <ArrowBackOutlinedIcon style={{ color: "#FFFFFF" }} />
        </Button>
        <CenteredBox>
          <HeaderTypography>Notificaciones</HeaderTypography>
        </CenteredBox>
      </HeaderBox>
      <div>
        <AppBar position="static" color="transparent">
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ color: "#FFFFFF" }}
          >
            <StyledTab sx={{ color: "#FFFFFF" }} label="Bandeja de entrada" />
            <StyledTab label="Archivado" />
          </StyledTabs>
        </AppBar>
        {selectedTab === 0 && (
          <NotificationContainer>
            {newNotifications?.map((notification, index) => (
              <CustomCard
                key={index}
                title={notification.title}
                content={notification.content}
                onArchive={handleArchive}
              />
            ))}
          </NotificationContainer>
        )}
        {selectedTab === 1 && (
          <NotificationContainer>
            {archivedNotifications?.map((notification, index) => (
              <CustomCard
                key={index}
                title={notification.title}
                content={notification.content}
                onArchive={handleArchive}
              />
            ))}
          </NotificationContainer>
        )}
      </div>
    </>
  );
};

export default NotificationTabs;

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "60px",
  backgroundColor: "#272541",
});

const CenteredBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#FFFFFF",
});

const StyledTabs = styled(Tabs)({
  "& .MuiTab-root": {
    backgroundColor: "#FFFFFF",
    color: "#272541",
    fontWeight: "bold",
  },
  "& .Mui-selected": {
    backgroundColor: "#272541",
    color: "#FFFFFF",
  },
});

const StyledTab = styled(Tab)({
  "& .Mui-selected": {
    backgroundColor: "#272541",
    color: "#FFFFFF",
  },
});

const NotificationContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  minHeight: "100vh",
  padding: "30px",
});

const ScrollBox = styled(Box)({
  display: "flex",
  width: "100%",
  height: "500px",
  overflowY: "auto",
});
