import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  LinearProgress,
  Divider,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../AxiosConfig"; // Asegúrate de tener configurado axios

const PagoExitoso = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  // Obtener el session_id desde la URL
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `/stripe-session?sessionId=${sessionId}`
        );
        setPaymentDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment details:", error);
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchPaymentDetails();
    }
  }, [sessionId]);

  if (loading) {
    return (
      <Container>
        <LinearProgressStyled />
        <Typography variant="h6">Cargando detalles del pago...</Typography>
      </Container>
    );
  }

  if (!paymentDetails) {
    return (
      <Container>
        <CloseButton onClick={() => navigate("/home")}>
          <CloseIcon />
        </CloseButton>
        <Typography variant="h6">
          No se encontraron detalles de la transacción.
        </Typography>
      </Container>
    ); 
  }
  console.log(paymentDetails)
  const { paymentIntent, session } = paymentDetails;
  console.log(paymentIntent)
  console.log(session)
  const { application_fee_amount, amount } = paymentIntent;
  const { payment_method_types } = session;
  const propina = (amount - application_fee_amount) / 100 || 0; // Propina desde metadatos si existe
  const costosTransaccion = application_fee_amount / 100;
  const totalPagado = amount / 100;

  return (
    <Container>
      <CloseButton onClick={() => navigate("/home")}>
        <CloseIcon />
      </CloseButton>
      <CheckIcon />
      <Title variant="h4">Pago exitoso</Title>
      <Subtitle variant="h6">¡Gracias! Su pago fue completado</Subtitle>
      <DividerStyled />
      <DetailContainer>
        <DetailText variant="h6">Detalles del pago</DetailText>
        <DetailItem>
          <Typography variant="body1">Propina</Typography>
          <Typography variant="body1">€ {propina.toFixed(2)}</Typography>
        </DetailItem>
        <DetailItem>
          <Typography variant="body1">Costes de transacción</Typography>
          <Typography variant="body1">
            € {costosTransaccion.toFixed(2)}
          </Typography>
        </DetailItem>
        <DetailItem>
          <Typography variant="h6" fontWeight="bold">
            Total pagado
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            € {totalPagado.toFixed(2)}
          </Typography>
        </DetailItem>
        <DetailItem>
          <Typography variant="body1">Método de Pago</Typography>
          <Typography variant="body1">
            {payment_method_types.join(", ")}
          </Typography>
        </DetailItem>
      </DetailContainer>
    </Container>
  );
};

export default PagoExitoso;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  height: "100vh",
  justifyContent: "center",
  position: "relative",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "16px",
  right: "16px",
});

const LinearProgressStyled = styled(LinearProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
});

const CheckIcon = styled(CheckCircleIcon)({
  fontSize: "80px",
  color: "green",
  marginBottom: "24px",
});

const Title = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "16px",
});

const Subtitle = styled(Typography)({
  color: "gray",
  marginBottom: "32px",
});

const DividerStyled = styled(Divider)({
  width: "100%",
  margin: "16px 0",
});

const DetailContainer = styled(Box)({
  width: "100%",
  maxWidth: "400px",
});

const DetailText = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "16px",
});

const DetailItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
});
