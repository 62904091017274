import React from "react";
import { Box, Button, Link, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/TipWise.png";
import Footer from "../footer/Footer.tsx";

const LogIn = () => {
  const history = useNavigate();

  const handleContinuar = () => {
    history("/SignIn");
  };

  return (
    <FullHeightContainer>
      <ContentContainer>
        <LogoImage src={logo} alt="Logo" />
        <AnimatedTypography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#272541",
            backgroundColor: "#F7E000",
            padding: "10px",
            borderRadius: "10px",
            animation: "fade-in 2s ease-in-out",
          }}
        >
          Camareras, taxistas, guías turísticos, artistas callejeros… ¡Tipwise
          es tu solución para recibir propinas! <br />
          ¡Únete ahora!
        </AnimatedTypography>
        <Box>
          <StyledButton
            variant="contained"
            size="large"
            onClick={handleContinuar}
          >
            Iniciar sesión
          </StyledButton>
          <Typography
            variant="body1"
            sx={{ marginBottom: "20px", textAlign: "center" }}
          >
            ¿No tienes una cuenta? <Link href="/onBoarding1">Regístrate</Link>
          </Typography>
        </Box>
      </ContentContainer>
      <Footer />
    </FullHeightContainer>
  );
};

export default LogIn;

const FullHeightContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh",
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  flexGrow: 1,
  padding: "20px",
  //minHeight: "600px",
});

const LogoImage = styled("img")({
  width: "200px",
  marginBottom: "20px",
});

const StyledButton = styled(Button)({
  marginBottom: "20px",
  paddingInline: "100px",
  backgroundColor: "#272541",
  borderRadius: "20px",
  "&:hover": {
    backgroundColor: "#272541",
  },
});

const AnimatedTypography = styled(Typography)`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
