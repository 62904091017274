import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Switch,
  Divider,
  TextField,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  ClickAwayListener,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Imagen from "../../assets/TipWise.png";

const Propinas = () => {
  const [selectedTip, setSelectedTip] = useState(null);
  const [customTip, setCustomTip] = useState("");
  const [userData, setUserData] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [botesIndividuales, setBotesIndividuales] = useState([]);
  const [selectedBote, setSelectedBote] = useState("");
  const [includeTransactionCost, setIncludeTransactionCost] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");

  const handleTransactionCostToggle = () => {
    setIncludeTransactionCost(!includeTransactionCost);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${userId}`
        );
        setUserData(response.data);

        // Si el usuario es empresa, obtener los botes individuales
        if (response.data.userType === "empresa") {
          const botesResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/botes/company/${response.data.companies[0]._id}`
          );
          const botesIndividuales = botesResponse.data?.botes.filter(
            (bote) => bote.tipo === "Individual"
          );
          setBotesIndividuales(botesIndividuales);
        }
      } catch (error) {
        console.error("Error fetching user data or botes:", error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  const handleTipClick = (tip) => {
    setSelectedTip(tip);
    setCustomTip("");
  };

  const handleCustomTipChange = (e) => {
    setSelectedTip(null);
    setCustomTip(e.target.value);
  };

  const handleContinue = async () => {
    const tipAmount = selectedTip ? selectedTip : customTip;
    const transactionFeePercentage = 0.05;
    const finalTipAmount = includeTransactionCost
      ? tipAmount + tipAmount * transactionFeePercentage
      : tipAmount;

    if (userData.userType === "empresa") {
      try {
        // Asegurarse de que un bote ha sido seleccionado
        if (selectedBote) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/create-checkout-session`,
            {
              userId: selectedBote,
              amount: Math.round(Number(finalTipAmount) * 100), // amount in cents
              currency: "eur",
            }
          );

          const checkoutUrl = response.data.url;
          window.location.href = checkoutUrl;
        } else {
          const responseTotal = await axios.post(
            `${process.env.REACT_APP_API_URL}/payments/intent`,
            {
              userId,
              boteId: selectedBote, // Agregar el ID del bote seleccionado
              amount: Math.round(Number(finalTipAmount) * 100), // amount in cents
              currency: "eur",
            }
          );
          const checkoutUrlfin = responseTotal.data.url;
          window.location.href = checkoutUrlfin;
        }
      } catch (error) {
        console.error("Error creating enterprise payment session:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-checkout-session`,
          {
            userId,
            amount: Math.round(Number(finalTipAmount) * 100), // amount in cents
            currency: "eur",
          }
        );
        const checkoutUrl = response.data.url;
        window.location.href = checkoutUrl;
      } catch (error) {
        console.error("Error creating checkout session:", error);
      }
    }
  };

  const tips = [3, 5, 10, 15, 20];

  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        "@media (max-width:600px)": {
          padding: 1,
        },
      }}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: 500,
          backgroundColor: "#fefefe",
          "@media (max-width:600px)": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <CardContent>
          <ProfileContainer>
            <LogoContainer>
              <Logo
                src={
                  userData.userType === "empresa"
                    ? userData.companies[0].avatar
                    : userData.avatar
                    ? userData.avatar
                    : Imagen
                }
              />
            </LogoContainer>
            <ContentContainer>
              <ProfileTextContainer>
                <ProfileName>
                  {userData.userType === "empresa"
                    ? userData.companies[0].name
                    : `${userData.firstName} ${userData.lastName}`}
                </ProfileName>
                <SubTitle variant="subtitle2">
                  {userData.userType === "empresa"
                    ? userData.companies[0].segment
                    : userData.segment || "Segmento no disponible"}
                </SubTitle>
              </ProfileTextContainer>
            </ContentContainer>
          </ProfileContainer>

          {userData.userType === "empresa" && botesIndividuales.length > 0 && (
            <>
              <Typography variant="h6" sx={{ marginTop: "20px" }}>
                ¿A quien te gustaria dirigir tu propina?
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel>Personal</InputLabel>
                <Select
                  value={selectedBote}
                  onChange={(e) => {
                    const selectedMemberId = e.target.value;
                    setSelectedBote(selectedMemberId);
                  }}
                  label="Bote"
                >
                  {botesIndividuales.map((bote) =>
                    bote.members.map(
                      (member) =>
                        (member.userId.firstName !== "" ||
                          member.userId.firstName) && (
                          <MenuItem
                            key={member.userId._id}
                            value={member.userId._id}
                          >
                            {member.userId.firstName} {member.userId.lastName}
                          </MenuItem>
                        )
                    )
                  )}
                </Select>
              </FormControl>
            </>
          )}

          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Elegir propina
          </Typography>
          <TipsContainer>
            {tips.map((tip, index) => (
              <Button
                key={index}
                variant={selectedTip === tip ? "contained" : "outlined"}
                onClick={() => handleTipClick(tip)}
                sx={{
                  fontSize: "1rem",
                  marginBottom: "15px",
                  textTransform: "none",
                  color: selectedTip === tip ? "white" : "black",
                  backgroundColor: selectedTip === tip ? "#272541" : "white",
                  border: "1px solid #CCCCCC",
                  borderRadius: "10px",
                  width: "130px",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#F7E000",
                    color: "#272541",
                  },
                }}
              >
                {`€ ${tip}`}
              </Button>
            ))}
            <TextField
              variant="outlined"
              placeholder="Otro monto"
              value={customTip}
              onChange={handleCustomTipChange}
              sx={{ width: "130px", marginBottom: "15px" }}
            />
          </TipsContainer>
          <TransactionCostContainer>
            <StyledSwitch
              checked={includeTransactionCost}
              onChange={handleTransactionCostToggle}
            />
            <Typography>Pagar costes de transacción</Typography>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Se añadirá un 5% para cubrir costes de transacción"
                  placement="top"
                  arrow
                >
                  <IconButton onClick={handleTooltipOpen}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </TransactionCostContainer>
        </CardContent>
      </Card>
      <ContinuarButton variant="contained" onClick={handleContinue}>
        Continuar
      </ContinuarButton>
    </Container>
  );
};

export default Propinas;

const ContinuarButton = styled(Button)({
  width: "100%",
  maxWidth: 500,
  marginTop: "20px",
  height: "60px",
  borderRadius: "10px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#F7E000",
    color: "#272541",
  },
});

const ProfileContainer = styled(Box)({
  margin: "20px 0",
  padding: "10px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#F7E000",
  borderRadius: "15px",
  borderTopLeftRadius: "50px",
  borderBottomLeftRadius: "50px",
});

const Logo = styled("img")({
  width: "90px",
  height: "90px",
  borderRadius: "50%",
  textAlign: "center",
});

const TypographyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "20px",
  marginBottom: "20px",
});

const TipsContainer = styled(Box)({
  width: "100%",
  marginTop: "20px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
});

const TransactionCostContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "20px",
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#272541",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#272541",
  },
}));

const HeaderContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const LogoContainer = styled(Box)({});

const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  justifyContent: "space-between",
});

const SubTitle = styled(Typography)({
  color: "white",
  backgroundColor: "#272541",
  borderRadius: "15px",
  paddingLeft: "10px",
  paddingRight: "10px",
  display: "inline-block",
});
const ProfileTextContainer = styled(Box)({
  marginLeft: "15px",
});

const ProfileName = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#272541",
});
