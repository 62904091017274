import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  ButtonBase,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import Bandera from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../context/OnboardingContext";

const EmpresaDireccionForm = () => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const [formData, setFormData] = useState(onboardingData.address || {});
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  //const isUpdating = !!onboardingData.address; // Detect if we are updating

  useEffect(() => {
    const { line1, postalCode, city } = formData;
    setIsFormValid(!!line1 && !!postalCode && !!city);
  }, [formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedOnboardingData = {
      ...onboardingData,
      address: {
        line1: formData.line1,
        line2: formData.line2,
        postalCode: formData.postalCode,
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
    };
    setOnboardingData(updatedOnboardingData);
    localStorage.setItem(
      "address",
      JSON.stringify(updatedOnboardingData.address)
    );

    navigate("/tycEmpresa");
  };

  const handleFieldChange = (fieldName) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  };

  return (
    <Container>
      <Header>
        <BackButton
          variant="contained"
          onClick={() => navigate("/nombreEmpresa")}
        >
          Atrás
        </BackButton>
        <Image src={Bandera} alt="Bandera de España" />
      </Header>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <Title variant="h4">Agregue su dirección</Title>
          <Subtitle variant="body1">Use la dirección donde reside</Subtitle>
          <StyledTextField
            label="Dirección"
            variant="outlined"
            fullWidth
            value={formData.line1 || ""}
            onChange={handleFieldChange("line1")}
          />
          <StyledTextField
            label="Adicional"
            variant="outlined"
            fullWidth
            value={formData.line2 || ""}
            onChange={handleFieldChange("line2")}
          />
          <StyledTextField
            label="Código Postal"
            variant="outlined"
            fullWidth
            value={formData.postalCode || ""}
            onChange={handleFieldChange("postalCode")}
          />
          <StyledTextField
            label="Ciudad"
            variant="outlined"
            fullWidth
            value={formData.city || ""}
            onChange={handleFieldChange("city")}
          />
          <StyledTextField
            label="Estado"
            variant="outlined"
            fullWidth
            value={formData.state || ""}
            onChange={handleFieldChange("state")}
          />
          <StyledTextField
            label="País"
            variant="outlined"
            fullWidth
            value={formData.country || ""}
            onChange={handleFieldChange("country")}
          />
          <ContinueButton
            type="submit"
            variant="contained"
            disabled={!isFormValid}
          >
            {"Continuar"}
          </ContinueButton>
        </FormContainer>
      </form>
    </Container>
  );
};

export default EmpresaDireccionForm;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 20,
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  alignItems: "center",
  marginBottom: 2,
});

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const Image = styled("img")({
  width: 85,
  height: 50,
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: 300,
  height: 500,
  marginTop: 4,
});

const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.4rem",
  alignSelf: "flex-start",
  marginBottom: 1,
});

const Subtitle = styled(Typography)({
  color: "#272541",
  marginBottom: 5,
  alignSelf: "flex-start",
});

const StyledTextField = styled(TextField)({
  width: 300,
  marginTop: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
  },
});

const ContinueButton = styled(Button)({
  position: "fixed",
  bottom: 40,
  width: "90%",
  borderRadius: "15px",
  height: "50px",
  backgroundColor: "#272541",
  fontWeight: "bold",
});
