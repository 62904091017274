import React, { useState, useEffect } from "react";
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import axios from "../../AxiosConfig";

const AddSchedule: React.FC = () => {
  const history = useNavigate();
  const [timeSlot, setTimeSlot] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [boteId, setBoteId] = useState("");
  const [boats, setBoats] = useState([]);

  useEffect(() => {
    axios.get("/boats")
      .then(response => setBoats(response.data.boats))
      .catch(error => console.error("Error fetching boats:", error));
  }, []);

  const handleTimeSlotChange = (e: React.ChangeEvent<{ value: unknown }>) => setTimeSlot(e.target.value as string);
  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => setStartTime(e.target.value);
  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => setEndTime(e.target.value);
  const handleBoatChange = (e: React.ChangeEvent<{ value: unknown }>) => setBoteId(e.target.value as string);

  const handleCreateSchedule = async () => {
    const newSchedule = { timeSlot, startTime, endTime, boteId };
    try {
      await axios.post("/schedules", newSchedule);
      history("/schedules");
    } catch (error) {
      console.error("Error creating schedule:", error);
    }
  };

  return (
    <>
      <BoxButtonNotificaciones>
        <Button onClick={() => history("/schedules")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <BoxLabelNotificaciones>
          <LabelNotificaciones>Crear Horario</LabelNotificaciones>
        </BoxLabelNotificaciones>
      </BoxButtonNotificaciones>
      <ContainerBox>
        <InnerBox>
          <StyledFormControl>
            <InputLabel>Turno</InputLabel>
            <Select value={timeSlot} onChange={handleTimeSlotChange}>
              <MenuItem value="morning">Mañana</MenuItem>
              <MenuItem value="afternoon">Tarde</MenuItem>
              <MenuItem value="evening">Noche</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledTextField
            label="Hora de inicio"
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }} // 5 min
          />
          <StyledTextField
            label="Hora de fin"
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 300 }} // 5 min
          />
          <StyledFormControl>
            <InputLabel>Bote</InputLabel>
            <Select value={boteId} onChange={handleBoatChange}>
              {boats.map((boat: any) => (
                <MenuItem key={boat._id} value={boat._id}>{boat.nombre}</MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </InnerBox>
        <StyledButton
          variant="contained"
          onClick={handleCreateSchedule}
        >
          Crear
        </StyledButton>
      </ContainerBox>
    </>
  );
};

export default AddSchedule;

const BoxButtonNotificaciones = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  marginBottom: "20px",
});

const BoxLabelNotificaciones = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const LabelNotificaciones = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const ContainerBox = styled(Box)({
  width: "100%",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
});

const InnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  minHeight: "400px",
});

const StyledFormControl = styled(FormControl)({
  marginBottom: "20px",
  width: "300px",
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  width: "300px",
});

const StyledButton = styled(Button)({
  width: "300px",
  backgroundColor: "#5657FF",
});
