import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LogIn from "../src/components/user/login.tsx";
import SignIn from "../src/components/user/SignIn.tsx";
import OnBoardingStep1 from "./components/onBoarding/steps/OnBoardingStep1.tsx";
import OnBoardingStep2 from "./components/onBoarding/steps/OnBoardingStep2.tsx";
import OnBoardingStep3 from "./components/onBoarding/steps/OnBoardingStep3.tsx";
import OnBoardingStep4 from "./components/onBoarding/steps/OnBoardingStep4.tsx";
import ValidarIdentidad from "./components/user/VerificarIdentidad.tsx";
import RegisterForm from "./components/user/FormularioRegistro.tsx";
import DireccionForm from "./components/user/FormularioDireccion.tsx";
import DetalleDireccionForm from "./components/user/FormularioDetalleDireccion.tsx";
import TerminosYCondiciones from "./components/user/TerminosYCondiciones.tsx";
import Home from "./components/navBar/Home.tsx";
import NotificacionesTabs from "./components/notificaciones/Notificaciones.tsx";
import BotesTabs from "./components/botes/Botes.tsx";
import Perfil from "./components/perfilMenu/Perfil.tsx";
import CuentaBancariaDeposito from "./components/perfilMenu/cuentaBancaria/CuentaBancariaDeposito.tsx";
import LenguajeSelector from "./components/perfilMenu/lenguaje/LenguajeSelector.tsx";
import Privacidad from "./components/perfilMenu/privacidad/Privacidad.tsx";
import TerminosYCondicionesPerfil from "./components/perfilMenu/terminosYCondiciones/TerminosYCondiciones.tsx";
import BotesEmpresa from "./components/empresa/botes/BotesEmpresa.tsx";
import CrearBote from "./components/empresa/botes/CrearBote.tsx";
import AgregarMiembros from "./components/empresa/botes/AgregarMiembros.tsx";
import InformacionPersonal from "./components/perfilMenu/informacionPersonal/InformacionPersonal.tsx";
import Documentos from "./components/perfilMenu/documentos/Documentos.tsx";
import Propinas from "./components/pagos/Propinas.tsx";
import PagoExitoso from "./components/pagos/PagoExitoso.tsx";
import PaymentForm from "./components/pagos/FormTarjeta.tsx";
import Transactions from "./components/transacciones/Transacciones.tsx";
import ElejirCalificar from "./components/pagos/ElegirCalificar.tsx";
import Calificar from "./components/pagos/Calificar.tsx";
import ReseñaExitosa from "./components/pagos/ReseñaExitosa.tsx";
import Ganancias from "./components/empresa/ganancias/Ganancias.tsx";
import TransactionDetail from "./components/empresa/ganancias/DetalleTransaccion.tsx";
import InfoUsuario from "./components/user/InfoUsuario.tsx";
import Contacto from "./components/user/Contacto.tsx";
import TipoUsuario from "./components/user/TipoUsuario.tsx";
import ConfigurarRubro from "./components/user/ConfigurarRubro.tsx";
import RubroEmpresa from "./components/user/RubroEmpresa.tsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ProtectedRoute from "./protectedRoutes";
import { AuthProvider } from "./context/authContext";
import CrearEmpresa from "./components/empresa/CrearEmpresa.tsx";
import ScheduleList from "./components/schedules/ScheduleList.tsx";
import AddSchedule from "./components/schedules/AddSchedule.tsx";
import AddBoat from "./components/botes/addBoat.tsx";
import ProfileComponent from "./components/perfilMenu/EditPerfil.tsx";
import EmpresaDireccionForm from "./components/empresa/DireccionEmpresa.tsx";
import TerminosYCondicionesEmpresa from "./components/empresa/TyCEmpresa.tsx";
import InfoEmpresa from "./components/empresa/InfoEmpresa.tsx";
import BoteDetalle from "./components/botes/DetalleBote.tsx";

import ProfileComponentEmpresa from "./components/perfilMenu/EditPerfilEmpresa.tsx";
import RubroUsuario from "./components/user/RubroUsuario.tsx";
import RetirarFondos from "./components/transacciones/RetirarFondos.tsx";
import RetiroExitoso from "./components/transacciones/RetiroExitoso.tsx";
import InformacionEmpresa from "./components/perfilMenu/informacionEmpresa/informacionEmpresa.tsx";
import DetalleDireccionFormEdit from "./components/user/FormularioDetalleDireccionEdit.tsx";
import RubroUsuarioEdit from "./components/user/RubroUsuarioEdit.tsx";

const cardsData = [
  { title: "Otra vuelta", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Puzzels", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Blackness", content: "Thu 2 Nov, 2023 6:24PM" },
];

const notifications = [
  { title: "Vidon Bar", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Hilton", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Taberna Moe", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Happy feet", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Duchshound", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Master Doctor", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Glitters", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Gintoneria", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Capitan XL", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Maori", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Iguazu falls", content: "Thu 2 Nov, 2023 6:24PM" },
  { title: "Jericocuara", content: "Thu 2 Nov, 2023 6:24PM" },
];
const invitationBotes = [
  { title: "Vidon Bar", content: "Turno Mañana" },
  { title: "Hilton", content: "Turno Tarde" },
  { title: "Taberna Moe", content: "Turno Noche" },
  { title: "Happy feet", content: "Turno Mañana" },
  { title: "Duchshound", content: "Turno Noche" },
  { title: "Master Doctor", content: "Turno Mañana" },
];

const stripePromise = loadStripe(
  "pk_test_51PNyWbHDk7MaXaiik0ojnmhAA9qBe6Ax8IUg2G1t00pq4wZ6c5iFCm4QzqXFjAQLNoWD7AmU2bAD2WpHtRAY008b00y867pONQ"
);

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route
            path="/onBoarding1"
            allowedRoles={["user"]}
            element={<OnBoardingStep1 />}
          />
          <Route path="/OnBoarding2" element={<OnBoardingStep2 />} />
          <Route path="/Onboarding3" element={<OnBoardingStep3 />} />
          <Route path="/Onboarding4" element={<OnBoardingStep4 />} />

          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/validarIdentidad" element={<ValidarIdentidad />} />

          <Route element={<ProtectedRoute allowedRoles={["user", "admin"]} />}>
            <Route path="/formularioRegistro" element={<RegisterForm />} />
            <Route
              path="/formularioDireccion"
              element={<DetalleDireccionForm />}
            />
            <Route
              path="/formularioDireccionEdicion"
              element={<DetalleDireccionFormEdit />}
            />
            <Route
              path="/terminosCondiciones"
              element={<TerminosYCondiciones />}
            />
            <Route path="/infoUsuario" element={<InfoUsuario />} />
            <Route path="/configuracionNegocio" element={<ConfigurarRubro />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/tipoEmpresa" element={<TipoUsuario />} />
            <Route path="/seleccionarRubro" element={<RubroEmpresa />} />
            <Route path="/seleccionarRubroUsuario" element={<RubroUsuario />} />
            <Route path="/seleccionarRubroUsuarioEdit" element={<RubroUsuarioEdit />} />
            <Route path="/nombreEmpresa" element={<ConfigurarRubro />} />
            <Route
              path="/direccionEmpresa"
              element={<EmpresaDireccionForm />}
            />
            <Route
              path="/tycEmpresa"
              element={<TerminosYCondicionesEmpresa />}
            />
            <Route path="/infoEmpresa" element={<InfoEmpresa />} />
            <Route path="/home" element={<Home />} />
            <Route path="/retirarFondos" element={<RetirarFondos />} />
            <Route path="/retiroExitoso" element={<RetiroExitoso />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route
              path="/informacionPersonal"
              element={<InformacionPersonal />}
            />
            <Route
              path="/InformacionEmpresa"
              element={<InformacionEmpresa />}
            />
            <Route path="/editPerfil" element={<ProfileComponent />} />
            <Route
              path="/editPerfilEmpresa"
              element={<ProfileComponentEmpresa />}
            />
            <Route path="/documentos" element={<Documentos />} />
            <Route
              path="/cuentaBancoDeposito"
              element={<CuentaBancariaDeposito />}
            />
            <Route path="/lenguajeSelector" element={<LenguajeSelector />} />
            <Route path="/privacidad" element={<Privacidad />} />
            <Route path="/tycPerfil" element={<TerminosYCondicionesPerfil />} />
            <Route path="/transacciones" element={<Transactions />} />
            <Route
              path="/notificaciones"
              element={
                <NotificacionesTabs
                  newNotifications={cardsData}
                  archivedNotifications={notifications}
                />
              }
            />
            <Route
              path="/botes"
              element={
                <BotesTabs
                  newBotes={invitationBotes}
                  invitationBotes={invitationBotes}
                />
              }
            />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
            <Route path="/botesEmpresa" element={<BotesEmpresa />} />
            <Route path="/ganancias" element={<Ganancias />} />
            <Route path="/detalleTransction" element={<TransactionDetail />} />
            <Route path="/crearBote" element={<CrearBote />} />
            <Route path="/agregarMiembros" element={<AgregarMiembros />} />
            <Route path="/crear-empresa" element={<CrearEmpresa />} />
            <Route path="/addBoat" element={<AddBoat />} />
            <Route path="/schedules" element={<ScheduleList />} />
            <Route path="/bote/:boteId" element={<BoteDetalle />} />
            <Route path="/addSchedule" element={<AddSchedule />} />
          </Route>

          <Route
            path="/pagos/propinas"
            element={
              <Elements stripe={stripePromise}>
                <Propinas />
              </Elements>
            }
          />
          <Route
            path="/pagos/formularioPago"
            element={
              <Elements stripe={stripePromise}>
                <PaymentForm />
              </Elements>
            }
          />
          <Route
            path="/pagos/pagoExitoso"
            element={
              <Elements stripe={stripePromise}>
                <PagoExitoso />
              </Elements>
            }
          />
          <Route
            path="/elejirCalificar"
            element={
              <Elements stripe={stripePromise}>
                <ElejirCalificar />
              </Elements>
            }
          />
          <Route
            path="/calificar"
            element={
              <Elements stripe={stripePromise}>
                <Calificar />
              </Elements>
            }
          />
          <Route
            path="/reseñaExitosa"
            element={
              <Elements stripe={stripePromise}>
                <ReseñaExitosa />
              </Elements>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
