import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const filtro = ["Hoy", "Semana", "Mes", "Todas"];

const GananciasDetail = ({ tipCount, totalTipsAmount, setSelectedFilter }) => {
  return (
    <DetailContainer>
      <HeaderContainer>
        <TitleText variant="h6">Total de propinas </TitleText>
        <EuroButton
          variant="contained"
          startIcon={<EuroIcon sx={{ color: "#fefe" }} />}
        >
          EUR
        </EuroButton>
      </HeaderContainer>
      <ContentContainer>
        <BalanceText variant="h4">{`€ ${totalTipsAmount}`}</BalanceText>
        <Typography>{`Propinas: ${tipCount}`}</Typography>
      </ContentContainer>
      <DividerStyled />
      <FooterContainer>
        {filtro.map((item, index) => (
          <FilterButton key={index} onClick={() => setSelectedFilter(item)}>
            {item}
          </FilterButton>
        ))}
      </FooterContainer>
    </DetailContainer>
  );
};

export default GananciasDetail;

const DetailContainer = styled(Box)(({ theme }) => ({
  width: "80vw",
  height: "240px",
  borderRadius: "15px",
  padding: "20px",
  margin: "10px auto",
  marginTop: "50px",
  backgroundColor: "#F7E000",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
  [theme.breakpoints.up("sm")]: {
    width: "70vw",
    height: "250px",
  },
  [theme.breakpoints.up("md")]: {
    width: "60vw",
    height: "250px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "50vw",
    height: "250px",
  },
}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
});

const TitleText = styled(Typography)({
  color: "#272541",
  fontWeight: "bold",
});

const EuroButton = styled(Button)({
  backgroundColor: "#272541",
  color: "#fff",
  borderRadius: "20px",
  padding: "5px 15px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6f6aff",
  },
});

const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const BalanceText = styled(Typography)({
  color: "#272541",
  fontWeight: "bold",
});

const DividerStyled = styled(Divider)({
  width: "100%",
  margin: "20px 0",
  backgroundColor: "#272541",
});

const FooterContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const FilterButton = styled(Button)({
  border: "1px solid #272541",
  borderRadius: "25px",
  textTransform: "none",
  color: "#272541",
  padding: "5px 15px",
  "&:hover": {
    backgroundColor: "#6f6aff",
    color: "#fff",
  },
});

const EuroIcon = styled("svg")({
  width: "20px",
  height: "20px",
});

// import React from "react";
// import { Box, Typography, Button, Divider } from "@mui/material";
// import { styled } from "@mui/material/styles";

// const filtro = ["Hoy", "Semana", "Mes", "Todas"];

// const GananciasDetail = ({
//   tipCount,
//   totalTipsAmount,
//   //selectedFilter,
//   setSelectedFilter,
// }) => {
//   return (
//     <DetailContainer>
//       <HeaderContainer>
//         <Typography variant="body1" fontSize="bold">
//           Ganancias
//         </Typography>
//         <EurContainer>
//           <Typography>EUR</Typography>
//         </EurContainer>
//       </HeaderContainer>
//       <ContentContainer>
//         <Typography
//           variant="h4"
//           fontWeight="normal"
//         >{`€ ${totalTipsAmount}`}</Typography>
//         <Typography>{`Propinas: ${tipCount}`}</Typography>
//       </ContentContainer>
//       <Divider sx={{ marginTop: "20px" }} />
//       <FooterContainer>
//         {filtro.map((item, index) => (
//           <Button
//             key={index}
//             sx={{
//               width: "80px",
//               border: "0.5px solid #cdced7",
//               borderRadius: "25px",
//               textTransform: "none",
//               color: "#6b697a",
//             }}
//             onClick={() => setSelectedFilter(item)}
//           >
//             {item}
//           </Button>
//         ))}
//       </FooterContainer>
//     </DetailContainer>
//   );
// };

// export default GananciasDetail;

// const DetailContainer = styled(Box)(({ theme }) => ({
//   width: "80vw",
//   height: "240px",
//   borderRadius: "10px",
//   padding: "20px",
//   margin: "10px",
//   boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
//   marginTop: "70px",
//   backgroundColor: "white",
//   [theme.breakpoints.up("sm")]: {
//     width: "70vw",
//     height: "250px",
//     //top: "150px",
//     left: "98px",
//   },
//   [theme.breakpoints.up("md")]: {
//     width: "80vw",
//     height: "250px",
//     //top: "170px",
//     left: "70px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     width: "80vw",
//     //top: "200px",
//     left: "100px",
//   },
// }));

// const HeaderContainer = styled(Box)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "15px",

//   [theme.breakpoints.up("sm")]: {
//     marginTop: "0px",
//     justifyContent: "space-around",
//   },

//   [theme.breakpoints.up("md")]: {
//     justifyContent: "space-around",
//   },
// }));

// const ContentContainer = styled(Box)({
//   width: "100%",
//   display: "flex",
//   flexDirection: "column",
//   gap: "10px",
//   marginTop: "10px",
// });

// const FooterContainer = styled(Box)({
//   width: "100%",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   marginTop: "20px",
// });

// const EurContainer = styled(Box)({
//   width: "50px",
//   margin: "5px",
//   padding: "5px",
//   backgroundColor: "#ccc5f9",
//   borderRadius: "25px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// });
