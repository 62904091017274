import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  IconButton,
  Card,
  Drawer,
  Divider,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import EuroIcon from "@mui/icons-material/Euro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "../../AxiosConfig";

const RetirarFondos = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isConfirmDrawerOpen, setIsConfirmDrawerOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [showBalance, setShowBalance] = useState(true);
  const [balance, setBalance] = useState(0);
  const [method, setMethod] = useState("");
  const [bankName, setBankName] = useState("");
  const [newIban, setNewIban] = useState("");
  const [newBankName, setNewBankName] = useState("");
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(`/stripe-balance?userId=${userData._id}`);
        setBalance(response.data.available[0].amount / 100);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    const fetchBankDetails = async () => {
      try {
        const response = await axios.get(`/user-bank-details?userId=${userData._id}`);
        if (response.data) {
          setMethod(response.data.iban);
          setBankName(response.data.bankName);
        }
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    };

    fetchBalance();
    fetchBankDetails();
  }, [userData._id]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleConfirmDrawerOpen = () => {
    setIsConfirmDrawerOpen(true);
  };

  const handleConfirmDrawerClose = () => {
    setIsConfirmDrawerOpen(false);
  };

  const toggleVisibility = () => {
    setShowBalance(!showBalance);
  };

  /* const handleSave = async () => {
    try {
      await axios.post(`/user-bank-details`, {
        userId: userData._id,
        iban: newIban,
        bankName: newBankName,
      });
      setMethod(newIban);
      setBankName(newBankName);
      handleDrawerClose();
    } catch (error) {
      console.error("Error saving bank details:", error);
    }
  }; */

  const handleWithdraw = async () => {
    try {
      await axios.post(`/withdraw-funds`, {
        userId: userData._id,
        amount: Number(amount) * 100, // Convert to cents
      });
      setBalance(balance - Number(amount));
      navigate("/retiroExitoso");
    } catch (error) {
      console.error("Error withdrawing funds:", error);
    }
  };

  return (
    <>
      <Header>
        <Button onClick={() => navigate("/home")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <TitleContainer>
          <TitleText>Retirar fondos</TitleText>
        </TitleContainer>
      </Header>
      <MainContainer>
        <BalanceBox>
          <Title>
            <Typography variant="subtitle1">Listo para retirar</Typography>
            <EuroButton
              variant="contained"
              sx={{ backgroundColor: "#272541" }}
              startIcon={<EuroIcon />}
            >
              EUR
            </EuroButton>
          </Title>
          <Balance>
            <Typography variant="h5">
              <EuroIcon />
              {showBalance ? balance.toFixed(2) : "*****"}
            </Typography>
            <IconButton onClick={toggleVisibility}>
              {showBalance ? (
                <VisibilityIconStyled />
              ) : (
                <VisibilityOffIconStyled />
              )}
            </IconButton>
          </Balance>
        </BalanceBox>
        <Box p={2} width="100%">
          <StyledTextField
            fullWidth
            label="Cantidad"
            placeholder=" 0"
            margin="normal"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: <EuroIcon />,
            }}
          />
          <Typography
            variant="body2"
            sx={{ marginBottom: 2 }}
            color="textSecondary"
          >
            El cantidad mínimo de retiro es €10
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, fontWeight: "bold" }}
          >
            Retirar a {bankName}
          </Typography>
         {/*  <MethodButton variant="outlined" onClick={handleDrawerOpen}>
            {method ? (
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Box display="flex" alignItems="center" p={2}>
                  <AccountBalanceIcon sx={{ mr: 2 }} />
                  <Box display="flex" flexDirection="column" textAlign="left">
                    <Typography variant="body2" fontWeight="bold">
                      IBAN: {method}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {bankName}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ) : (
              "Agregar método"
            )}
          </MethodButton> */}
        </Box>
      </MainContainer>
      <FixedFooter>
        <WithdrawButton variant="contained" onClick={handleConfirmDrawerOpen}>
          Retirar fondos
        </WithdrawButton>
      </FixedFooter>
      {/* <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose}>
        <DrawerContent>
           <Header>
            <Button onClick={handleDrawerClose}>
              <ArrowBackOutlinedIcon />
            </Button>
            <TitleContainer>
              <TitleText>Agregar nuevo método de retiro</TitleText>
            </TitleContainer>
          </Header> 
          <Box p={2}>
            <TextField
              fullWidth
              label="IBAN"
              placeholder="XXXXXXXXXXXXXXXXXXXXXX"
              margin="normal"
              value={newIban}
              onChange={(e) => setNewIban(e.target.value)}
            />
            <Typography variant="body2" sx={{ marginBottom: 2 }}>
              Por favor ingrese aquí su IBAN que puede encontrar en su resumen
              bancario o en su sistema de e-banking
            </Typography>
            <TextField
              fullWidth
              label="Nombre del banco"
              placeholder="Nombre del banco"
              margin="normal"
              value={newBankName}
              onChange={(e) => setNewBankName(e.target.value)}
            />
            <SaveButton variant="contained" onClick={handleSave}>
              Guardar
            </SaveButton>
          </Box>
        </DrawerContent>
      </Drawer> */}
      <Drawer
        anchor="bottom"
        open={isConfirmDrawerOpen}
        onClose={handleConfirmDrawerClose}
      >
        <DrawerContent>
          <Header>
            <Button onClick={handleConfirmDrawerClose}>
              <ArrowBackOutlinedIcon />
            </Button>
            <TitleContainer>
              <TitleText>Confirmación de retiro</TitleText>
            </TitleContainer>
          </Header>
          <Box p={2}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Cantidad de retiro
            </Typography>
            <Card variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Cantidad de retiro</Typography>
                <Typography variant="body2">€{amount}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Costos del proveedor</Typography>
                <Typography variant="body2">- €0.00</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" fontWeight="bold">
                  Obtenerás
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  €{amount}
                </Typography>
              </Box>
            </Card>
            <Box mb={2}>
              <Typography variant="body1" fontWeight="bold">
                Retirar a
              </Typography>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <Box display="flex" alignItems="center" p={2}>
                  <AccountBalanceIcon sx={{ mr: 2 }} />
                  <Box display="flex" flexDirection="column" textAlign="left">
                    <Typography variant="body2" fontWeight="bold">
                      IBAN: {method}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {bankName}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
            <SaveButton
              variant="contained"
              onClick={handleWithdraw}
            >
              Confirmar
            </SaveButton>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RetirarFondos;

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 16px",
  "@media (max-width: 768px)": {
    flexDirection: "row",
    height: "auto",
    padding: "10px 0",
  },
});

const TitleContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const TitleText = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  "@media (max-width: 768px)": {
    fontSize: "18px",
  },
});

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: "20px",
  marginTop: "20px",
  "@media (max-width: 768px)": {
    marginTop: "20px",
  },
});

const BalanceBox = styled(Card)({
  width: "90%",
  marginBottom: "20px",
  backgroundColor: "#F7E000",
  padding: "15px",
  textAlign: "center",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  border: "1px solid #E0E0E0",
});

const Title = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
});

const EuroButton = styled(Button)({
  minWidth: "50px",
  borderRadius: "30px",
  backgroundColor: "#272541",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0041A3",
  },
});

const Balance = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const VisibilityIconStyled = styled(VisibilityIcon)({
  marginLeft: "10px",
});

const VisibilityOffIconStyled = styled(VisibilityOffIcon)({
  marginLeft: "10px",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputAdornment-root": {
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#272541",
    },
    "&:hover fieldset": {
      borderColor: "#272541",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#272541",
    },
  },
});

const MethodButton = styled(Button)({
  width: "100%",
  padding: "10px",
  marginBottom: "20px",
  border: "1px solid #ccc",
  color: "#272541",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const FixedFooter = styled(Box)({
  position: "fixed",
  bottom: 0,
  width: "100%",
  marginBottom: "10px",
  backgroundColor: "#fff",
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const WithdrawButton = styled(Button)({
  width: "90%",
  backgroundColor: "#272541",
  padding: "15px",
  fontSize: "16px",
  fontWeight: "bold",
  "@media (max-width: 768px)": {
    width: "90%",
  },
});

const DrawerContent = styled(Box)({
  padding: "20px",
});

const SaveButton = styled(Button)({
  marginTop: "20px",
  width: "100%",
  backgroundColor: "#272541",
  "&:hover": {
    backgroundColor: "#2a256a",
  },
});
