import React, { createContext, useState, useEffect } from "react";

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
    const [onboardingData, setOnboardingData] = useState({
        country: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        dni: "",
        gender: "",
        address: {
            line1: "",
            line2: "",
            postalCode: "",
            city: "",
            country: "",
        },
        username: "",
        description: "",
        phoneNumber: "",
        bankIban: "",
        bankReference: "",
        terminosAceptados: false,
        datosPersonalesAceptados: false,
        completedOnboarding: false,
    });

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            setOnboardingData((prevData) => ({
                ...prevData,
                ...userData,
            }));
        } else {
            setOnboardingData({
                country: "",
                firstName: "",
                lastName: "",
                birthDate: "",
                dni: "",
                gender: "",
                address: {
                    line1: "",
                    line2: "",
                    postalCode: "",
                    city: "",
                    country: "",
                },
                username: "",
                description: "",
                phoneNumber: "",
                bankIban: "",
                bankReference: "",
                terminosAceptados: false,
                datosPersonalesAceptados: false,
                completedOnboarding: false,
            });
        }
    }, []);

    return (
        <OnboardingContext.Provider value={{ onboardingData, setOnboardingData }}>
            {children}
        </OnboardingContext.Provider>
    );
};
