import React, { useState } from "react";
import { Button, Typography, TextField, Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../AxiosConfig";
import logo from "../../assets/TipWise.png";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleContinuar = async () => {
    try {
      await axios.post("/auth/generate-access-code", { email });
      navigate("/validarIdentidad", { state: { email } });
    } catch (error) {
      setError("Error al enviar el código. Por favor, inténtelo de nuevo.");
    }
  };

  return (
    <CenteredContainer>
      <LogoImage src={logo} alt="Logo" />
      <Typography variant="h4" sx={{ color: "#272541", marginBottom: "10px" }}>
        Crea tu cuenta
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{ color: "#272541", marginBottom: "20px" }}
      >
        O ingresa a tu cuenta de Tipwise para continuar:
      </Typography>
      <StyledTextField
        sx={{ width: 300, marginBottom: "20px" }}
        label="Correo electrónico"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && (
        <Typography textAlign="center" color="error">
          {error}
        </Typography>
      )}
      <StyledButton variant="contained" size="large" onClick={handleContinuar}>
        Continuar
      </StyledButton>
    </CenteredContainer>
  );
};

export default SignIn;

const CenteredContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  paddingBottom: "50px",
  paddingInline: "20px",
});

const LogoImage = styled("img")({
  width: "200px",
  marginBottom: "20px",
});

const StyledButton = styled(Button)({
  width: "300px",
  marginTop: "20px",
  backgroundColor: "#272541",
  borderRadius: "15px",
  "&:hover": {
    backgroundColor: "#272541",
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
  },
});
