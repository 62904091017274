import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "../../AxiosConfig";

const AddBoat: React.FC = () => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [percentage, setPercentage] = useState(100);
  const [isFormValid, setIsFormValid] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const history = useNavigate();

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;

  useEffect(() => {
    validateForm();
    fetchSchedules();
  }, [name, type, percentage, selectedSchedules]);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('/schedules');
      setSchedules(response.data.schedules);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => setType(e.target.value as string);
  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => setPercentage(parseFloat(e.target.value));
  const handleScheduleChange = (event) => {
    const { target: { value } } = event;
    setSelectedSchedules(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const validateForm = () => setIsFormValid(
    name !== "" &&
    type !== "" &&
    (type !== "Manual" || (percentage > 0 && percentage <= 100)) &&
    selectedSchedules.length > 0
  );

  const handleSubmit = async () => {
    const newBoat = { name, type, percentage, companyId: user.companyId, scheduleIds: selectedSchedules };
    try {
      await axios.post("/boats", newBoat);
      history("/boats");
    } catch (error) {
      console.error("Error creating boat:", error);
    }
  };

  return (
    <StyledContainer>
      <StyledTitle variant="h4" gutterBottom>Crear Bote</StyledTitle>
      <StyledFormControl fullWidth margin="normal">
        <TextField label="Nombre" value={name} onChange={handleNameChange} />
      </StyledFormControl>
      <StyledFormControl fullWidth margin="normal">
        <InputLabel>Tipo</InputLabel>
        <Select value={type} onChange={handleTypeChange}>
          <MenuItem value="Manual">Manual</MenuItem>
          <MenuItem value="Igualitario">Igualitario</MenuItem>
          <MenuItem value="Individual">Individual</MenuItem>
        </Select>
      </StyledFormControl>
      {type == "Manual" && (
        <StyledFormControl fullWidth margin="normal">
          <TextField label="Porcentaje" type="number" value={percentage} onChange={handlePercentageChange} />
        </StyledFormControl>
      )}
      <StyledFormControl fullWidth margin="normal">
        <InputLabel>Horarios</InputLabel>
        <Select
          multiple
          value={selectedSchedules}
          onChange={handleScheduleChange}
          renderValue={(selected) => selected.join(', ')}
        >
          {schedules.map((schedule) => (
            <MenuItem key={schedule._id} value={schedule._id}>
              <Checkbox checked={selectedSchedules.indexOf(schedule._id) > -1} />
              <ListItemText primary={`${schedule.timeSlot} (${schedule.startTime} - ${schedule.endTime})`} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledButton variant="contained" color="primary" onClick={handleSubmit} disabled={!isFormValid}>Crear</StyledButton>
    </StyledContainer>
  );
};

export default AddBoat;

const StyledContainer = styled(Box)({
  backgroundColor: "#FEFEFE",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)"
});

const StyledTitle = styled(Typography)({
  color: "#272541",
  fontWeight: "bold"
});

const StyledFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    color: "#272541",
  },
  '& .MuiInputBase-root': {
    color: "#272541",
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "#272541",
    },
  }
});

const StyledButton = styled(Button)({
  backgroundColor: "#F7E000",
  color: "#272541",
  '&:hover': {
    backgroundColor: "#E6C200",
  },
  '&:disabled': {
    backgroundColor: "#F7E00099",
    color: "#27254199",
  }
});
