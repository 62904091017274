import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import AppBarMenu from "../navBar/NavBarMenu.tsx";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { styled } from "@mui/material/styles";
import axios from "../../AxiosConfig";

const BoteDetalle = () => {
  const { boteId } = useParams();
  const [bote, setBote] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchBoteDetails = async () => {
      try {
        const response = await axios.get(`/botes/${boteId}`);
        setBote(response.data.bote);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bote details:", error);
        setLoading(false);
      }
    };

    fetchBoteDetails();
  }, [boteId]);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleAddMembers = () => {
    history(`/agregarMiembros?boteId=${boteId}`);
  };

  if (loading) {
    return (
      <CenteredContainer>
        <CircularProgress />
      </CenteredContainer>
    );
  }

  if (!bote) {
    return (
      <CenteredContainer>
        <Typography variant="h6">Bote no encontrado</Typography>
      </CenteredContainer>
    );
  }

  return (
    <>
      <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <BoxButtonNotificaciones>
        <Button onClick={() => history("/botes")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <BoxLabelNotificaciones>
          <LabelNotificaciones>Botes</LabelNotificaciones>
        </BoxLabelNotificaciones>
      </BoxButtonNotificaciones>
      <Container>
        <StyledPaper elevation={3}>
          <Header>
            <Typography variant="h4" gutterBottom>
              Bote: {bote?.nombre}
            </Typography>
          </Header>
          <Content>
            <Typography variant="h6" gutterBottom>
              Empresa: {bote?.companyId?.name}
            </Typography>
            <Divider />
            {bote.parentBote && (
              <>
                <Typography variant="h6" gutterBottom>
                  Bote Padre: {bote.parentBote.nombre}
                </Typography>
                <Divider />
              </>
            )}
            <Typography variant="h6" gutterBottom>
              Tipo: {bote.tipo}
            </Typography>
            <Divider />
            {bote.tipo === "Manual" && (
              <>
                <Typography variant="h6" gutterBottom>
                  Porcentaje Total: {bote.percentage}%
                </Typography>
                <Divider />
              </>
            )}
            <Typography variant="h6" gutterBottom>
              Usuarios:
            </Typography>
            <List>
              {bote.members.map((member) => (
                <Box key={member._id} mb={2}>
                  <List>
                    <React.Fragment key={member._id}>
                      <ListItem>
                        <ListItemText
                          primary={member.userId?.email}
                          secondary={`Nombre: ${
                            member.userId?.firstName || ""
                          } ${member.userId?.lastName || ""}, Rol: ${
                            member.userId?.role
                          }${
                            bote.tipo === "Manual"
                              ? `, Porcentaje: ${member.percentage}%`
                              : ""
                          }`}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  </List>
                </Box>
              ))}
            </List>
            <CenteredBox>
              <AddMembersButton variant="contained" onClick={handleAddMembers}>
                Agregar Miembros
              </AddMembersButton>
            </CenteredBox>
          </Content>
        </StyledPaper>
      </Container>
    </>
  );
};

export default BoteDetalle;

const BoxButtonNotificaciones = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "10px",
  boxSizing: "border-box",
});

const BoxLabelNotificaciones = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const LabelNotificaciones = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const Container = styled(Box)({
  padding: "20px",
  backgroundColor: "#F0F0F0",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  overflow: "hidden",
  width: "100vw",
});

const StyledPaper = styled(Paper)({
  padding: "20px",
  borderRadius: "10px",
  maxWidth: "600px",
  width: "100%",
  boxSizing: "border-box",
});

const CenteredContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: "#F0F0F0",
  boxSizing: "border-box",
  width: "100%",
  overflow: "hidden",
});

const Header = styled(Box)({
  backgroundColor: "#272541",
  padding: "10px",
  borderRadius: "10px",
  marginBottom: "20px",
  color: "#FFFFFF",
  textAlign: "center",
  boxSizing: "border-box",
  width: "100%",
});

const Content = styled(Box)({
  padding: "20px",
  boxSizing: "border-box",
});

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  boxSizing: "border-box",
});

const AddMembersButton = styled(Button)({
  marginTop: "20px",
  width: "90%",
  backgroundColor: "#272541",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#4343D9",
  },
  boxSizing: "border-box",
});
