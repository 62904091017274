import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Typography,
  Box,
  Button,
  Drawer,
  Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EuroIcon from "@mui/icons-material/Euro";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LinkIcon from "@mui/icons-material/Link";
import QRCodeIcon from "@mui/icons-material/QrCode";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import axios from "../../AxiosConfig";

const BalanceComponent = (totalBalance, totalAvailable) => {
  const [showBalance, setShowBalance] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [balance, setBalance] = useState(null);
  const [paymentLink, setPaymentLink] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  const toggleVisibility = () => {
    setShowBalance(!showBalance);
  };

  const generateQRCode = async () => {
    try {
      const response = await axios.post("/qr-code", {
        userId: userData._id,
      });
      setQrCode(response.data.qrCodeDataURL);
      setDrawerOpen(true);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const toggleShareDrawer = (open) => () => {
    setShareDrawerOpen(open);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(paymentLink).then(() => {
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    });
  };

  const generatePaymentLink = () => {
    setPaymentLink(
      `https://open.tipwise.ai/pagos/propinas?userId=${userData._id}`
    );
    setShareDrawerOpen(true);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          `/stripe-balance?userId=${userData._id}`
        );
        setBalance(response.data);
        console.log("balance", balance);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalance();
  }, [userData._id]);

  console.log("totalBalance", totalBalance);
  console.log("totalAvailable", totalAvailable);
  return (
    <ContentContainer>
      <BalanceBox>
        <Title>
          <TitleText variant="h6" color="#272541" sx={{ color: "#272541" }}>
            Balance
          </TitleText>

          <EuroButton
            variant="contained"
            color="primary"
            startIcon={<EuroIcon />}
          >
            EUR
          </EuroButton>
        </Title>
        <Balance>
          <BalanceText variant="h5" sx={{ color: "#272541" }}>
            <EuroIcon />
            {balance && showBalance ? totalBalance.totalAvailable : "*****"}
          </BalanceText>
          <AvailableText variant="body2" sx={{ color: "#272541" }}>
            Pendiente de ingresar
          </AvailableText>
          <IconButton onClick={toggleVisibility}>
            {showBalance ? (
              <VisibilityIconStyled sx={{ color: "#272541" }} />
            ) : (
              <VisibilityOffIconStyled sx={{ color: "#272541" }} />
            )}
          </IconButton>
        </Balance>
        <Balance>
          <BalanceText variant="h6" sx={{ color: "#272541" }}>
            <EuroIcon sx={{ width: 18 }} />
            {balance && showBalance ? 0 : "*****"}
          </BalanceText>

          <AvailableText variant="body2" sx={{ color: "#272541" }}>
            Disponible para retirar
          </AvailableText>
          <IconButton onClick={toggleVisibility}>
            {showBalance ? (
              <VisibilityIconStyled sx={{ color: "#272541" }} />
            ) : (
              <VisibilityOffIconStyled sx={{ color: "#272541" }} />
            )}
          </IconButton>
        </Balance>

        <ButtonsContainer>
          <ActionBox>
            <ActionButton
              variant="contained"
              onClick={() => navigate("/retirarFondos")}
            >
              <EuroIcon style={{ width: "50px", height: "50px" }} />
            </ActionButton>
            <ActionText sx={{ color: "#272541" }}>Retirar fondos</ActionText>
          </ActionBox>
          <ActionBox>
            <ActionButton variant="contained" onClick={generatePaymentLink}>
              <LinkIcon sx={{ width: "50px", height: "50px" }} />
            </ActionButton>
            <ActionText sx={{ color: "#272541" }}>Compartir enlace</ActionText>
          </ActionBox>
          <ActionBox>
            <ActionButton variant="contained" onClick={generateQRCode}>
              <QRCodeIcon sx={{ width: "50px", height: "50px" }} />
            </ActionButton>
            <ActionText sx={{ color: "#272541" }}>Ver QR</ActionText>
          </ActionBox>
        </ButtonsContainer>
      </BalanceBox>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          },
        }}
      >
        <DrawerContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton onClick={toggleDrawer(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
              Código QR
            </Typography>
            <Box sx={{ width: 48 }} />
          </Box>
          {qrCode && (
            <Box>
              <img src={qrCode} alt="QR Code" />
            </Box>
          )}
          <Box>
            <Typography variant="h6">
              {userData.userType && userData.userType === "empresa"
                ? userData.companies[0].name
                : `${userData.firstName} ${userData.lastName}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {userData.userType && userData.userType === "empresa"
                ? `@${userData.companies[0].userName}`
                : `@${userData.username}`}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const link = document.createElement("a");
              link.href = qrCode;
              link.download = "QR_Code.png";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            Descargar QR
          </Button>
        </DrawerContent>
      </Drawer>

      <Drawer
        anchor="bottom"
        open={shareDrawerOpen}
        onClose={toggleShareDrawer(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          },
        }}
      >
        <DrawerContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton onClick={toggleShareDrawer(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">Compartir enlace</Typography>
            <div></div>{" "}
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              startIcon={<FileCopyIcon />}
              onClick={handleCopyLink}
            >
              Copiar link
            </Button>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <IconButton
                onClick={() =>
                  window.open(
                    `https://facebook.com/sharer/sharer.php?u=${paymentLink}`,
                    "_blank"
                  )
                }
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?url=${paymentLink}`,
                    "_blank"
                  )
                }
              >
                <TwitterIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(`https://wa.me/?text=${paymentLink}`, "_blank")
                }
              >
                <WhatsAppIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(
                    `https://t.me/share/url?url=${paymentLink}`,
                    "_blank"
                  )
                }
              >
                <TelegramIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(`mailto:?body=${paymentLink}`, "_blank")
                }
              >
                <EmailIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        message="Copiado en el portapapeles"
        autoHideDuration={3000}
      />
    </ContentContainer>
  );
};

export default BalanceComponent;

const ContentContainer = styled("div")({
  padding: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const BalanceBox = styled(Box)({
  backgroundColor: "#F7E000",
  borderRadius: "15px",
  padding: "20px",
  color: "#FFF",
  width: "100%",
  maxWidth: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "-150px",
});

const Title = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
});

const TitleText = styled(Typography)({
  color: "#fff",
});

const EuroButton = styled(Button)({
  backgroundColor: "#272541",
  color: "#fff",
  borderRadius: "20px",
  padding: "5px 15px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6f6aff",
  },
});

const Balance = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px",
});

const BalanceText = styled(Typography)({
  color: "#fff",
  display: "flex",
  alignItems: "center",
});

const VisibilityIconStyled = styled(VisibilityIcon)({
  color: "#fff",
});

const VisibilityOffIconStyled = styled(VisibilityOffIcon)({
  color: "#fff",
});

const AvailableText = styled(Typography)({
  color: "#fff",
  // marginBottom: "20px",
});

const ButtonsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const ActionBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "30%",
});

const ActionButton = styled(Button)({
  backgroundColor: "#272541",
  color: "#FFF",
  width: "80px",
  height: "80px",
  borderRadius: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6f6aff",
  },
});

const ActionText = styled(Typography)({
  color: "#FEFEFE",
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "0.875rem",
});

const DrawerContent = styled(Box)({
  padding: "20px",
  textAlign: "center",
});
