import React from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ElejirCalificar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { propina, costosTransaccion, totalPagado } = location.state || { propina: 0, costosTransaccion: 0, totalPagado: 0 };

  const personas = [
    { nombre: "Nicolas Capiel", avatar: "/path-to-avatar1.jpg" },
    { nombre: "Nicolas Fernandez", avatar: "/path-to-avatar2.jpg" },
  ];

  const handleSelect = (persona) => {
    navigate("/calificar", { state: { persona, propina, costosTransaccion, totalPagado } });
  };

  return (
    <Container>
      <Header>
        <Typography variant="h6">Califica el servicio de:</Typography>
        <IconButton onClick={() => navigate("/")}>
          <CloseIcon />
        </IconButton>
      </Header>
      <Box display="flex" justifyContent="space-around" mt={2}>
        {personas.map((persona) => (
          <PersonaBox
            key={persona.nombre}
            onClick={() => handleSelect(persona)}
          >
            <Avatar src={persona.avatar} sx={{ width: 80, height: 80 }} />
            <Typography>{persona.nombre}</Typography>
          </PersonaBox>
        ))}
      </Box>
    </Container>
  );
};

export default ElejirCalificar;

const Container = styled(Box)({
  padding: "20px",
  textAlign: "center",
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const PersonaBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});
