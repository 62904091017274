import React, { useState, useContext } from "react";
import {
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../context/OnboardingContext";

const TerminosYCondiciones = () => {
  const { setOnboardingData } = useContext(OnboardingContext);
  const [checkedTerminos, setCheckedTerminos] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  const handleTerminosChange = (event) => {
    setCheckedTerminos(event.target.checked);
    setIsFormValid(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOnboardingData((prevState) => ({
      ...prevState,
      terminosAceptados: checkedTerminos,
    }));
    navigate("/infoUsuario");
  };

  return (
    <MainContainer>
      <TopBox>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#272541",
            borderRadius: "15px",
            fontWeight: "bold",
          }}
          onClick={() => navigate(-1)}
        >
          Atrás
        </Button>
        <LogoImage src={Logo} alt="Logo TipWise" />
      </TopBox>
      <MiddleBox>
        <FormContainer>
          <Title variant="h5">Términos y condiciones</Title>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Confirmo que he leído y entendido los términos y condiciones de{" "}
            <strong>Tipwise</strong>, <strong>Stripe</strong> y el
            <strong> tratamiento de datos personales.</strong>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedTerminos}
                onChange={handleTerminosChange}
                sx={{
                  "&.Mui-checked": {
                    color: "#272541",
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontWeight: "bold" }}>
                Acepto los términos de uso y la política de privacidad.
              </Typography>
            }
          />
          <Link
            href="https://tipwise.ai/privacidad/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginBlock: 2 }}
          >
            Ver términos y condiciones TIPWISE
          </Link>
          <Link
            href="https://stripe.com/es-us/privacy"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginBottom: 2 }}
          >
            Ver términos y condiciones STRIPE
          </Link>
        </FormContainer>
      </MiddleBox>
      <BottomBox>
        <ContinueButton
          type="submit"
          variant="contained"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Confirmar
        </ContinueButton>
        <CancelButton variant="text" onClick={() => navigate("/editPerfil")}>
          Cancelar
        </CancelButton>
      </BottomBox>
    </MainContainer>
  );
};

export default TerminosYCondiciones;

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90vh",
  padding: "20px",
  "@media (max-width: 768px)": {
    padding: "30px",
  },
});

const TopBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const MiddleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const BottomBox = styled(Box)({
  width: "100%",
  paddingBottom: "20px",
});

const LogoImage = styled("img")({
  width: 80,
  height: 50,
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
});

const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.4rem",
  alignSelf: "flex-start",
  marginBottom: 10,
});

const ContinueButton = styled(Button)({
  width: "100%",
  height: "50px",
  marginTop: 20,
  borderRadius: "15px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#F7E000",
    color: "#272541",
  },
  "&:active": {
    backgroundColor: "#1f1a33", // Cambia este color al que desees cuando se hace clic
  },
});

const CancelButton = styled(Button)({
  width: "100%",
  marginTop: 10,
  height: "50px",
  borderRadius: "15px",
  color: "#272541",
  fontWeight: "bold",
});
