import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Link,
  Typography,
  TextField,
  Box,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../AxiosConfig";
import logo from "../../assets/TipWise.png";
import { AuthContext } from "../../context/authContext";

const ValidarIdentidad = () => {
  const location = useLocation();
  const initialEmail = location.state?.email || "";
  const [email, setEmail] = useState(initialEmail);
  const [code, setCode] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(false);
  const history = useNavigate();
  const { login } = useContext(AuthContext);

  useEffect(() => {
    validateForm();
  }, [email, code]);

  const validateForm = () => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const codeValid = code.length > 4 && code.length < 8;
    setIsFormValid(emailValid && codeValid);
  };

  const handleContinuar = async () => {
    try {
      const response = await login(email, code);
      if (response.status !== 200) {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleReenviar = () => {
    axios
      .post(`/auth/generate-access-code`, {
        email,
      })
      .then((response) => {
        console.log("Access code sent:", response.data);
      })
      .catch((error) => {
        console.error("Error sending access code:", error);
      });
  };

  return (
    <CenteredContainer>
      <LogoImage src={logo} alt="Logo" />
      <Typography variant="h4" sx={{ color: "#272541", marginBottom: "10px" }}>
        Verifica tu identidad
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: "#272541", marginBottom: "20px" }}
      >
        Hemos enviado un correo electrónico con tu código a:
      </Typography>
      <InputBox>
        <StyledTextField
          error={error}
          label="Correo electrónico"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: "10px" }}
        />
        <StyledTextField
          error={error}
          label="Introduzca el código"
          variant="outlined"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </InputBox>
      <ContinueButton
        variant="contained"
        size="large"
        onClick={handleContinuar}
        disabled={!isFormValid}
      >
        Continuar
      </ContinueButton>
      <Typography variant="body1" sx={{ marginTop: "20px" }}>
        ¿No recibiste un email?{" "}
        <Link href="#" onClick={handleReenviar}>
          Reenviar
        </Link>
      </Typography>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          El email o código de acceso no son válidos
        </Alert>
      </Snackbar>
    </CenteredContainer>
  );
};

export default ValidarIdentidad;

const CenteredContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  paddingBottom: "50px",
  paddingInline: "8px",
});

const LogoImage = styled("img")({
  width: "180px",
  marginBottom: "20px",
});

const InputBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: 300,
  marginBottom: 20,
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
    "& fieldset": {
      borderColor: (props) => (props.error ? "red" : ""),
    },
    "&:hover fieldset": {
      borderColor: (props) => (props.error ? "red" : ""),
    },
    "&.Mui-focused fieldset": {
      borderColor: (props) => (props.error ? "red" : ""),
    },
  },
});

const ContinueButton = styled(Button)({
  width: "300px",
  backgroundColor: "#272541",
  borderRadius: "15px",
  "&:hover": {
    backgroundColor: "#272541",
  },
});
