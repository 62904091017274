import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import axios from '../../AxiosConfig';
import { AuthContext } from '../../context/authContext';

const CrearEmpresa: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [nombre, setNombre] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    validateForm();
  }, [nombre]);

  const validateForm = () => {
    setIsFormValid(nombre.trim() !== "");
  };

  const handleCrearEmpresa = async () => {
    try {
      const response = await axios.post('/companies', { name: nombre }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      const newCompany = response.data.company;
      const defaultBote = response.data.defaultBote;

      // Asociar al usuario con la empresa en el frontend
      user.companies.push(newCompany._id);

      alert('Empresa creada con éxito y bote por defecto asociado');
      navigate("/home");
    } catch (error) {
      console.error('Error creando empresa:', error);
      alert('Error creando empresa');
    }
  };

  return (
    <>
      <BoxButtonNotificaciones>
        <Button onClick={() => navigate("/home")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <BoxLabelNotificaciones>
          <LabelNotificaciones>Crear Empresa</LabelNotificaciones>
        </BoxLabelNotificaciones>
      </BoxButtonNotificaciones>
      <ContainerBox>
        <InnerBox>
          <StyledTextField
            label="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </InnerBox>
        <StyledButton
          variant="contained"
          onClick={handleCrearEmpresa}
          disabled={!isFormValid}
        >
          Crear
        </StyledButton>
      </ContainerBox>
    </>
  );
};

export default CrearEmpresa;

// Styled components
const BoxButtonNotificaciones = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  marginBottom: "20px",
});

const BoxLabelNotificaciones = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const LabelNotificaciones = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const ContainerBox = styled(Box)({
  width: "100%",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
});

const InnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  minHeight: "400px",
});

const StyledTextField = styled(TextField)({
  marginBlock: "20px",
  width: "300px",
});

const StyledButton = styled(Button)({
  width: "300px",
  backgroundColor: "#5657FF",
});
