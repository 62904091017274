import React, { useState } from "react";
import {
  Box,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import AppBarMenu from "../navBar/NavBarMenu.tsx";
import { styled } from "@mui/material/styles";

const Perfil = () => {
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useNavigate();
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  console.log("user", user);

  return (
    <>
      <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <ProfileContainer>
        <ProfileImage
          src={localStorage.getItem("avatar")}
          //src={user.avatar ? user.avatar : user.companies[0].avatar}
          alt="Foto Perfil"
        />
        <ProfileTextContainer>
          {user.firstName ? (
            <ProfileName>{user.firstName + " " + user.lastName}</ProfileName>
          ) : (
            ""
          )}
          <ProfileLink
            onClick={() => {
              if (user.userType && user.userType == "empresa") {
                history("/editPerfilEmpresa");
              } else {
                history("/editPerfil");
              }
            }}
          >
            Ver perfil
          </ProfileLink>
        </ProfileTextContainer>
      </ProfileContainer>
      <MenuContainer>
        <StyledListItemButton
          onClick={() => {
            if (user.userType && user.userType == "empresa") {
              history("/informacionEmpresa");
            } else {
              history("/informacionPersonal");
            }
          }}
        >
          <StyledListItemIcon>
            <AccountCircleOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText
            primary={
              user.userType && user.userType == "empresa"
                ? "Informacion Empresa"
                : "Informacion personal"
            }
          />
          <ChevronRightIcon />
        </StyledListItemButton>
        {user.userType !== "empresa" && (
          <>
            <StyledListItemButton onClick={() => history("/documentos")}>
              <StyledListItemIcon>
                <ArticleOutlinedIcon />
              </StyledListItemIcon>
              <StyledListItemText primary="Documentos" />
              <ChevronRightIcon />
            </StyledListItemButton>

            <StyledListItemButton
              onClick={() => history("/cuentaBancoDeposito")}
            >
              <StyledListItemIcon>
                <AccountBalanceOutlinedIcon />
              </StyledListItemIcon>
              <StyledListItemText primary="Cuenta bancaria de deposito" />
              <ChevronRightIcon />
            </StyledListItemButton>
          </>
        )}

        <StyledListItemButton onClick={() => history("/lenguajeSelector")}>
          <StyledListItemIcon>
            <LanguageOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="Lenguaje" />
          <ChevronRightIcon />
        </StyledListItemButton>

        <StyledListItemButton onClick={() => history("/privacidad")}>
          <StyledListItemIcon>
            <LockOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="Privacidad" />
          <ChevronRightIcon />
        </StyledListItemButton>

        <StyledListItemButton onClick={() => history("/tycPerfil")}>
          <StyledListItemIcon>
            <BookOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="Terminos y condiciones" />
          <ChevronRightIcon />
        </StyledListItemButton>
      </MenuContainer>
    </>
  );
};

export default Perfil;

const ProfileContainer = styled(Box)({
  margin: "80px 15px 30px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#F7E000",
  borderRadius: "15px",
  borderTopLeftRadius: "40px",
  borderBottomLeftRadius: "40px",
});

const ProfileImage = styled("img")({
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const ProfileTextContainer = styled(Box)({
  marginLeft: "15px",
});

const ProfileName = styled(Typography)({
  fontSize: "22px",
  fontWeight: "bold",
  color: "#272541",
});

const ProfileLink = styled(Typography)({
  fontSize: "14px",
  textDecoration: "underline",
  color: "#3f51b5",
  cursor: "pointer",
  "&:hover": {
    color: "#1a237e",
  },
});

const MenuContainer = styled(Box)({
  backgroundColor: "#f5f5f5",
  borderRadius: "10px",
  padding: "10px",
  margin: "0 25px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const StyledListItemButton = styled(ListItemButton)({
  borderRadius: "8px",
  marginBottom: "8px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const StyledListItemIcon = styled(ListItemIcon)({
  color: "#272541",
});

const StyledListItemText = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontWeight: "bold",
    color: "#424242",
  },
});
