import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { styled } from "@mui/material/styles";
import axios from "../../AxiosConfig";

const ScheduleList: React.FC = () => {
  const [schedules, setSchedules] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    axios.get("/schedules")
      .then(response => setSchedules(response.data.schedules))
      .catch(error => console.error("Error fetching schedules:", error));
  }, []);

  const handleAddSchedule = () => {
    history("/addSchedule");
  };

  return (
    <ContainerBox>
      <BoxButtonNotificaciones>
        <Button onClick={() => history("/home")}>
          <ArrowBackOutlinedIcon />
        </Button>
        <BoxLabelNotificaciones>
          <LabelNotificaciones>Horarios</LabelNotificaciones>
        </BoxLabelNotificaciones>
      </BoxButtonNotificaciones>
      <InnerBox>
        <StyledButton variant="contained" onClick={handleAddSchedule}>Agregar Horario</StyledButton>
        {schedules.map((schedule: any) => (
          <ScheduleItem key={schedule._id}>
            <Typography variant="h6">{schedule.timeSlot}</Typography>
            <Typography>Inicio: {schedule.startTime}</Typography>
            <Typography>Fin: {schedule.endTime}</Typography>
            <Typography>Bote: {schedule.bote?.nombre}</Typography>
          </ScheduleItem>
        ))}
      </InnerBox>
    </ContainerBox>
  );
};

export default ScheduleList;

const BoxButtonNotificaciones = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  height: "50px",
  marginBottom: "20px",
});

const BoxLabelNotificaciones = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const LabelNotificaciones = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
});

const ContainerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const InnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "400px",
  padding: "20px",
});

const ScheduleItem = styled(Box)({
  borderBottom: "1px solid grey",
  padding: "10px 0",
  width: "100%",
  textAlign: "center",
});

const StyledButton = styled(Button)({
  marginBottom: "20px",
  backgroundColor: "#5657FF",
});
