import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Box,
  Typography,
  LinearProgress,
  Divider,
  IconButton,
  Card,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

const RetiroExitoso = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { propina, costosTransaccion } = location.state || {
    propina: 0,
    costosTransaccion: 0,
  };
  const totalPagado = propina + costosTransaccion;

  return (
    <Container>
      <CloseButton onClick={() => navigate("/home")}>
        <CloseIcon />
      </CloseButton>
      <CheckIcon />
      <Title variant="h5">Retiro exitoso</Title>
      <Subtitle variant="body1">
        Te notificaremos cuando el estado de tu pedido cambie. También puedes
        ver el estado de tu pedido en la sección de transacciones.
      </Subtitle>
      <CardStyled>
        <DetailItem>
          <Typography variant="body1">Cantidad de retiro</Typography>
          <Typography variant="body1">€ {propina.toFixed(2)}</Typography>
        </DetailItem>
        <DetailItem>
          <Typography variant="body1">Costos del proveedor</Typography>
          <Typography variant="body1">
            - € {costosTransaccion.toFixed(2)}
          </Typography>
        </DetailItem>
        <DividerStyled />
        <DetailItem>
          <Typography variant="h6" fontWeight="bold">
            Obtenerás
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            € {totalPagado.toFixed(2)}
          </Typography>
        </DetailItem>
      </CardStyled>
      <Link to={"/home"}>
        <Typography sx={{ marginTop: "40px" }}> Ir al inicio</Typography>
      </Link>
    </Container>
  );
};

export default RetiroExitoso;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  height: "100vh",
  justifyContent: "center",
  position: "relative",
  backgroundColor: "#f7f7f7",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "16px",
  right: "16px",
});

const LinearProgressStyled = styled(LinearProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
});

const CheckIcon = styled(CheckCircleIcon)({
  fontSize: "80px",
  color: "green",
  marginBottom: "24px",
});

const Title = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "16px",
  fontSize: "24px",
});

const Subtitle = styled(Typography)({
  color: "gray",
  marginBottom: "32px",
  textAlign: "center",
});

const DividerStyled = styled(Divider)({
  width: "100%",
  margin: "16px 0",
});

const CardStyled = styled(Card)({
  width: "100%",
  maxWidth: "400px",
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
});

const DetailItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
});
