import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Drawer,
  IconButton,
  Snackbar,
  Link,
  Tooltip,
  Alert,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BalanceComponent from "../balance/Balance.tsx";
import TransaccionesCard from "../transacciones/CardTransacciones.tsx";
import AppBarMenu from "./NavBarMenu.tsx";
import Footer from "../footer/Footer.tsx";
import axios from "../../AxiosConfig.js";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Home: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenQR, setIsDrawerOpenQR] = useState(false);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [stripeAccount, setStripeAccount] = useState(null);
  const [accountAlert, setAccountAlert] = useState(false);

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const history = useNavigate();

  useEffect(() => {
    const fetchStripeAccount = async () => {
      try {
        const response = await axios.get(`/usersAccount/${user._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setStripeAccount(response.data);

        if (!response.data.charges_enabled || !response.data.payouts_enabled) {
          setAccountAlert(true);
        }
      } catch (error) {
        console.error("Error fetching Stripe account:", error);
      }
    };

    fetchStripeAccount();
  }, [user._id]);

  const handleArchive = () => {
    console.log("Notificación archivada");
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `/stripe-balance-transactions?userId=${user._id}`
        );
        setTransactions(response.data.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [user._id]);

  const formatDate = (dateTransaction, dateDisponible) => {
    const date = new Date(dateTransaction * 1000);
    const dateReady = new Date(dateDisponible * 1000);
    const options = { day: "numeric", month: "long", year: "numeric" };

    return `Transaccion realizada el ${date.toLocaleDateString(
      "es-ES",
      options
    )}, Disponible el ${dateReady.toLocaleDateString("es-ES", options)}`;
  };

  const balanceValue = (transactions) => {
    const totalNet = transactions
      .filter((transaction) => transaction.net > 0)
      .reduce((sum, transaction) => sum + transaction.net, 0);

    return totalNet / 100;
  };

  const balanceAvailable = (transactions) => {
    const totalNetAvailable = transactions
      .filter(
        (transaction) =>
          transaction.status === "available" && transaction.net > 0
      )
      .reduce((sum, transaction) => sum + transaction.net, 0);

    return totalNetAvailable / 100;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <AppBarMenu
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
      />

      <Box component="main" sx={{ flexGrow: 1 }}>
        <ToolbarStyled />
        <MainBox>
        </MainBox>

        <BoxContainer>
          <BalanceComponent
            totalBalance={balanceValue(transactions)}
            totalAvailable={balanceAvailable(transactions)}
          />
        </BoxContainer>
        
        {/* Mostrar alerta si la cuenta no está activada */}
        {accountAlert && (
          <TransactionHeader>
            <Alert
              severity="warning"
              sx={{ marginBottom: "20px", marginInline: "15px" }}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => history("/documentos")}
                >
                  Ir a Documentos
                </Button>
              }
            >
              Tu cuenta en Stripe está pendiente de verificación. Por favor,
              completa la verificación para habilitar cargos y pagos.
            </Alert>
          </TransactionHeader>
        )}

        <TransactionHeader>
          <Typography
            variant="h6"
            fontWeight="bold"
            marginTop="30px"
            paddingBottom="5px"
            color="#272541"
          >
            Historial de transacciones
          </Typography>
        </TransactionHeader>

        <TransactionContainer>
          <TransactionBox>
            {transactions.map((transaction, index) => (
              <TransaccionesCard
                key={index}
                date={formatDate(transaction.created)}
                title={formatDate(
                  transaction.created,
                  transaction.available_on
                )}
                content={transaction.status}
                amount={transaction.amount}
                fee={transaction.fee}
                net={transaction.net}
              />
            ))}
          </TransactionBox>
        </TransactionContainer>
      </Box>

      <Footer />
    </Box>
  );
};

export default Home;

const ToolbarStyled = styled("div")({
  height: 44,
});

const MainBox = styled(Box)({
  backgroundColor: "#272541",
  height: 180,
  display: "flex",
  alignContent: "center",
});

const BoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const TransactionHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  alignContent: "center",
  alignItems: "flex-end",
});

const TransactionContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const TransactionBox = styled(Box)({
  maxHeight: "250px",
  overflowY: "auto",
});
