import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  Link,
  ButtonBase,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ConfigurarRubro = () => {
  const [venueName, setVenueName] = useState("");
  const navigate = useNavigate();
  const segment = localStorage.getItem("selectedSegment");

  const handleBack = () => {
    navigate("/seleccionarRubro");
  };

  const handleTextfieldChange = (event) => {
    setVenueName(event.target.value);
  };

  const handleContinue = () => {
    localStorage.setItem("venueName", venueName);
    navigate("/direccionEmpresa");
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <BackButton variant="contained" onClick={handleBack}>
          Atrás
        </BackButton>
        <StyledImg src={localStorage.getItem("avatar") || ""} />
      </HeaderContainer>
      <ParagraphContainer>
        <Typography
          variant="h5"
          component="h2"
          fontWeight={"bold"}
          sx={{ marginBottom: "10px" }}
        >
          Configura el perfil del lugar
        </Typography>
        <StyledParagraph>
          Esta información necesita ser precisa.
        </StyledParagraph>
      </ParagraphContainer>
      <ContentContainer>
        <Typography
          variant="body1"
          sx={{ color: "#626067", marginLeft: "5px" }}
        >
          Nombre del negocio
        </Typography>
        <TextField
          variant="outlined"
          value={venueName}
          onChange={handleTextfieldChange}
          InputProps={{
            sx: {
              height: "50px",
              borderRadius: "10px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#4640b3",
              },
            },
          }}
        />
      </ContentContainer>
      <StyledButton onClick={handleContinue}>Continuar</StyledButton>
    </MainContainer>
  );
};

export default ConfigurarRubro;

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const StyledImg = styled("img")(({ theme }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
}));

const ParagraphContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "30px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginBottom: "10px",
  color: "#626067",
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
    marginBottom: "25px",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },

  [theme.breakpoints.up("lg")]: {
    width: "34%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  marginTop: "350px",
  backgroundColor: "#272541",
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#3b36a6",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    marginTop: "300px",
  },
  [theme.breakpoints.up("md")]: {
    width: "58%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
    marginTop: "200px",
  },
}));
