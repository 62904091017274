import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../context/OnboardingContext";
import axios from "../../AxiosConfig";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pica from "pica";

const InfoEmpresa = () => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const [username, setUsername] = useState(onboardingData.username);
  const [description, setDescription] = useState(onboardingData.description);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("avatar") || onboardingData.avatar || ""
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(username.trim() !== "" && description.trim() !== "");
  }, [username, description]);

  const resizeImage = async (file, maxWidth, maxHeight) => {
    const picaInstance = pica();
    const img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    await img.decode();

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const aspectRatio = img.width / img.height;
    if (img.width > maxWidth || img.height > maxHeight) {
      if (aspectRatio > 1) {
        canvas.width = maxWidth;
        canvas.height = maxWidth / aspectRatio;
      } else {
        canvas.height = maxHeight;
        canvas.width = maxHeight * aspectRatio;
      }
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    await picaInstance.resize(img, canvas);
    const base64String = canvas.toDataURL("image/jpeg", 0.8); // Adjust the quality as needed
    return base64String;
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const resizedBase64 = await resizeImage(file, 800, 800);
      setAvatar(resizedBase64);
      localStorage.setItem("avatar", resizedBase64);
    }
  };

  const handleAvatarDelete = () => {
    setAvatar("");
    localStorage.removeItem("avatar");
  };

  const segment = localStorage.getItem("selectedSegment");
  const name = localStorage.getItem("venueName");
  const address = localStorage.getItem("address");
  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const parsedAddress = JSON.parse(address);
    const finalData = {
      name,
      segment,
      address: parsedAddress,
      description,
      username: username,
      completedOnboarding: true,
      avatar,
      userId: user._id,
    };
    try {
      const response = await axios.post(
        "/company/complete-onboarding",
        finalData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(response);
      localStorage.setItem("company", JSON.stringify(response.data.company));
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate("/home");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  return (
    <Container>
      <Header>
        <IconButton onClick={() => navigate("/tycEmpresa")}>
          <ArrowBackIcon sx={{ color: "#FFFFFF" }} />
        </IconButton>
        <TitleContainer>
          <TitleText>Cuentanos sobre tu negocio</TitleText>
        </TitleContainer>
      </Header>
      <SubHeader>
        Complete esta información para permitir a los usuarios conocerle mejor
      </SubHeader>
      <CardContainer>
        <AvatarContainer>
          <IconButton component="span">
            <Avatar src={avatar} sx={{ width: "100px", height: "100px" }}>
              {!avatar && "N"}
            </Avatar>
            {avatar && (
              <DeleteButton onClick={handleAvatarDelete}>
                <CloseIcon sx={{ color: "red" }} />
              </DeleteButton>
            )}
          </IconButton>
        </AvatarContainer>
        <label htmlFor="icon-button-file">
          <UploadText>Subir foto</UploadText>
        </label>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={handleAvatarChange}
        />
        <Form>
          <StyledTextField
            label="Usuario"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: "@",
            }}
          />
          <StyledTextField
            label="Descripción"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            inputProps={{ maxLength: 140 }}
            helperText={`${description.length} / 140`}
          />
          <FinalButton
            variant="contained"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            FINALIZAR
          </FinalButton>
        </Form>
      </CardContainer>
    </Container>
  );
};

export default InfoEmpresa;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "",
  height: "100vh",
  backgroundColor: "#f9f9f9",
});

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
  backgroundColor: "#272541",
  padding: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const TitleContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
});

const TitleText = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#FFFFFF",
});

const SubHeader = styled(Typography)({
  textAlign: "center",
  marginBottom: "20px",
});

const CardContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  marginInline: "15px",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
});

const AvatarContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
  marginBottom: "10px",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: "white",
  borderRadius: "50%",
});

const UploadText = styled(Typography)({
  cursor: "pointer",
  color: "blue",
  marginBottom: "20px",
});

const Form = styled(Box)({
  width: "100%",
  maxWidth: "500px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const StyledTextField = styled(TextField)({
  width: "100%",
});

const FinalButton = styled(Button)({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#F7E000",
    color: "#272541",
  },
  "&:active": {
    backgroundColor: "#1f1a33", // Cambia este color al que desees cuando se hace clic
  },
});
