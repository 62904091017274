import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Drawer,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "../../../AxiosConfig"; // Suponiendo que estás usando Axios para las solicitudes al backend

const CuentaBancariaDeposito = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [iban, setIban] = useState("");
  const [reference, setReference] = useState("");
  const [newIban, setNewIban] = useState("");
  const [newReference, setNewReference] = useState("");
  const [ibanError, setIbanError] = useState(false); // Para validar el IBAN
  const history = useNavigate();

  useEffect(() => {
    // Cargar datos del localStorage si están disponibles
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.bankIban) setIban(user.bankIban);
      if (user.bankReference) setReference(user.bankReference);
    }
  }, []);

  const handleDrawerOpen = () => {
    if (!iban) {
      setIsDrawerOpen(true);
    } else {
      alert("Ya tienes un IBAN agregado. Solo se permite agregar uno.");
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  // Validar formato de IBAN usando una expresión regular
  const validateIban = (iban) => {
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/; // Expresión regular para validar IBAN
    return ibanRegex.test(iban);
  };

  const handleSave = async () => {
    if (!validateIban(newIban)) {
      setIbanError(true); // Marcar error si el IBAN no es válido
      return;
    }
    try {
      setIban(newIban);
      setReference(newReference);

      // Actualizar el backend y localStorage
      const storedUser = localStorage.getItem("user");
      const user = storedUser ? JSON.parse(storedUser) : null;

      if (user) {
        const updatedUser = { ...user, bankIban: newIban, bankReference: newReference };

        // Hacer la solicitud al backend para actualizar el usuario
        await axios.patch(`/users/${user._id}`, {
          bankIban: newIban,
          bankReference: newReference,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        // Actualizar el localStorage con los nuevos datos
        localStorage.setItem("user", JSON.stringify(updatedUser));

        alert("Datos guardados correctamente");
      } else {
        console.error("Usuario no encontrado en el localStorage");
      }

      handleDrawerClose();
    } catch (error) {
      console.error("Error al guardar los datos:", error);
      alert("Error al guardar los datos.");
    }
  };

  // Función para ocultar IBAN y mostrar solo los últimos 4 dígitos
  const formatIban = (iban) => {
    if (!iban) return "";
    return "XXXX XXXX XXXX XXXX " + iban.slice(-4);
  };

  return (
    <>
      <Header>
        <StyledButton onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon />
        </StyledButton>
        <TitleContainer>
          <HeaderTypography>Cuenta bancaria de depósito</HeaderTypography>
        </TitleContainer>
      </Header>
      <MainContainer>
        <CardContainer>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Método Bancario</Typography>
              <Typography>IBAN: {formatIban(iban)}</Typography>
              <Typography>Referencia: {reference || "No disponible"}</Typography>
            </CardContent>
          </StyledCard>
        </CardContainer>
        <Typography>
          Recuerda que sólo puedes asociar un IBAN por cuenta.
        </Typography>
        <AddMethodButton
          variant="contained"
          onClick={handleDrawerOpen}
          disabled={!!iban} // Deshabilitar botón si ya hay un IBAN
        >
          {iban ? "IBAN Agregado" : "Agregar método"}
        </AddMethodButton>
      </MainContainer>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose}>
        <DrawerContent>
          <Header>
            <StyledButton onClick={handleDrawerClose}>
              <ArrowBackOutlinedIcon />
            </StyledButton>
            <TitleContainer>
              <HeaderTypography>
                Agregar nuevo método de retiro
              </HeaderTypography>
            </TitleContainer>
          </Header>
          <Box p={2}>
            <TextField
              fullWidth
              label="IBAN"
              placeholder="XXXXXXXXXXXXXXXXXXXXXX"
              margin="normal"
              value={newIban}
              onChange={(e) => {
                setNewIban(e.target.value);
                setIbanError(false); // Resetear el error si el usuario está editando
              }}
              error={ibanError}
              helperText={ibanError ? "IBAN inválido. Por favor verifique." : ""}
            />
            <Typography
              variant="body2"
              sx={{ marginTop: "10px", marginBottom: "20px" }}
            >
              Por favor ingrese aquí su IBAN que puede encontrar en su resumen
              bancario o en su sistema de e-banking
            </Typography>
            <TextField
              fullWidth
              label="Referencia"
              placeholder="Referencia"
              margin="normal"
              value={newReference}
              onChange={(e) => setNewReference(e.target.value)}
            />
            <SaveButton variant="contained" onClick={handleSave}>
              Guardar
            </SaveButton>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CuentaBancariaDeposito;

// Estilos (sin cambios)
const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "0 1px",
  height: "50px",
  backgroundColor: "#272541",
  color: "#FFFFFF",
});

const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#FFFFFF",
  "@media (max-width: 768px)": {
    fontSize: "18px",
  },
});

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "50px",
  "@media (max-width: 768px)": {
    marginTop: "20px",
    padding: "0 10px",
  },
});

const AddMethodButton = styled(Button)({
  width: "90%",
  backgroundColor: "#272541",
  color: "#fff",
  marginTop: "20px",
  height: "50px",
  borderRadius: "15px",
  "&:hover": {
    backgroundColor: "#1f1a33",
  },
  "@media (max-width: 768px)": {
    width: "90%",
  },
});

const CardContainer = styled(Box)({
  width: "95%",
  marginBottom: "20px",
  "@media (max-width: 768px)": {
    width: "90%",
  },
});

const StyledCard = styled(Card)({
  borderRadius: "10px",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
});

const DrawerContent = styled(Box)({});

const SaveButton = styled(Button)({
  width: "100%",
  padding: "15px",
  backgroundColor: "#272541",
  color: "#fff",
  borderRadius: "15px",
  "&:hover": {
    backgroundColor: "#1f1a33",
  },
  marginTop: "20px",
});

const StyledButton = styled(Button)({
  color: "#FFFFFF",
});
