import React, { useState } from "react";
import { Box, Typography, Button, ButtonBase, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const RubroEmpresa = () => {
  const [selectedSegment, setSelectedSegment] = useState("");
  const [otherSegment, setOtherSegment] = useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/tipoEmpresa");
  };

  const handleSelection = (value) => {
    setSelectedSegment(value);
  };

  const handleContinue = () => {
    const segmentToSave =
      selectedSegment === "Otro" ? otherSegment : selectedSegment;
    localStorage.setItem("selectedSegment", segmentToSave);
    navigate("/nombreEmpresa");
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <BackButton variant="contained" onClick={handleBack}>
          Atrás
        </BackButton>
        <StyledImg src={localStorage.getItem("avatar") || ""} />
      </HeaderContainer>
      <ParagraphContainer>
        <Typography
          variant="h5"
          component="h2"
          fontWeight={"bold"}
          sx={{ marginBottom: "10px" }}
        >
          ¿En qué se especializa el lugar?
        </Typography>
        <StyledParagraph>Elija una opción.</StyledParagraph>
      </ParagraphContainer>
      <StyledBoxContainer>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {[
            "Restaurante",
            "Bar",
            "Hotel",
            "Hospital/Clínica/Centro de salud",
            "Organización sin fines de lucro",
            "Empresa de delivery",
            "Transporte",
            "Otro",
          ].map((segment) => (
            <StyledButtonBase
              key={segment}
              onClick={() => handleSelection(segment)}
            >
              <StyledCard selected={selectedSegment === segment}>
                <StyledTypogrphy>{segment}</StyledTypogrphy>
              </StyledCard>
            </StyledButtonBase>
          ))}
        </Box>
        {selectedSegment === "Otro" && (
          <TextField
            fullWidth
            label="Ingrese especialidad"
            value={otherSegment}
            onChange={(e) => setOtherSegment(e.target.value)}
            sx={{ marginTop: "15px" }}
          />
        )}
      </StyledBoxContainer>
      <StyledButton
        onClick={handleContinue}
        variant="contained"
        disabled={
          !selectedSegment || (selectedSegment === "Otro" && !otherSegment)
        }
      >
        Continuar
      </StyledButton>
    </MainContainer>
  );
};

export default RubroEmpresa;

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const StyledImg = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
}));

const ParagraphContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "30px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  marginBottom: "10px",
  color: "#626067",
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
    marginBottom: "25px",
  },
}));

const StyledTypogrphy = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
}));

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({}));

const StyledCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ selected }) => ({
  padding: "5px 10px",
  border: selected ? "2px solid #7c7495" : "1px solid #ccc",
  color: selected ? "#7c7495" : "black",
  backgroundColor: selected ? "#f1f0ff" : "#ffffff",
  borderRadius: "15px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "50px",
  borderRadius: "15px",
  fontWeight: "bold",
  marginTop: "15px",
  backgroundColor: "#272541",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#272541",
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    marginTop: "100px",
  },
  [theme.breakpoints.up("md")]: {
    width: "58%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "34%",
    marginTop: "100px",
  },
}));

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});
