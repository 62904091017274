import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Typography,
  Box,
  Button,
  Divider,
  Tabs,
  Tab,
  Toolbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EuroIcon from "@mui/icons-material/Euro";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarMenu from "../navBar/NavBarMenu.tsx";

const BalanceAndTransactions = () => {
  const [showBalance, setShowBalance] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // eslint-disable-next-line
  const location = useLocation();
  // eslint-disable-next-line
  const history = useNavigate();

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const toggleVisibility = () => {
    setShowBalance(!showBalance);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // eslint-disable-next-line
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const transactions = [
    {
      id: 1,
      type: "Desde Turno Noche",
      date: "Fri 3 Nov, 2023 4:36 PM",
      amount: "+ € 2.29",
      status: "completed",
    },
    {
      id: 2,
      type: "Desde Turno Almuerzo",
      date: "Fri 3 Nov, 2023 4:05 PM",
      amount: "+ € 4.58",
      status: "completed",
    },
    {
      id: 3,
      type: "Desde Turno Almuerzo",
      date: "Fri 3 Nov, 2023 3:33 PM",
      amount: "",
      status: "in progress",
    },
  ];

  return (
    <div>
      <AppBarMenu isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <MainContainer>
        <Toolbar /> {/* To offset the AppBar height */}
        <ContentContainer>
          <BalanceBox>
            <Title>
              <Typography variant="subtitle1" sx={{ color: "#272541" }}>
                Balance
              </Typography>
              <EuroButton
                variant="contained"
                color="primary"
                startIcon={<EuroIcon />}
              >
                EUR
              </EuroButton>
            </Title>
            <Balance>
              <Typography variant="h5" sx={{ color: "#272541" }}>
                <EuroIcon />
                {showBalance ? "2.29" : "*****"}
              </Typography>
              <IconButton onClick={toggleVisibility}>
                {showBalance ? (
                  <VisibilityIconStyled sx={{ color: "#272541" }} />
                ) : (
                  <VisibilityOffIconStyled sx={{ color: "#272541" }} />
                )}
              </IconButton>
            </Balance>
            <Typography variant="body2" sx={{ color: "#272541" }}>
              Disponible para retirar
            </Typography>
          </BalanceBox>
        </ContentContainer>
        <TransactionsContainer>
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Todas" />
            <Tab label="Recibidas" />
            <Tab label="Enviadas" />
          </Tabs>
          <Divider />
          {transactions.map((transaction) => (
            <TransactionCard key={transaction.id}>
              <TransactionIcon>
                {transaction.status === "completed" ? (
                  <ArrowUpwardIcon />
                ) : (
                  <AccessTimeIcon />
                )}
              </TransactionIcon>
              <TransactionDetails>
                <Typography variant="body2">{transaction.type}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {transaction.date}
                </Typography>
              </TransactionDetails>
              <TransactionAmount>
                {transaction.status === "completed" ? (
                  <Typography color="green">{transaction.amount}</Typography>
                ) : (
                  <Typography color="orange">In progress</Typography>
                )}
              </TransactionAmount>
            </TransactionCard>
          ))}
        </TransactionsContainer>
      </MainContainer>
    </div>
  );
};

export default BalanceAndTransactions;

const MainContainer = styled(Box)({
  backgroundColor: "#F3F4F6",
  padding: "20px",
  minHeight: "100vh",
});

const ContentContainer = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const BalanceBox = styled(Box)({
  backgroundColor: "#F7E000",
  borderRadius: "15px",
  padding: "20px",
  color: "#FFF",
  width: "100%",
  maxWidth: "350px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
});

const Title = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
});

const EuroButton = styled(Button)({
  backgroundColor: "#272541",
  color: "#fff",
  borderRadius: "20px",
  padding: "5px 15px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6f6aff",
  },
});

const Balance = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px",
});

const VisibilityIconStyled = styled(VisibilityIcon)({
  color: "#fff",
});

const VisibilityOffIconStyled = styled(VisibilityOffIcon)({
  color: "#fff",
});

const TransactionsContainer = styled(Box)({
  marginTop: "20px",
  backgroundColor: "#FFF",
  borderRadius: "15px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "10px",
});

const TransactionCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid #E0E0E0",
  "&:last-child": {
    borderBottom: "none",
  },
});

const TransactionIcon = styled(Box)({
  marginRight: "10px",
});

const TransactionDetails = styled(Box)({
  flexGrow: 1,
});

const TransactionAmount = styled(Box)({
  marginLeft: "10px",
});
