import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ReseñaExitosa = () => {

  return (
    <Container>
      <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
      <Typography variant="h5" mt={2}>
        ¡Reseña enviada con éxito!
      </Typography>
      <Typography variant="body1" mt={1}>
        ¡Gracias por tu valiosa opinión!
      </Typography>
    </Container>
  );
};

export default ReseñaExitosa;

const Container = styled(Box)({
  padding: "20px",
  textAlign: "center",
});
