import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Switch,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const Privacidad = () => {
  const history = useNavigate();
  const [hideWorkLocation, setHideWorkLocation] = useState(false);
  const [hideLastName, setHideLastName] = useState(false);

  const handleWorkLocationToggle = () => {
    setHideWorkLocation((prev) => !prev);
  };

  const handleLastNameToggle = () => {
    setHideLastName((prev) => !prev);
  };

  return (
    <>
      <HeaderBox>
        <StyledButton onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon />
        </StyledButton>
        <TitleContainer>
          <HeaderTypography>Privacidad</HeaderTypography>
        </TitleContainer>
      </HeaderBox>
      <MainContainer>
        <StyledListItemButton>
          <ListItemIcon>
            <RoomOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Ocultar lugar de trabajo" />
          <Switch
            checked={hideWorkLocation}
            onChange={handleWorkLocationToggle}
          />
        </StyledListItemButton>
        <StyledListItemButton>
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Ocultar apellido" />
          <Switch checked={hideLastName} onChange={handleLastNameToggle} />
        </StyledListItemButton>
      </MainContainer>
    </>
  );
};

export default Privacidad;

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 16px",
  backgroundColor: "#272541",
  color: "#FFFFFF",
});

const StyledButton = styled(Button)({
  color: "#FFFFFF",
});

const TitleContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flex: 1,
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#FFFFFF",
  "@media (max-width: 768px)": {
    fontSize: "18px",
  },
});

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    padding: "10px",
  },
});

const StyledListItemButton = styled(ListItemButton)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});
