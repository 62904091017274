import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Autocomplete,
  ClickAwayListener,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { OnboardingContext } from "../../context/OnboardingContext";
import Logo from "../../assets/logos/ISOLOGOAmarilloAzul.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const RegisterForm: React.FC = () => {
  const [countries, setCountries] = useState<string[]>([]);
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const [formData, setFormData] = useState(onboardingData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [ibanError, setIbanError] = useState(false);
  const navigate = useNavigate();

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data.map(
          (country: any) => country.translations.spa.common
        );
        countryNames.sort((a: string, b: string) => a.localeCompare(b));
        setCountries([" ", ...countryNames]);
      })
      .catch((error) => {
        console.error("Error al cargar los países:", error);
      });
  }, []);

  useEffect(() => {
    const {
      country,
      firstName,
      lastName,
      birthDate,
      gender,
      dni,
      bankIban,
      bankReference,
      phoneNumber,
    } = formData;

    // Validar IBAN (formato simple)
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/; // Validación IBAN básica
    const validIban = ibanRegex.test(bankIban);
    setIbanError(!validIban && bankIban !== "");
    setIsFormValid(
      !!country &&
        !!firstName &&
        !!lastName &&
        !!birthDate &&
        !!gender &&
        !!dni &&
        !!bankIban &&
        validIban &&
        !!bankReference &&
        !!phoneNumber // Validación del número de teléfono
    );
  }, [formData]);

  const handleFieldChange =
    (fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));
    };

  const handlePhoneChange = (phone: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: phone,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnboardingData(formData);
    navigate("/formularioDireccion");
  };

  return (
    <Container>
      <Header>
        <BackButton
          variant="contained"
          onClick={() => navigate("/seleccionarRubroUsuario")}
        >
          Atrás
        </BackButton>
        <FlagImage src={Logo} alt="Logo TipWise" />
      </Header>
      <form onSubmit={handleSubmit}>
        <ScrollContainer>
          <FormContainer>
            <Title variant="h4">Configura tu perfil</Title>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: 0,
              }}
            >
              <Subtitle variant="body1">
                Está información debe ser precisa. Se utilizará para procesar y
                asignar correctamente tus propinas.
              </Subtitle>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Los datos solicitados en esta plataforma se utilizarán exclusivamente para fines de facturación y no se emplearán para ningún otro propósito."
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={handleTooltipOpen}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Box>
            <Autocomplete
              options={countries}
              value={formData.country}
              onChange={(event, value) =>
                setFormData((prevState) => ({
                  ...prevState,
                  country: value || "",
                }))
              }
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="País de residencia"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <StyledTextField
              label="Nombre"
              value={formData.firstName}
              onChange={handleFieldChange("firstName")}
              variant="outlined"
              margin="normal"
            />
            <StyledTextField
              label="Apellido"
              value={formData.lastName}
              onChange={handleFieldChange("lastName")}
              variant="outlined"
              margin="normal"
            />
            <StyledTextField
              label="Fecha de nacimiento"
              type="date"
              value={formData.birthDate}
              onChange={handleFieldChange("birthDate")}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="normal"
            />
            <StyledTextField
              select
              label="Género"
              value={formData.gender}
              onChange={handleFieldChange("gender")}
              variant="outlined"
              margin="normal"
              SelectProps={{
                native: true,
              }}
            >
              <StyledOption value=""></StyledOption>
              <StyledOption value="masculino">Masculino</StyledOption>
              <StyledOption value="femenino">Femenino</StyledOption>
              <StyledOption value="otros">Otros</StyledOption>
            </StyledTextField>
            <StyledTextField
              label="DNI"
              value={formData.dni}
              onChange={handleFieldChange("dni")}
              variant="outlined"
              margin="normal"
            />
            <StyledTextField
              label="IBAN"
              value={formData.bankIban}
              onChange={handleFieldChange("bankIban")}
              variant="outlined"
              margin="normal"
              error={ibanError}
              helperText={ibanError ? "IBAN inválido" : ""}
            />
            <StyledTextField
              label="Referencia Bancaria"
              value={formData.bankReference}
              onChange={handleFieldChange("bankReference")}
              variant="outlined"
              margin="normal"
            />

            {/* Campo de número de teléfono */}
            <PhoneInput
              country={"es"}
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                placeholder: "Número de teléfono",
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              containerStyle={{ width: "100%", marginTop: "20px" }}
              inputStyle={{
                width: "100%",
                height: "50px",
                borderRadius: "12px",
                paddingLeft: "50px",
                borderColor: "#4640b3",
              }}
              buttonStyle={{
                borderRadius: "12px 0 0 12px",
              }}
              dropdownStyle={{
                borderRadius: "0 0 12px 12px",
              }}
            />
          </FormContainer>
        </ScrollContainer>
        <Box
          sx={{
            position: "fixed",
            zIndex: 99999,
          }}
        >
          <ContinueButton
            type="submit"
            variant="contained"
            disabled={!isFormValid}
          >
            Continuar
          </ContinueButton>
        </Box>
      </form>
    </Container>
  );
};

export default RegisterForm;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "90vh",
  paddingTop: "20px",
  "@media (max-width:800px)": {
    height: "110vh",
  },
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  alignItems: "center",
  marginBottom: 2,
});

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const FlagImage = styled("img")({
  width: 80,
  height: 50,
});

const ScrollContainer = styled(Box)({
  flex: 1,
  zIndex: 1,
  overflowY: "auto",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingBottom: "70px",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: 300,
  marginTop: "20px",
});

const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.4rem",
  alignSelf: "flex-start",
  marginBottom: 1,
});

const Subtitle = styled(Typography)({
  color: "#272541",
  marginRight: 5,
  fontSize: "15px",
  padding: "5px",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    width: 300,
    borderRadius: "15px",
  },
});

const StyledOption = styled("option")({
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "rgba(39, 37, 65, 0.1)",
  },
});

const ContinueButton = styled(Button)({
  position: "fixed",
  bottom: 20,
  width: "90%",
  maxWidth: 300,
  borderRadius: "15px",
  //marginTop: "15px",
  height: "50px",
  backgroundColor: "#272541",
  fontWeight: "bold",
  zIndex: 9999,
});
