import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import image from "../../../assets/OnBoarding1.jpg";
import { useNavigate } from "react-router-dom";

const OnBoarding1 = () => {
  const history = useNavigate();
  const handleContinuar = () => {
    history("/OnBoarding2");
  };
  const handleSaltar = () => {
    history("/SignIn");
  };

  return (
    <MainContainer>
      <TopBox>
        <BackButton variant="contained" onClick={handleSaltar}>
          {"Saltar"}
        </BackButton>
      </TopBox>
      <MiddleBox>
        <ImageContainer>
          <Image src={image} alt="" />
        </ImageContainer>
        <Box>
          <StyledTypography align="left" className="title">
            {"Bienvenido a Tipwise"}
          </StyledTypography>
          <StyledTypography align="left" className="subtitle">
            {
              "Descubre la magia de expresar gratitud y difundir alegría a través de propinas y reseñas."
            }
          </StyledTypography>
          <StyledTypography align="left" className="subtitle" color="primary">
            {"¡Es tan fácil como escanear un código QR!"}
          </StyledTypography>
        </Box>
      </MiddleBox>
      <BottomBox>
        <StyledButton
          fullWidth
          onClick={handleContinuar}
          variant="contained"
          color="secondary"
        >
          Siguiente
        </StyledButton>
      </BottomBox>
    </MainContainer>
  );
};

export default OnBoarding1;

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "90vh",
  padding: "20px",
  "@media (max-width: 768px)": {
    padding: "30px",
  },
});

const BackButton = styled(Button)({
  backgroundColor: "#272541",
  borderRadius: "15px",
  fontWeight: "bold",
});

const TopBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const MiddleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const BottomBox = styled(Box)({
  width: "100%",
  paddingBottom: "20px",
});

const StyledTypography = styled(Typography)({
  "&.title": {
    fontSize: 20,
    fontWeight: 800,
    paddingBottom: 8,
    color: "#272541",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  "&.subtitle": {
    fontSize: 14,
    "@media (max-width: 768px)": {
      fontSize: 12,
    },
  },
  "&.saltearText": {
    fontSize: 16,
    fontWeight: 600,
    color: "#272541",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      fontSize: 14,
    },
  },
});

const ImageContainer = styled("div")({
  marginTop: "34px",
  marginBottom: "0px",
  "@media (max-width: 768px)": {
    marginTop: "20px",
  },
});

const StyledButton = styled(Button)({
  width: "100%",
  whiteSpace: "nowrap",
  borderRadius: "15px",
  fontWeight: 800,
  backgroundColor: "#272541",
  height: 50,
  "@media (max-width: 768px)": {
    height: 45,
  },
  "&:hover": {
    backgroundColor: "#272541",
  },
});

const Image = styled("img")({
  width: 300,
  height: 250,
  "@media (max-width: 768px)": {
    width: "100%",
    height: "auto",
  },
});
