import React, { useState, useEffect } from "react";
import { Button, TextField, Link, Typography, Box } from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Bandera from "../../assets/España.jpg";

const DireccionForm = () => {
  const [formData, setFormData] = useState({
    country: "",
    address: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  // eslint-disable-next-line
  const [predictions, setPredictions] = useState<any[]>([]);
  const history = useNavigate();

  useEffect(() => {
    const { address } = formData;
    setIsFormValid(!!address);
  }, [formData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Formulario enviado:", formData);
    history("/detalleDireccion");
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address = event.target.value;
    setFormData((prevState) => ({ ...prevState, address }));
    if (address.trim() === "") {
      setPredictions([]);
      return;
    }
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${address}&key=YOUR_API_KEY`
      );
      setPredictions(response.data.predictions);
    } catch (error) {
      console.error("Error al buscar la dirección:", error);
    }
  };

  return (
    <Container>
      <Header>
        <BackButton
          variant="contained"
          onClick={() => history("/formularioRegistro")}
        >
          Atrás
        </BackButton>
        <Image src={Bandera} alt="Bandera de España" />
      </Header>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <Title variant="h4">Agregue su direccion</Title>
          <Subtitle variant="body1">Usá la dirección donde resides</Subtitle>
          <StyledTextField
            label="Dirección"
            variant="outlined"
            fullWidth
            value={formData.address}
            onChange={handleAddressChange}
          />
          {/* 
          {predictions.length > 0 && (
            <PredictionContainer>
              {predictions.map((prediction) => (
                <Typography key={prediction.place_id} variant="body1">
                  {prediction.description}
                </Typography>
              ))}
            </PredictionContainer>
          )} 
          */}
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <Link href="#">No puedo encontrar mi direccion</Link>
          </Typography>
          <ContinueButton
            type="submit"
            variant="contained"
            disabled={!isFormValid}
          >
            Continuar
          </ContinueButton>
        </FormContainer>
      </form>
    </Container>
  );
};

export default DireccionForm;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 2,
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  alignItems: "center",
  marginBottom: 2,
});

const BackButton = styled(Button)({
  backgroundColor: "#342F81",
  fontWeight: "bold",
});

const Image = styled("img")({
  width: 40,
  height: 40,
  borderRadius: "50%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: 300,
  marginTop: 4,
});

const Title = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.4rem",
  alignSelf: "flex-start",
  marginBottom: 1,
});

const Subtitle = styled(Typography)({
  color: "#342F81",
  marginBottom: 5,
  alignSelf: "flex-start",
});

const StyledTextField = styled(TextField)({
  width: 300,
  marginTop: 1,
});

const ContinueButton = styled(Button)({
  position: "fixed",
  bottom: 20,
  width: "80%",
  marginTop: 2,
  backgroundColor: "#342F81",
  fontWeight: "bold",
});
// eslint-disable-next-line
const PredictionContainer = styled(Box)({
  width: 300,
  marginTop: 2,
});
