import React from "react";
import { Button, Box, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const InformacionEmpresa = () => {
  const history = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <HeaderBox>
        <StyledButton onClick={() => history("/perfil")}>
          <ArrowBackOutlinedIcon />
        </StyledButton>
        <CenteredBox>
          <HeaderTypography>Información empresarial</HeaderTypography>
        </CenteredBox>
      </HeaderBox>
      <ContentBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Detalles</Typography>
          </SectionHeader>
          <SectionContent>Nombre: {userData.companies[0].name}</SectionContent>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Email</Typography>
          </SectionHeader>
          <SectionContent>{userData.email}</SectionContent>
        </SectionBox>
        <SectionBox>
          <SectionHeader>
            <Typography variant="h6">Segmento</Typography>
          </SectionHeader>
          <SectionContent>
            Tags: {userData.companies[0].segment || "No disponible"}
          </SectionContent>
        </SectionBox>
      </ContentBox>
    </>
  );
};

export default InformacionEmpresa;

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  alignItems: "center",
  height: "50px",
  padding: "0 1px",
  backgroundColor: "#272541",
  color: "#FFFFFF",
});

const StyledButton = styled(Button)({
  color: "#FFFFFF",
});

const CenteredBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const HeaderTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "left",
});

const ContentBox = styled(Box)({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const SectionBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#F9F9F9",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
});

const SectionHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
});

const SectionContent = styled(Typography)({
  fontSize: "16px",
  color: "#555",
});
